import React, {Fragment, PureComponent} from 'react';
import {Badge, Button, ButtonGroup} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment-timezone';
import axios from 'axios';

import AppRouter from '../router/AppRouter';
import ReceiveTabs from '../header/ReceiveTabs';
import ItemListOptions from './options/ItemListOptions';
import APIGatewayClientProvider, {APIGatewayClientContext} from '../provider/APIGatewayClientProvider';
import ItemListRecord, {OPTIONS_PROPERTY_NAME_TO_COLUMN_NAME_MAP} from './pojos/ItemListRecord';
import ForecastedItemListRecord from './pojos/ForecastedItemListRecord';
import ItemListRequestInput from './pojos/ItemListRequestInput';
import RodeoFilters, {
    ContainerIdFilter,
    ContainerSizeFilter,
    ContainerTypeFilter,
    ExpectedStowDateFilter,
    FcSkuFilter,
    FnSkuFilter,
    ISAIdFilter,
    ISDIdFilter,
    IsReactiveFilter,
    OuterContainerIdFilter,
    OuterContainerTypeFilter,
    OuterScannableIdFilter,
    OuterOuterScannableIdFilter,
    FloorFilter,
    ModFilter,
    ProcessPathFilter,
    ScannableIdFilter,
    SourceWarehouseIdFilter,
    TrailerDockingTimeFilter,
    VRIdFilter,
    WorkPoolFilter,
    WorkPoolLastChangedTimeFilter,
    WORK_POOL_FILTER_NAME,
    VALUES_FIELD
} from '../pojos/RodeoFilters';
import * as RodeoFilterQueryStrings from '../pojos/RodeoFilterQueryStrings';
import {DWELL_TIME_MILLIS_QUERY_STRING_KEY} from '../pojos/RodeoFilterQueryStrings';
import DataLoading from '../homepage/DataLoading';
import PageLoadError from '../error/PageLoadError';
import ItemListPagination from './ItemListPagination';
import NotificationContainer from '../notification/NotificationContainer';
import Notification from '../notification/Notification';
import SearchRequestInput from './pojos/SearchRequestInput';
import ItemListTable from './ItemListTable';
import {DEFAULT_TIME_ZONE} from '../util/DateUtilities';
import {QueryStringUtilities} from '../util/QueryStringUtilities';
import {SHIPMENT_TYPE_VALUE_TO_NAME_MAP, SHIPMENT_TYPES_ENUM, NON_POST_RECEIVE_STATUS_ENUM} from '../pojos/Dimensions';
import {POST_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP} from '../pojos/WorkPools';
import {WORKPOOL_PROPERTY_NAME} from '../pojos/PropertyNames';

import '../../../css/item-list.scss';
import {WorkpoolUtilities} from "../util/WorkpoolUtilities";

export const SEARCH_QUERY_QUERY_STRING_KEY = "searchQuery";
const DEFAULT_SHIPMENT_TYPE = SHIPMENT_TYPES_ENUM.TRANSSHIPMENT;
const SHIPMENT_TYPE_QUERY_STRING_KEY = "shipmentType";
const ADDITIONAL_COLUMNS_QUERY_STRING_KEY = "additionalColumns";

const DEFAULT_PAGE_SIZE = 1000;
const NA_REALM = "NAFulfillment";
const EU_REALM = "EUFulfillment";
const DEFAULT_REALM = NA_REALM;

class ItemListView extends PureComponent {

    constructor(props) {
        super(props);

        const queryStringState = this.parseQueryStringState();

        this.state = {
            itemListRecords: [],
            warehouseTimezone: DEFAULT_TIME_ZONE,
            realm: DEFAULT_REALM,
            isLoaded: false,
            isJavaScriptError: false,
            showOptionsPanel: false,
            isInitialSearch: true, //used to redirect user to the appropriate initial shipment type
            paginationNumber: 1,
            itemCount: 0,
            isForecast: false,
            isActuals: false,
            ...queryStringState
        };

        this.cancelTokenSource = APIGatewayClientProvider.createNewCancelTokenSource();
        this.notificationRef = React.createRef();

        this.setActivePaginationNumber = this.setActivePaginationNumber.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.toggleOptionsPanel = this.toggleOptionsPanel.bind(this);
        this.parseQueryStringState = this.parseQueryStringState.bind(this);
    }

    componentDidMount() {
        Promise.all( [this.retrieveWarehouseTimezone(),this.retrieveRealm()]).then(() => this.retrieveItemListRecords());
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
        this.setState({isJavaScriptError: true});
    }

    componentDidUpdate(prevProps, prevState) {
        const queryStringsHaveChanged = this.props.location.search !== prevProps.location.search;
        const shipmentTypeHasChanged = this.state.selectedShipmentType !== prevState.selectedShipmentType;
        const searchQueryHasChanged = this.state.searchQuery !== prevState.searchQuery;
        const paginationNumberHasChanged = this.state.paginationNumber !== prevState.paginationNumber;

        if (queryStringsHaveChanged) {
            this.setState(prevState => {
                return {...prevState, ...this.parseQueryStringState()}
            });
        } else if (searchQueryHasChanged) {
            this.setState({isInitialSearch: true}, () => this.retrieveItemListRecords());
        } else if (shipmentTypeHasChanged || paginationNumberHasChanged || !_.isEqual(this.state.rodeoFilters, prevState.rodeoFilters)) {
            this.retrieveItemListRecords();
        }
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel('Unmounting component cancel fetch requests');
    }

    render() {
        if (this.state.isJavaScriptError) {
            return <PageLoadError/>
        }
        const numFiltersApplied = this.determineNumFiltersApplied();
        return (
            <Fragment>
                <ReceiveTabs/>
                <div data-testid="ItemListView-TestId" className="container-fluid h-100 d-flex flex-column">
                    <NotificationContainer ref={this.notificationRef}/>
                    <div className="row item-list-selector-row">
                        <div className="col-md-3">
                            <Button variant="secondary" size="sm" onClick={this.toggleOptionsPanel}>Options</Button>
                            <span className="mx-2">
                                <Badge
                                    variant="light">{numFiltersApplied} filter{numFiltersApplied === 1 ? '' : 's'} applied</Badge>
                            </span>
                        </div>
                        <div className="col-md-6 text-center">
                            <ButtonGroup aria-label="Shipment Type Selector" size="sm">
                                <Button variant={"outline-secondary"}
                                        className={this.isTransshipmentSelected() ? 'active' : ''}
                                        value={SHIPMENT_TYPES_ENUM.TRANSSHIPMENT}
                                        onClick={this.changeSelectedShipmentType.bind(this, SHIPMENT_TYPES_ENUM.TRANSSHIPMENT)}>
                                    {_.toUpper(SHIPMENT_TYPE_VALUE_TO_NAME_MAP.get(SHIPMENT_TYPES_ENUM.TRANSSHIPMENT))}
                                </Button>
                                <Button variant={"outline-secondary"}
                                        className={this.isVendorReceiveSelected() ? 'active' : ''}
                                        value={_.toUpper(SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE)}
                                        onClick={this.changeSelectedShipmentType.bind(this, SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE)}>
                                    {_.toUpper(SHIPMENT_TYPE_VALUE_TO_NAME_MAP.get(SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE))}
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className="col-md-3">
                            <ItemListPagination activePageNum={this.state.paginationNumber}
                                                setActivePaginationNumber={this.setActivePaginationNumber}
                                                itemCount={this.state.itemCount}
                                                defaultPageSize={DEFAULT_PAGE_SIZE}/>
                            {this.createRecordDisplayCounter(this.state.itemListRecords)}
                        </div>
                    </div>
                    <div className="row h-100">
                        <ItemListOptions isOpen={this.state.showOptionsPanel}
                                         additionalPropertyNameToHeaderNameMap={this.state.additionalPropertyNameToHeaderNameMap}
                                         toggleOptionsPanel={this.toggleOptionsPanel}
                                         isForecast={this.state.isForecast}
                                         applyFilters={this.applyFilters}/>
                        <div className="col h-100 text-center">
                            <ItemListTable isLoaded={this.state.isLoaded}
                                           isEURealm={this.isEURealm()}
                                           isTransshipmentSelected={this.isTransshipmentSelected()}
                                           itemListRecords={this.state.itemListRecords}
                                           isForecast={this.state.isForecast}
                                           additionalPropertyNameToHeaderNameMap={this.state.additionalPropertyNameToHeaderNameMap}
                                           isPostReceiveSelected={this.isPostReceiveSelected()}/>
                            {!this.state.isLoaded && <DataLoading/>}
                        </div>
                    </div>
                </div>
            </Fragment>);
    }

    createRecordDisplayCounter() {
        let displayCounterText;
        if (!this.state.isLoaded) {
            displayCounterText = "Loading Rows...";
        } else if (this.state.itemCount === 0) {
            displayCounterText = 'No records';
        } else {
            const currentItemListRecordsCount = this.state.itemListRecords.length;
            const currentStartCount = currentItemListRecordsCount === 0 ? currentItemListRecordsCount : (this.state.paginationNumber - 1) * DEFAULT_PAGE_SIZE + 1;
            const currentEndCount = (DEFAULT_PAGE_SIZE > currentItemListRecordsCount ?
                currentItemListRecordsCount :
                DEFAULT_PAGE_SIZE) + ((this.state.paginationNumber - 1) * DEFAULT_PAGE_SIZE);
            const totalItems = this.state.itemCount;
            displayCounterText = `Showing ${currentStartCount} - ${currentEndCount} of ${totalItems}`;
        }
        const queryStringText = "Query: ";
        const isSearchView = AppRouter.isSearchView(this.props.match.path);

        return (
            <div className="text-right">
                {isSearchView && this.state.searchQuery &&
                <div>{queryStringText}<strong>{this.state.searchQuery}</strong></div>}
                <div>{displayCounterText}</div>
            </div>
        );
    }

    setActivePaginationNumber(event) {
        const paginationNumberString = event.target.dataset.paginationNumber;
        if (!_.isEmpty(paginationNumberString)) {
            const paginationNumber = _.parseInt(paginationNumberString);
            this.setState({paginationNumber});
        }
    }

    isTransshipmentSelected() {
        return this.state.selectedShipmentType === SHIPMENT_TYPES_ENUM.TRANSSHIPMENT;
    }

    isVendorReceiveSelected() {
        return this.state.selectedShipmentType === SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE;
    }

    isPostReceiveSelected() {
        const parsedQueryStrings = QueryStringUtilities.parseQueryStrings(this.props.location.search);
        const workPoolsArray = [].concat(parsedQueryStrings[RodeoFilterQueryStrings.WORK_POOLS_QUERY_STRING_KEY]);

        return !_.isEmpty(workPoolsArray.filter(workPool => [...POST_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP.keys()].includes(workPool)));
    }

    createSearchRequestInput() {
        return new SearchRequestInput.Builder().withWarehouseId(this.props.match.params.warehouseId)
            .withBatchSize(DEFAULT_PAGE_SIZE)
            .withShipmentTypes([SHIPMENT_TYPES_ENUM.TRANSSHIPMENT, SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE])
            .withStartIndex((this.state.paginationNumber - 1) * DEFAULT_PAGE_SIZE)
            .withSearchQuery(this.state.searchQuery)
            .withRodeoFilters(this.state.rodeoFilters).build();
    }

    retrieveWarehouseTimezone() {
        return this.context.client.getTimezoneForWarehouseId(this.props.match.params.warehouseId, this.cancelTokenSource.token).then(response => {
            const warehouseTimezone = response.data.timezone;
            if (_.isEmpty(warehouseTimezone)) {
                this.notificationRef.current.addNotification(new Notification.Builder('itemListViewNoTimezoneInfoFoundError')
                    .withType(Notification.TYPES.WARN)
                    .withMessage(`No warehouse timezone information found, defaulting to ${DEFAULT_TIME_ZONE}`)
                    .build());
            } else {
                this.setState({warehouseTimezone});
            }
        }).catch(error => {
            if (!axios.isCancel(error)) {
                const errorMessage = APIGatewayClientProvider.parseError(error);
                this.notificationRef.current.addNotification(new Notification.Builder('itemListViewTimezoneLoadError')
                    .withType(Notification.TYPES.ERROR)
                    .withMessage(`Error retrieving timezone, defaulting to ${DEFAULT_TIME_ZONE}: ${errorMessage}`)
                    .build());
            }
        });
    }

    retrieveRealm() {
        return this.context.client.getRealm(this.cancelTokenSource.token).then(response => {
            this.setState({realm: response.data.realm});
        }).catch(error => {
            if (!axios.isCancel(error)) {
                const errorMessage = APIGatewayClientProvider.parseError(error);
                this.notificationRef.current.addNotification(new Notification.Builder('aggregateViewRealmLoadError')
                    .withType(Notification.TYPES.WARN)
                    .withMessage(`Problem retrieving the current realm. Defaulting to ${DEFAULT_REALM}: ${errorMessage}`)
                    .build());
            }
        });
    }

    retrieveItemListRecords() {
        this.cancelTokenSource.cancel('Cancelling previous running ItemList ajax requests');
        this.cancelTokenSource = APIGatewayClientProvider.createNewCancelTokenSource();
        this.setState({isLoaded: false});

        let itemListRequestInput;
        const itemListRequests = [];

        const isSearchView = AppRouter.isSearchView(this.props.match.path);
        if (isSearchView) {
            itemListRequestInput = this.createSearchRequestInput();
            itemListRequests.push(this.context.client.getSearchResults(itemListRequestInput, this.cancelTokenSource.token));
        } else {
            const workPoolName = this.state.rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD][0];
            if (workPoolName.endsWith(NON_POST_RECEIVE_STATUS_ENUM.FORECASTED)) {
                this.setState({
                    isForecast: true
                });
            } else if (workPoolName.endsWith(NON_POST_RECEIVE_STATUS_ENUM.ACTUALS)) {
                this.setState({
                    isActuals: true
                });
            }
            itemListRequestInput = this.createItemListRequestInput();
            if (this.state.isForecast) {
                itemListRequests.push(this.isTransshipmentSelected() ? this.context.client.getForecastedTransshipmentItemList(itemListRequestInput, this.cancelTokenSource.token)
                    : this.context.client.getForecastedVendorReceiveItemList(itemListRequestInput, this.cancelTokenSource.token));
            } else if (this.state.isActuals) {
                itemListRequests.push(this.isTransshipmentSelected() ? this.context.client.getTransshipmentItemList(itemListRequestInput, this.cancelTokenSource.token)
                    : this.context.client.getVendorReceiveItemList(itemListRequestInput, this.cancelTokenSource.token));
            } else {
                if (this.isPostReceiveSelected()) {
                    itemListRequests.push(this.isTransshipmentSelected() ? this.context.client.getTransshipmentItemList(itemListRequestInput, this.cancelTokenSource.token)
                        : this.context.client.getVendorReceiveItemList(itemListRequestInput, this.cancelTokenSource.token));
                } else {
                    if (this.isTransshipmentSelected()) {
                        itemListRequests.push(this.context.client.getTransshipmentItemList(itemListRequestInput, this.cancelTokenSource.token))
                        itemListRequests.push(this.context.client.getForecastedTransshipmentItemList(itemListRequestInput, this.cancelTokenSource.token))
                    } else {
                        itemListRequests.push(this.context.client.getVendorReceiveItemList(itemListRequestInput, this.cancelTokenSource.token))
                        itemListRequests.push(this.context.client.getForecastedVendorReceiveItemList(itemListRequestInput, this.cancelTokenSource.token))
                    }
                }
            }
        }

        Promise.all(itemListRequests).then(responses => {
            const itemCount = responses.reduce((sum, response) => sum + response.data.itemCount, 0);
            const itemListRecord = responses.flatMap(response => this.parseItemListFromResponse(response));
            const itemListRecordJSON = itemListRecord.map(recordItem => {
                if (this.state.isForecast) {
                    return ForecastedItemListRecord.createForecastedItemListRecordFromJSON(recordItem, this.state.warehouseTimezone);
                }
                return ItemListRecord.createItemListRecordFromJSON(recordItem, this.state.warehouseTimezone);
            });
            this.setState({
                itemCount: itemCount,
                itemListRecords: itemListRecordJSON,
                isLoaded: true,
                isInitialSearch: false
            });
        }).catch(error => {
            if (!axios.isCancel(error)) {
                const errorMessage = APIGatewayClientProvider.parseError(error);
                this.notificationRef.current.addNotification(new Notification.Builder('itemListDataLoadError')
                    .withType(Notification.TYPES.ERROR)
                    .withMessage(`Error retrieving item list records: ${errorMessage}`)
                    .build());
                this.setState({itemListRecords: [], isLoaded: true});
            }
        });
    }

    parseItemListFromResponse(response) {
        const isSearchView = AppRouter.isSearchView(this.props.match.path);
        const transshipmentItems = response.data.transshipmentItemList || [];
        const vendorReceiveItems = response.data.vendorReceiveItemList || [];
        const forecastedTransshipmentItems = response.data.forecastedTransshipmentItemList || [];
        const forecastedVendorReceiveItems = response.data.forecastedVendorReceiveItemList || [];
        if (isSearchView && this.state.isInitialSearch) {
            if (!_.isEmpty(transshipmentItems) && _.isEmpty(vendorReceiveItems)) {
                if (!this.isTransshipmentSelected()) {
                    this.changeSelectedShipmentType(SHIPMENT_TYPES_ENUM.TRANSSHIPMENT);
                }
                return transshipmentItems;
            }
            if (!_.isEmpty(vendorReceiveItems) && _.isEmpty(transshipmentItems)) {
                if (!this.isVendorReceiveSelected()) {
                    this.changeSelectedShipmentType(SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE);
                }
                return vendorReceiveItems;
            }
        }
        if (this.state.isForecast) {
            return this.isTransshipmentSelected() ? this.appendSubWorkPoolSuffix(forecastedTransshipmentItems, NON_POST_RECEIVE_STATUS_ENUM.FORECASTED) : this.appendSubWorkPoolSuffix(forecastedVendorReceiveItems, NON_POST_RECEIVE_STATUS_ENUM.FORECASTED);
        }
        if (this.state.isActuals) {
            return this.isTransshipmentSelected() ? this.appendSubWorkPoolSuffix(transshipmentItems, NON_POST_RECEIVE_STATUS_ENUM.ACTUALS) : this.appendSubWorkPoolSuffix(vendorReceiveItems, NON_POST_RECEIVE_STATUS_ENUM.ACTUALS);
        }
        if (!this.isPostReceiveSelected()) {
            if (this.isTransshipmentSelected()) {
                return transshipmentItems.length === 0 ? this.appendSubWorkPoolSuffix(forecastedTransshipmentItems, NON_POST_RECEIVE_STATUS_ENUM.FORECASTED) : this.appendSubWorkPoolSuffix(transshipmentItems, NON_POST_RECEIVE_STATUS_ENUM.ACTUALS)
            } else {
                return vendorReceiveItems.length === 0 ? this.appendSubWorkPoolSuffix(forecastedVendorReceiveItems, NON_POST_RECEIVE_STATUS_ENUM.FORECASTED) : this.appendSubWorkPoolSuffix(vendorReceiveItems, NON_POST_RECEIVE_STATUS_ENUM.ACTUALS)
            }
        }
        return this.isTransshipmentSelected() ? transshipmentItems : vendorReceiveItems;
    }

    appendSubWorkPoolSuffix(itemList, suffix) {
        return itemList.map((item) => {
            item[WORKPOOL_PROPERTY_NAME] = item[WORKPOOL_PROPERTY_NAME] + suffix;
            return item;
        });
    }

    createItemListRequestInput() {
        const itemListRequestWorkPools = this.state.rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD];
        let rodeoFiltersClone = _.cloneDeep(this.state.rodeoFilters);
        rodeoFiltersClone[WORK_POOL_FILTER_NAME][VALUES_FIELD] = itemListRequestWorkPools.map((workPool) => {
            return WorkpoolUtilities.findParentWorkPool(workPool);
        });
        return new ItemListRequestInput.Builder().withWarehouseId(this.props.match.params.warehouseId)
            .withBatchSize(DEFAULT_PAGE_SIZE)
            .withStartIndex((this.state.paginationNumber - 1) * DEFAULT_PAGE_SIZE)
            .withRodeoFilters(rodeoFiltersClone).build();
    }

    parseQueryStringState() {
        const queryStringState = {};
        const parsedQueryStrings = QueryStringUtilities.parseQueryStrings(this.props.location.search);

        const shipmentTypeQueryString = parsedQueryStrings[SHIPMENT_TYPE_QUERY_STRING_KEY];
        const additionalColumnsQueryString = parsedQueryStrings[ADDITIONAL_COLUMNS_QUERY_STRING_KEY];

        queryStringState.searchQuery = parsedQueryStrings[SEARCH_QUERY_QUERY_STRING_KEY];
        queryStringState.selectedShipmentType = _.isNil(shipmentTypeQueryString) ? DEFAULT_SHIPMENT_TYPE : shipmentTypeQueryString;

        let arrayConvertedColumns;
        if (_.isEmpty(additionalColumnsQueryString)) {
            arrayConvertedColumns = [];
        } else {
            arrayConvertedColumns = _.isArray(additionalColumnsQueryString) ? additionalColumnsQueryString : [additionalColumnsQueryString];
        }
        queryStringState.additionalPropertyNameToHeaderNameMap = new Map(arrayConvertedColumns.map(propertyName =>
            [propertyName, OPTIONS_PROPERTY_NAME_TO_COLUMN_NAME_MAP.get(propertyName)]));

        queryStringState.rodeoFilters = this.createRodeoFiltersFromQueryStrings(parsedQueryStrings);

        return queryStringState;
    }

    createRodeoFiltersFromQueryStrings(parsedQueryStrings) {
        const containerIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.CONTAINER_ID_QUERY_STRING_KEY];
        const containerSizesQueryString = parsedQueryStrings[RodeoFilterQueryStrings.CONTAINER_SIZES_QUERY_STRING_KEY];
        const containerTypesQueryString = parsedQueryStrings[RodeoFilterQueryStrings.CONTAINER_TYPES_QUERY_STRING_KEY];
        const dwellTimeMillisQueryString = parsedQueryStrings[RodeoFilterQueryStrings.DWELL_TIME_MILLIS_QUERY_STRING_KEY];
        const expectedStowDateQueryString = parsedQueryStrings[RodeoFilterQueryStrings.EXPECTED_STOW_DATE_QUERY_STRING_KEY];
        const fcSkuQueryString = parsedQueryStrings[RodeoFilterQueryStrings.FC_SKU_QUERY_STRING_KEY];
        const fnSkuQueryString = parsedQueryStrings[RodeoFilterQueryStrings.FN_SKU_QUERY_STRING_KEY];
        const isaIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.ISA_ID_QUERY_STRING_KEY];
        const isdIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.ISD_ID_QUERY_STRING_KEY];
        const isReactiveQueryString = parsedQueryStrings[RodeoFilterQueryStrings.IS_REACTIVE_QUERY_STRING_KEY];
        const outerContainerIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.OUTER_CONTAINER_ID_QUERY_STRING_KEY];
        const outerContainerTypesQueryString = parsedQueryStrings[RodeoFilterQueryStrings.OUTER_CONTAINER_TYPES_QUERY_STRING_KEY];
        const outerScannableIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.OUTER_SCANNABLE_ID_QUERY_STRING_KEY];
        const outerOuterScannableIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.OUTER_OUTER_SCANNABLE_ID_QUERY_STRING_KEY];
        const floorQueryString = parsedQueryStrings[RodeoFilterQueryStrings.FLOOR_QUERY_STRING_KEY];
        const modQueryString = parsedQueryStrings[RodeoFilterQueryStrings.MOD_QUERY_STRING_KEY];
        const processPathsQueryString = parsedQueryStrings[RodeoFilterQueryStrings.PROCESS_PATHS_QUERY_STRING_KEY];
        const scannableIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.SCANNABLE_ID_QUERY_STRING_KEY];
        const sourceWarehouseIdsQueryString = parsedQueryStrings[RodeoFilterQueryStrings.SOURCE_WAREHOUSE_IDS_QUERY_STRING_KEY];
        const trailerDockingTimeQueryString = parsedQueryStrings[RodeoFilterQueryStrings.TRAILER_DOCKING_TIME_QUERY_STRING_KEY];
        const vrIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.VR_ID_QUERY_STRING_KEY];
        const workPoolsQueryString = parsedQueryStrings[RodeoFilterQueryStrings.WORK_POOLS_QUERY_STRING_KEY];

        const rodeoFiltersBuilder = new RodeoFilters.Builder();
        if (!_.isEmpty(containerIdQueryString)) {
            rodeoFiltersBuilder.withContainerIdFilter(new ContainerIdFilter().withValue(containerIdQueryString))
        }
        if (!_.isEmpty(containerSizesQueryString)) {
            rodeoFiltersBuilder.withContainerSizeFilter(new ContainerSizeFilter().withValues(containerSizesQueryString));
        }
        if (!_.isEmpty(containerTypesQueryString)) {
            rodeoFiltersBuilder.withContainerTypeFilter(new ContainerTypeFilter().withValues(containerTypesQueryString));
        }
        if (!_.isEmpty(expectedStowDateQueryString)) {
            rodeoFiltersBuilder.withExpectedStowDateFilter(new ExpectedStowDateFilter().withFrom(expectedStowDateQueryString.start).withTo(expectedStowDateQueryString.end));
        }
        if (!_.isEmpty(fcSkuQueryString)) {
            rodeoFiltersBuilder.withFcSkuFilter(new FcSkuFilter().withValue(fcSkuQueryString));
        }
        if (!_.isEmpty(fnSkuQueryString)) {
            rodeoFiltersBuilder.withFnSkuFilter(new FnSkuFilter().withValue(fnSkuQueryString));
        }
        if (!_.isEmpty(isaIdQueryString)) {
            rodeoFiltersBuilder.withISAIdFilter(new ISAIdFilter().withValue(isaIdQueryString));
        }
        if (!_.isEmpty(isdIdQueryString)) {
            rodeoFiltersBuilder.withISDIdFilter(new ISDIdFilter().withValue(isdIdQueryString));
        }
        if (!_.isEmpty(isReactiveQueryString)) {
            rodeoFiltersBuilder.withIsReactiveFilter(new IsReactiveFilter().withValue(isReactiveQueryString));
        }
        if (!_.isEmpty(outerContainerIdQueryString)) {
            rodeoFiltersBuilder.withOuterContainerIdFilter(new OuterContainerIdFilter().withValue(outerContainerIdQueryString));
        }
        if (!_.isEmpty(outerContainerTypesQueryString)) {
            rodeoFiltersBuilder.withOuterContainerTypeFilter(new OuterContainerTypeFilter().withValues(outerContainerTypesQueryString));
        }
        if (!_.isEmpty(outerScannableIdQueryString)) {
            rodeoFiltersBuilder.withOuterScannableIdFilter(new OuterScannableIdFilter().withValues(outerScannableIdQueryString));
        }
        if (!_.isEmpty(outerOuterScannableIdQueryString)) {
            rodeoFiltersBuilder.withOuterOuterScannableIdFilter(new OuterOuterScannableIdFilter().withValues(outerOuterScannableIdQueryString));
        }
        if (!_.isEmpty(floorQueryString)) {
            rodeoFiltersBuilder.withFloorFilter(new FloorFilter().withValues(floorQueryString));
        }
        if (!_.isEmpty(modQueryString)) {
            rodeoFiltersBuilder.withModFilter(new ModFilter().withValues(modQueryString));
        }
        if (!_.isEmpty(processPathsQueryString)) {
            rodeoFiltersBuilder.withProcessPathFilter(new ProcessPathFilter().withValues(processPathsQueryString));
        }
        if (!_.isEmpty(scannableIdQueryString)) {
            rodeoFiltersBuilder.withScannableIdFilter(new ScannableIdFilter().withValues(scannableIdQueryString));
        }
        if (!_.isEmpty(sourceWarehouseIdsQueryString)) {
            rodeoFiltersBuilder.withSourceWarehouseIdFilter(new SourceWarehouseIdFilter().withValues(sourceWarehouseIdsQueryString));
        }
        if (!_.isEmpty(trailerDockingTimeQueryString)) {
            rodeoFiltersBuilder.withTrailerDockingTimeFilter(new TrailerDockingTimeFilter().withFrom(trailerDockingTimeQueryString.start).withTo(trailerDockingTimeQueryString.end));
        }
        if (!_.isEmpty(vrIdQueryString)) {
            rodeoFiltersBuilder.withVRIdFilter(new VRIdFilter().withValue(vrIdQueryString));
        }
        if (!_.isEmpty(workPoolsQueryString)) {
            rodeoFiltersBuilder.withWorkPoolFilter(new WorkPoolFilter().withValues(workPoolsQueryString));
        }
        if (!_.isEmpty(dwellTimeMillisQueryString) && !(_.isEmpty(dwellTimeMillisQueryString.start) && _.isEmpty(dwellTimeMillisQueryString.end))) {
            const filter = new WorkPoolLastChangedTimeFilter();
            if (!_.isEmpty(dwellTimeMillisQueryString.start)) {
                filter.withTo(moment().unix() - dwellTimeMillisQueryString.start / 1000);
            }
            if (!_.isEmpty(dwellTimeMillisQueryString.end)) {
                filter.withFrom(moment().unix() - dwellTimeMillisQueryString.end / 1000);
            }
            rodeoFiltersBuilder.withWorkPoolLastChangedTimeFilter(filter);
        }
        return rodeoFiltersBuilder.build();
    }

    addQueryParamsToURL(queryParamNameToValueObject) {
        const currentQueryStrings = QueryStringUtilities.parseQueryStrings(this.props.location.search);
        this.props.history.push(`${this.props.location.pathname}?${QueryStringUtilities.stringifyQueryStrings({...currentQueryStrings, ...queryParamNameToValueObject})}`);
    }

    toggleOptionsPanel() {
        this.setState(prevState => {
            return {showOptionsPanel: !prevState.showOptionsPanel}
        });
    }

    changeSelectedShipmentType(newSelectedShipmentType) {
        const queryParamsObject = {};
        queryParamsObject[SHIPMENT_TYPE_QUERY_STRING_KEY] = newSelectedShipmentType;
        this.addQueryParamsToURL(queryParamsObject);
        this.setState({paginationNumber: 1});
    }

    applyFilters(additionalPropertyNameToHeaderNameMap, dwellTimeMillisStart, dwellTimeMillisEnd) {
        const queryParamsObject = {};
        queryParamsObject[ADDITIONAL_COLUMNS_QUERY_STRING_KEY] = [...additionalPropertyNameToHeaderNameMap.keys()];
        if (!(_.isNil(dwellTimeMillisStart) && _.isNil(dwellTimeMillisEnd))) {
            queryParamsObject[DWELL_TIME_MILLIS_QUERY_STRING_KEY] = {
                start: dwellTimeMillisStart,
                end: dwellTimeMillisEnd
            };
        } else {
            const parsedQueryStrings = QueryStringUtilities.parseQueryStrings(this.props.location.search);
            queryParamsObject[DWELL_TIME_MILLIS_QUERY_STRING_KEY] = parsedQueryStrings[DWELL_TIME_MILLIS_QUERY_STRING_KEY];
        }
        this.addQueryParamsToURL(queryParamsObject);
    }

    isEURealm() {
        return _.toLower(this.state.realm) === _.toLower(EU_REALM);
    }

    determineNumFiltersApplied() {
        return _.filter(_.values(this.state.rodeoFilters), filter => !_.isNil(filter)).length;
    }
}

export {ItemListView};

ItemListView.contextType = APIGatewayClientContext;
export default ItemListView;
