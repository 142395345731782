import axios from "axios";

import signRequest from "./signRequest";

export default class AxiosClient {
  constructor(credentials, httpEndpoint, service, region) {
    const httpClient = axios.create({
      baseURL: httpEndpoint,
      withCredentials: true,
    });
    this.credentials = credentials;

    httpClient.interceptors.request.use((request) =>
      signRequest(request, credentials, service, region)
    );
    /**
     * In some cases randomly response.data is returned
     * as string instead of object so this interceptor
     * is used to mitigate this problem if it happened
     *  */
    httpClient.interceptors.response.use(function (response) {
      if (typeof response.data === "string") {
        try {
          response.data = JSON.parse(response.data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      }
      return response;
    });
    httpClient.credentials = credentials;
    return Object.assign(this, httpClient);
  }
}
