import React, {PureComponent} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';

import AppRouter, {PRE_RECEIVE_URL, POST_RECEIVE_URL, DWELL_TIME_URL} from '../router/AppRouter';
import AggregateViewReceive from '../aggregate/receive/AggregateViewReceive';
import {VIEW_NAMES_ENUM} from '../pojos/ViewNames';
import AggregateViewMainTable from "../aggregate/receive/AggregateViewMainTable";

class ReceiveTabs extends PureComponent {

    constructor(props) {
        super(props);

        this.updateActiveKey = this.updateActiveKey.bind(this);
    }

    render() {
        const activeKey = this.props.match.params.receive || null;
        return (
            <Tabs className="justify-content-center receive-nav-tabs"
                  activeKey={activeKey}
                  mountOnEnter={true}
                  onSelect={this.updateActiveKey}>
                <Tab eventKey={PRE_RECEIVE_URL} title={VIEW_NAMES_ENUM.PRE_RECEIVE}>
                    <AggregateViewReceive viewName={VIEW_NAMES_ENUM.PRE_RECEIVE} warehouseId={this.props.match.params.warehouseId} mainTable={AggregateViewMainTable}/>
                </Tab>
                <Tab eventKey={POST_RECEIVE_URL} title={VIEW_NAMES_ENUM.POST_RECEIVE}>
                    <AggregateViewReceive viewName={VIEW_NAMES_ENUM.POST_RECEIVE} warehouseId={this.props.match.params.warehouseId} mainTable={AggregateViewMainTable}/>
                </Tab>
                <Tab eventKey={DWELL_TIME_URL} title={VIEW_NAMES_ENUM.DWELL_TIME}>
                    <AggregateViewReceive viewName={VIEW_NAMES_ENUM.DWELL_TIME} warehouseId={this.props.match.params.warehouseId} mainTable={AggregateViewMainTable}/>
                </Tab>
            </Tabs>
        );
    }

    updateActiveKey(selectedKey) {
        const redirectURL = AppRouter.generateNewUrl(this.props.match.params.warehouseId, selectedKey);
        this.props.history.push(redirectURL);
    }
}

export default withRouter(ReceiveTabs);
