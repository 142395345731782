import _ from 'lodash';
import * as RodeoFilterQueryStrings from './RodeoFilterQueryStrings';

export const VALUES_FIELD = "values";
const VALUE_FIELD = "value";
const PREFIX_FIELD = "prefix";
const FROM_FIELD = "from";
const TO_FIELD = "to";

export default class RodeoFilters {
    constructor(builder) {
        this.containerIdFilter = builder.containerIdFilter;
        this.containerSizeFilter = builder.containerSizeFilter;
        this.containerTypeFilter = builder.containerTypeFilter;
        this.expectedStowDateFilter = builder.expectedStowDateFilter;
        this.fcSkuFilter = builder.fcSkuFilter;
        this.fnSkuFilter = builder.fnSkuFilter;
        this.isaIdFilter = builder.isaIdFilter;
        this.isdIdFilter = builder.isdIdFilter;
        this.isReactiveFilter = builder.isReactiveFilter;
        this.outerContainerIdFilter = builder.outerContainerIdFilter;
        this.outerContainerTypeFilter = builder.outerContainerTypeFilter;
        this.outerScannableIdFilter = builder.outerScannableIdFilter;
        this.outerOuterScannableIdFilter = builder.outerOuterScannableIdFilter;
        this.floorFilter = builder.floorFilter;
        this.modFilter = builder.modFilter;
        this.processPathFilter = builder.processPathFilter;
        this.scannableIdFilter = builder.scannableIdFilter;
        this.sourceWarehouseIdFilter = builder.sourceWarehouseIdFilter;
        this.statusFilter = builder.statusFilter;
        this.trailerDockingTimeFilter = builder.trailerDockingTimeFilter;
        this.vrIdFilter = builder.vrIdFilter;
        this.workPoolFilter = builder.workPoolFilter;
        this.workPoolLastChangedTimeFilter = builder.workPoolLastChangedTimeFilter;
    }

    static get Builder() {
        class Builder {

            withContainerIdFilter(containerIdFilter) {
                this.containerIdFilter = containerIdFilter;
                return this;
            }

            withContainerSizeFilter(containerSizeFilter) {
                this.containerSizeFilter = containerSizeFilter;
                return this;
            }

            withContainerTypeFilter(containerTypeFilter) {
                this.containerTypeFilter = containerTypeFilter;
                return this;
            }

            withExpectedStowDateFilter(expectedStowDateFilter) {
                this.expectedStowDateFilter = expectedStowDateFilter;
                return this;
            }

            withFcSkuFilter(fcSkuFilter) {
                this.fcSkuFilter = fcSkuFilter;
                return this;
            }

            withFnSkuFilter(fnSkuFilter) {
                this.fnSkuFilter = fnSkuFilter;
                return this;
            }

            withISAIdFilter(isaIdFilter) {
                this.isaIdFilter = isaIdFilter;
                return this;
            }

            withISDIdFilter(isdIdFilter) {
                this.isdIdFilter = isdIdFilter;
                return this;
            }

            withIsReactiveFilter(isReactiveFilter) {
                this.isReactiveFilter = isReactiveFilter;
                return this;
            }

            withOuterContainerIdFilter(outerContainerIdFilter) {
                this.outerContainerIdFilter = outerContainerIdFilter;
                return this;
            }

            withOuterContainerTypeFilter(outerContainerTypeFilter) {
                this.outerContainerTypeFilter = outerContainerTypeFilter;
                return this;
            }

            withOuterScannableIdFilter(outerScannableIdFilter) {
                this.outerScannableIdFilter = outerScannableIdFilter;
                return this;
            }

            withOuterOuterScannableIdFilter(outerOuterScannableIdFilter) {
                this.outerOuterScannableIdFilter = outerOuterScannableIdFilter;
                return this;
            }

            withFloorFilter(floorFilter) {
                this.floorFilter = floorFilter;
                return this;
            }

            withModFilter(modFilter) {
                this.modFilter = modFilter;
                return this;
            }

            withProcessPathFilter(processPathFilter) {
                this.processPathFilter = processPathFilter;
                return this;
            }

            withScannableIdFilter(scannableIdFilter) {
                this.scannableIdFilter = scannableIdFilter;
                return this;
            }

            withSourceWarehouseIdFilter(sourceWarehouseIdFilter) {
                this.sourceWarehouseIdFilter = sourceWarehouseIdFilter;
                return this;
            }

            withStatusFilter(statusFilter) {
                this.statusFilter = statusFilter;
                return this;
            }

            withTrailerDockingTimeFilter(trailerDockingTimeFilter) {
                this.trailerDockingTimeFilter = trailerDockingTimeFilter;
                return this;
            }

            withVRIdFilter(vrIdFilter) {
                this.vrIdFilter = vrIdFilter;
                return this;
            }

            withWorkPoolFilter(workPoolFilter) {
                this.workPoolFilter = workPoolFilter;
                return this;
            }

            withWorkPoolLastChangedTimeFilter(workPoolLastChangedTimeFilter) {
                this.workPoolLastChangedTimeFilter = workPoolLastChangedTimeFilter;
                return this;
            }

            build() {
                return new RodeoFilters(this);
            }
        }

        return Builder;
    }
}

export const CONTAINER_ID_FILTER_NAME = "containerIdFilter";
export const CONTAINER_SIZE_FILTER_NAME = "containerSizeFilter";
export const CONTAINER_TYPE_FILTER_NAME = "containerTypeFilter";
export const EXPECTED_STOW_DATE_FILTER_NAME = "expectedStowDateFilter";
export const FC_SKU_FILTER_NAME = "fcSkuFilter";
export const FN_SKU_FILTER_NAME = "fnSkuFilter";
export const ISA_ID_FILTER_NAME = "isaIdFilter";
export const ISD_ID_FILTER_NAME = "isdIdFilter";
export const IS_REACTIVE_FILTER_NAME = "isReactiveFilter";
export const OUTER_CONTAINER_ID_FILTER_NAME = "outerContainerIdFilter";
export const OUTER_CONTAINER_TYPE_FILTER_NAME = "outerContainerTypeFilter";
export const OUTER_SCANNABLE_ID_FILTER_NAME = "outerScannableIdFilter";
export const OUTER_OUTER_SCANNABLE_ID_FILTER_NAME = "outerOuterScannableIdFilter";
export const FLOOR_FILTER_NAME = "floorFilter";
export const MOD_FILTER_NAME = "modFilter";
export const PROCESS_PATH_FILTER_NAME = "processPathFilter";
export const SCANNABLE_ID_FILTER_NAME = "scannableIdFilter";
export const SOURCE_WAREHOUSE_ID_FILTER_NAME = "sourceWarehouseIdFilter";
export const STATUS_FILTER_NAME = "statusFilter";
export const TRAILER_DOCKING_TIME_FILTER_NAME = "trailerDockingTimeFilter";
export const VR_ID_FILTER_NAME = "vrIdFilter";
export const WORK_POOL_FILTER_NAME = "workPoolFilter";
export const WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME = "workPoolLastChangedTimeFilter";

export const RODEO_FILTER_NAME_TO_QUERY_STRING_KEY_MAP = new Map([
    [CONTAINER_ID_FILTER_NAME, RodeoFilterQueryStrings.CONTAINER_ID_QUERY_STRING_KEY],
    [CONTAINER_SIZE_FILTER_NAME, RodeoFilterQueryStrings.CONTAINER_SIZES_QUERY_STRING_KEY],
    [CONTAINER_TYPE_FILTER_NAME, RodeoFilterQueryStrings.CONTAINER_TYPES_QUERY_STRING_KEY],
    [EXPECTED_STOW_DATE_FILTER_NAME, RodeoFilterQueryStrings.EXPECTED_STOW_DATE_QUERY_STRING_KEY],
    [FC_SKU_FILTER_NAME, RodeoFilterQueryStrings.FC_SKU_QUERY_STRING_KEY],
    [FN_SKU_FILTER_NAME, RodeoFilterQueryStrings.FN_SKU_QUERY_STRING_KEY],
    [ISA_ID_FILTER_NAME, RodeoFilterQueryStrings.ISA_ID_QUERY_STRING_KEY],
    [ISD_ID_FILTER_NAME, RodeoFilterQueryStrings.ISD_ID_QUERY_STRING_KEY],
    [IS_REACTIVE_FILTER_NAME, RodeoFilterQueryStrings.IS_REACTIVE_QUERY_STRING_KEY],
    [OUTER_CONTAINER_ID_FILTER_NAME, RodeoFilterQueryStrings.OUTER_CONTAINER_ID_QUERY_STRING_KEY],
    [OUTER_CONTAINER_TYPE_FILTER_NAME, RodeoFilterQueryStrings.OUTER_CONTAINER_TYPES_QUERY_STRING_KEY],
    [OUTER_SCANNABLE_ID_FILTER_NAME, RodeoFilterQueryStrings.OUTER_SCANNABLE_ID_QUERY_STRING_KEY],
    [OUTER_OUTER_SCANNABLE_ID_FILTER_NAME, RodeoFilterQueryStrings.OUTER_OUTER_SCANNABLE_ID_QUERY_STRING_KEY],
    [FLOOR_FILTER_NAME, RodeoFilterQueryStrings.FLOOR_QUERY_STRING_KEY],
    [MOD_FILTER_NAME, RodeoFilterQueryStrings.MOD_QUERY_STRING_KEY],
    [PROCESS_PATH_FILTER_NAME, RodeoFilterQueryStrings.PROCESS_PATHS_QUERY_STRING_KEY],
    [SCANNABLE_ID_FILTER_NAME, RodeoFilterQueryStrings.SCANNABLE_ID_QUERY_STRING_KEY],
    [SOURCE_WAREHOUSE_ID_FILTER_NAME, RodeoFilterQueryStrings.SOURCE_WAREHOUSE_IDS_QUERY_STRING_KEY],
    [STATUS_FILTER_NAME, RodeoFilterQueryStrings.STATUS_QUERY_STRING_KEY],
    [TRAILER_DOCKING_TIME_FILTER_NAME, RodeoFilterQueryStrings.TRAILER_DOCKING_TIME_QUERY_STRING_KEY],
    [VR_ID_FILTER_NAME, RodeoFilterQueryStrings.VR_ID_QUERY_STRING_KEY],
    [WORK_POOL_FILTER_NAME, RodeoFilterQueryStrings.WORK_POOLS_QUERY_STRING_KEY],
    [WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME, RodeoFilterQueryStrings.DWELL_TIME_MILLIS_QUERY_STRING_KEY]
]);

/* Filter Classes */

export class ContainerIdFilter {
}

export class ContainerSizeFilter {
}

export class ContainerTypeFilter {
}

export class ExpectedStowDateFilter {
}

export class FcSkuFilter {
}

export class FnSkuFilter {
}

export class ISAIdFilter {
}

export class ISDIdFilter {
}

export class IsReactiveFilter {
}

export class OuterContainerIdFilter {
}

export class OuterContainerTypeFilter {
}

export class OuterScannableIdFilter {
}

export class OuterOuterScannableIdFilter {
}

export class FloorFilter {
}

export class ModFilter {
}

export class ProcessPathFilter {
}

export class ScannableIdFilter {
}

export class SourceWarehouseIdFilter {
}

export class StatusFilter {
}

export class TrailerDockingTimeFilter {
}

export class VRIdFilter {    
}

export class WorkPoolFilter {
}

export class WorkPoolLastChangedTimeFilter {
}

class FilterFactory {
    static getClassName(value) {
        if (value === CONTAINER_ID_FILTER_NAME) {
            return ContainerIdFilter;
        } else if (value === CONTAINER_SIZE_FILTER_NAME) {
            return ContainerSizeFilter;
        } else if (value === CONTAINER_TYPE_FILTER_NAME) {
            return ContainerTypeFilter;
        } else if (value === EXPECTED_STOW_DATE_FILTER_NAME) {
            return ExpectedStowDateFilter;
        } else if (value === FC_SKU_FILTER_NAME) {
            return FcSkuFilter;
        } else if (value === FN_SKU_FILTER_NAME) {
            return FnSkuFilter;
        } else if (value === ISA_ID_FILTER_NAME) {
            return ISAIdFilter;
        } else if (value === ISD_ID_FILTER_NAME) {
            return ISDIdFilter;
        } else if (value === IS_REACTIVE_FILTER_NAME) {
            return IsReactiveFilter;
        } else if (value === OUTER_CONTAINER_ID_FILTER_NAME) {
            return OuterContainerIdFilter;
        } else if (value === OUTER_CONTAINER_TYPE_FILTER_NAME) {
            return OuterContainerTypeFilter;
        } else if (value === OUTER_SCANNABLE_ID_FILTER_NAME) {
            return OuterScannableIdFilter;
        } else if (value === OUTER_OUTER_SCANNABLE_ID_FILTER_NAME) {
            return OuterOuterScannableIdFilter;
        } else if (value === FLOOR_FILTER_NAME) {
            return FloorFilter;
        } else if (value === MOD_FILTER_NAME) {
            return ModFilter;
        } else if (value === PROCESS_PATH_FILTER_NAME) {
            return ProcessPathFilter;
        } else if (value === SCANNABLE_ID_FILTER_NAME) {
            return ScannableIdFilter;
        } else if (value === SOURCE_WAREHOUSE_ID_FILTER_NAME) {
            return SourceWarehouseIdFilter;
        } else if (value === STATUS_FILTER_NAME) {
            return StatusFilter;
        } else if (value === TRAILER_DOCKING_TIME_FILTER_NAME) {
            return TrailerDockingTimeFilter;
        } else if (value === VR_ID_FILTER_NAME) {
            return VRIdFilter;
        }else if (value === WORK_POOL_FILTER_NAME) {
            return WorkPoolFilter;
        } else if (value === WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME) {
            return WorkPoolLastChangedTimeFilter;
        }
    }
}

/* Filter Types */
export const STRING_FILTER_TYPE = 'StringFilterType';
export const STRING_LIST_FILTER_TYPE = 'StringListFilterType';
export const STRING_PREFIX_FILTER_TYPE = 'StringPrefixFilterType';
export const STRING_LIST_PREFIX_FILTER_TYPE = 'StringListPrefixFilterType';
export const BOOLEAN_FILTER_TYPE = 'BooleanFilterType';
export const LONG_FILTER_TYPE = 'LongFilterType';
export const DATE_RANGE_FILTER_TYPE = 'DateRangerFilterType';

class StringFilterType {
    withValue(value) {
        this[VALUE_FIELD] = value;
        return this;
    }
}

class StringListFilterType {
    withValues(values) {
        this[VALUES_FIELD] = _.isArray(values) ? values : [values];
        return this;
    }
}

class StringListPrefixFilterType {
    withValues(prefixValues) {
        this[VALUES_FIELD] = _.isArray(prefixValues) ? prefixValues : [prefixValues];
        return this;
    }
}

class StringPrefixFilterType {
    withPrefix(prefix) {
        this[PREFIX_FIELD] = prefix;
        return this;
    }
}

class BooleanFilterType {
    withValue(boolean) {
        this[VALUE_FIELD] = boolean;
        return this;
    }
}

class LongFilterType {
    withValue(long) {
        this[VALUE_FIELD] = long;
        return this;
    }
}

class DateRangeFilterType {
    withFrom(timestamp) {
        this[FROM_FIELD] = timestamp;
        return this;
    }

    withTo(timestamp) {
        this[TO_FIELD] = timestamp;
        return this;
    }
}

class FilterTypeFactory {
    static getClassName(value) {
        if (value === STRING_FILTER_TYPE) {
            return StringFilterType;
        } else if (value === STRING_PREFIX_FILTER_TYPE) {
            return StringPrefixFilterType;
        } else if (value === STRING_LIST_FILTER_TYPE) {
            return StringListFilterType;
        } else if (value === STRING_LIST_PREFIX_FILTER_TYPE) {
            return StringListPrefixFilterType;
        } else if (value === BOOLEAN_FILTER_TYPE) {
            return BooleanFilterType;
        } else if (value === LONG_FILTER_TYPE) {
            return LongFilterType;
        } else if (value === DATE_RANGE_FILTER_TYPE) {
            return DateRangeFilterType;
        }
    }
}

export const FILTER_NAME_TO_TYPE_MAP = new Map([
    [CONTAINER_ID_FILTER_NAME, LONG_FILTER_TYPE],
    [CONTAINER_SIZE_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [CONTAINER_TYPE_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [WORK_POOL_FILTER_NAME, STRING_LIST_FILTER_TYPE],
    [WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME, DATE_RANGE_FILTER_TYPE],
    [SOURCE_WAREHOUSE_ID_FILTER_NAME, STRING_LIST_FILTER_TYPE],
    [STATUS_FILTER_NAME, STRING_LIST_FILTER_TYPE],
    [FC_SKU_FILTER_NAME, STRING_FILTER_TYPE],
    [FN_SKU_FILTER_NAME, STRING_FILTER_TYPE],
    [ISA_ID_FILTER_NAME, LONG_FILTER_TYPE],
    [ISD_ID_FILTER_NAME, LONG_FILTER_TYPE],
    [IS_REACTIVE_FILTER_NAME, BOOLEAN_FILTER_TYPE],
    [EXPECTED_STOW_DATE_FILTER_NAME, DATE_RANGE_FILTER_TYPE],
    [TRAILER_DOCKING_TIME_FILTER_NAME, DATE_RANGE_FILTER_TYPE],
    [PROCESS_PATH_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [SCANNABLE_ID_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [OUTER_CONTAINER_ID_FILTER_NAME, LONG_FILTER_TYPE],
    [OUTER_CONTAINER_TYPE_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [OUTER_SCANNABLE_ID_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [OUTER_OUTER_SCANNABLE_ID_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [FLOOR_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [MOD_FILTER_NAME, STRING_LIST_PREFIX_FILTER_TYPE],
    [VR_ID_FILTER_NAME, STRING_FILTER_TYPE]
]);

[...FILTER_NAME_TO_TYPE_MAP.entries()].forEach(([filterName, filterType]) => classMixin(filterName, filterType));

function classMixin(sourceClassName, ...mixinNames) {
    const filterClass = FilterFactory.getClassName(sourceClassName);
    for (let mixinName of mixinNames) {
        const mixinClass = FilterTypeFactory.getClassName(mixinName);
        for (let key of Object.getOwnPropertyNames(mixinClass.prototype)) {
            filterClass.prototype[key] = mixinClass.prototype[key];
        }
    }
}

