import React, {Component} from 'react';

export default class NotFound404 extends Component {

    render(){
        return(
          <div className="jumbotron text-center text-danger">
              <h4>Uh, oh!</h4>
              <div>No page found here</div>
          </div>
        );
    }
}