import React, {Component} from 'react';
import {Spinner} from 'react-bootstrap';

export default class DataLoading extends Component {
    render() {
        return (
            <div className="container h-100">
                <div className={`row h-100 justify-content-${this.props.justifyContent || 'center'} align-items-center`}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            </div>
        );
    }
}
