
export default class ItemListRequestInput {

    constructor(builder) {
        this.warehouseId = builder.warehouseId;
        this.batchSize = builder.batchSize;
        this.startIndex = builder.startIndex;
        this.rodeoFilters = builder.rodeoFilters;
    }

    static get Builder() {
        class Builder {

            withWarehouseId(warehouseId) {
                this.warehouseId = warehouseId;
                return this;
            }

            withBatchSize(batchSize) {
                this.batchSize = batchSize;
                return this;
            }

            withStartIndex(startIndex) {
                this.startIndex = startIndex;
                return this;
            }

            withRodeoFilters(rodeoFilters) {
                this.rodeoFilters = rodeoFilters;
                return this;
            }

            build() {
                return new ItemListRequestInput(this);
            }
        }

        return Builder;
    }
}