import React, {PureComponent} from 'react';
import _ from 'lodash';

export default class AggregateOptionInputGroup extends PureComponent {

    constructor(props) {
        super(props);

        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleRemoveExtraInput = this.handleRemoveExtraInput.bind(this);
    }

    render() {
        const isArray = _.isArray(this.props.filterValue);
        const endIndex = this.props.filterValue.length - 1;
        return isArray ? this.props.filterValue.map((fieldValue, index) =>
                this.generateInputGroup(fieldValue, index, endIndex)) :
            this.generateInputGroup(this.props.filterValue);
    }

    generateInputGroup(fieldValue, index, endIndex) {
        return <div className="input-group mb-3" key={index}>
            <input id={`${index}-${this.props.filterName}-keys`}
                   key={`${index}-input-${this.props.propertyName}`}
                   type="text"
                   className="form-control"
                   name={index}
                   value={fieldValue}
                   onChange={this.handleValueChange}/>
            {index !== endIndex &&
            <div className="input-group-append">
                <span className="input-group-text fa fa-times clickable" id={index} onClick={this.handleRemoveExtraInput}/>
            </div>}
        </div>
    }

    handleRemoveExtraInput(event) {
        const newValueArray = [...this.props.filterValue];
        const deletedInputFieldId = _.parseInt(event.target.id);
        newValueArray.splice(deletedInputFieldId, 1);
        this.props.updateInputChange(this.props.filterName, newValueArray);
    }

    handleValueChange(event) {
        const inputFieldIndex = _.parseInt(event.target.name);
        const newFilterValue = event.target.value;

        if (_.isArray(this.props.filterValue)) {
            const newValueArray = [...this.props.filterValue];
            newValueArray[inputFieldIndex] = newFilterValue;
            this.props.updateInputChange(this.props.filterName, newValueArray);
        } else {
            this.props.updateInputChange(this.props.filterName, newFilterValue);
        }
    }
}
