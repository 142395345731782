import _ from 'lodash';
import moment from 'moment-timezone';

import {VIEW_NAMES_ENUM} from "./ViewNames";
import {VIEW_NAME_TO_TIME_RANGE_PROPERTY_MAP} from './AggregateOptionParameters';
import {
    DEFAULT_AXIS_VALUES_ENUM,
    MAX_DIMENSION_NUMBER,
    SUMMATION_NAMES_ENUM,
    NON_POST_RECEIVE_STATUS_ENUM,
    FORECASTED_SHIPMENT_TYPES_ENUM,
    UNIFIED_SHIPMENT_TYPES_MAP
} from './Dimensions';
import {BUCKET_SIZES_IN_SECONDS} from './Buckets';
import {DateUtilities} from '../util/DateUtilities';
import {WorkpoolUtilities} from '../util/WorkpoolUtilities';

const AGGREGATE_RESULT_MAP = 'aggregateResultMap';
const COLUMN_DISPLAYED_ID_ARRAY = 'columnDisplayedIdArray';
const AGGREGATION_ACTION_TIME_STAMP = 'aggregationActionTimeStamp';
const CONVERT_ARRAY_MEMBER_TO_SET = (array) => {
    return new Set(array)
};
const CREATE_AND_RETURN_SET_BY_DEFAULT = () => {
    return new Set()
};
const CREATE_AND_RETURN_MAP_BY_DEFAULT = () => {
    return new Map()
};

export default class AggregationAction {

    constructor(viewName, aggregateParameters, warehouseTimezone, rodeoFilters) {
        this.viewName = viewName;
        this.warehouseTimezone = warehouseTimezone;
        this.aggregateParameters = {...aggregateParameters};
        this.rodeoFilters = {...rodeoFilters};
    }

    generateDisplayedBucketTimeStampArray(displayedBucketIdSet) {
        return _.toArray(displayedBucketIdSet).sort()
    }

    getAggregateDimensionReMapObject(aggregateParameters, aggregateDimensionListInResponse) {
        const aggregateAxisToResponseAxisMap = new Map([[aggregateParameters.xAxis, null], [aggregateParameters.yAxis, null]]);
        if (aggregateParameters.zAxis !== DEFAULT_AXIS_VALUES_ENUM.Z_DEFAULT) {
            aggregateAxisToResponseAxisMap.set(aggregateParameters.zAxis, null);
        }
        let i = 0, bucketReferencePoint = null;
        aggregateDimensionListInResponse.forEach((aggregateDimensionResponseObject) => {
            aggregateAxisToResponseAxisMap.set(_.split(aggregateDimensionResponseObject["__type"], ':')[0], i++);
            if (!_.isNil(aggregateDimensionResponseObject["bucketReferencePoint"])) {
                bucketReferencePoint = aggregateDimensionResponseObject["bucketReferencePoint"];
            }
        });
        return {
            index: [...aggregateAxisToResponseAxisMap.values()],
            names: [...aggregateAxisToResponseAxisMap.keys()].reverse(),
            bucketReferencePoint
        }
    }

    getExistedOrEmptyAggregateResultMember(previousAggregatedResult,
                                           aggregateResultMemberName,
                                           emptyMemberAction,
                                           additionalAction = null) {
        if (_.isNil(previousAggregatedResult)) {
            return emptyMemberAction();
        } else {
            if (_.isMap(previousAggregatedResult)) {
                return new Map(_.toPairs(previousAggregatedResult).map(([zDimensionValue, aggregateResultObject]) => {
                    let targetObject = aggregateResultObject[aggregateResultMemberName];
                    if (!_.isNil(additionalAction)) {
                        targetObject = additionalAction(targetObject);
                    }
                    return [zDimensionValue, targetObject];
                }));
            }
            let targetObject = previousAggregatedResult[aggregateResultMemberName];
            if (!_.isNil(additionalAction)) {
                targetObject = additionalAction(targetObject);
            }
            return targetObject;
        }
    }

    getTimeInterval() {
        let timeInterval = null;
        if (this.viewName === VIEW_NAMES_ENUM.DWELL_TIME) {
            if (!_.isNil(this.rodeoFilters.workPoolLastChangedTimeFilter)) {
                timeInterval = {
                    start: this.rodeoFilters.workPoolLastChangedTimeFilter.from,
                    end: this.rodeoFilters.workPoolLastChangedTimeFilter.to
                };
            }
        } else {
            timeInterval = DateUtilities.getStartAndEndMillisFromSelectedRangeName(this.aggregateParameters[VIEW_NAME_TO_TIME_RANGE_PROPERTY_MAP.get(this.viewName)], this.warehouseTimezone);
        }
        return timeInterval;
    }

    addItemObjectToTargetBucketInMap(aggregationActionTimeStamp, bucketSize, timeStamp, timeInterval, itemObject, targetMap, rowDisplayedIdsSet) {

        const bucketTimeStamp = this.getBucketTimeStamp(timeStamp, bucketSize, aggregationActionTimeStamp);
        if (_.isNil(bucketTimeStamp)) {
            return;
        }

        AggregationAction.addValueToMapById(targetMap, SUMMATION_NAMES_ENUM.TOTAL, itemObject, AggregationAction.addObject);

        if (!_.isNil(timeInterval)) {
            if (timeStamp > timeInterval.end) {
                AggregationAction.addValueToMapById(targetMap, SUMMATION_NAMES_ENUM.LATER_TOTAL, itemObject, AggregationAction.addObject);
            } else if (timeStamp < timeInterval.start) {
                AggregationAction.addValueToMapById(targetMap, SUMMATION_NAMES_ENUM.EARLIER_TOTAL, itemObject, AggregationAction.addObject);
            } else {
                AggregationAction.addValueToMapById(targetMap, SUMMATION_NAMES_ENUM.RANGE_TOTAL, itemObject, AggregationAction.addObject);
                AggregationAction.addValueToMapById(targetMap, bucketTimeStamp, itemObject, AggregationAction.addObject);
                rowDisplayedIdsSet.add(bucketTimeStamp);
            }
        } else {
            AggregationAction.addValueToMapById(targetMap, bucketTimeStamp, itemObject, AggregationAction.addObject);
            rowDisplayedIdsSet.add(bucketTimeStamp);
        }
    }

    getBucketTimeStamp(timeStamp, bucketSize, aggregationActionTimeStamp) {
        if (this.viewName === VIEW_NAMES_ENUM.DWELL_TIME) {
            if (timeStamp > aggregationActionTimeStamp) {
                return null;
            }
            return aggregationActionTimeStamp - bucketSize * _.ceil((aggregationActionTimeStamp - timeStamp) / bucketSize);
        } else {
            return timeStamp - timeStamp % bucketSize;
        }
    }

    aggregate(responseAsJson, previousAggregatedResult) {
        const bucketSize = BUCKET_SIZES_IN_SECONDS.get(this.aggregateParameters.bucketSize);
        const timeInterval = this.getTimeInterval();
        const aggregateDimensionReMapObject = this.getAggregateDimensionReMapObject(this.aggregateParameters, responseAsJson.aggregateDimensionList);
        const shipmentTypes = responseAsJson.shipmentTypes;
        const aggregateDimensionIndex = aggregateDimensionReMapObject.index;
        let aggregateList = responseAsJson.aggregateList;
        const aggregationActionTimeStamp = this.getAggregationActionTimeStamp(previousAggregatedResult, aggregateDimensionReMapObject);

        const aggregateListMap = this.getExistedOrEmptyAggregateResultMember(previousAggregatedResult,
            AGGREGATE_RESULT_MAP,
            CREATE_AND_RETURN_MAP_BY_DEFAULT);

        let rowDisplayedIdsSet = null;
        let dimensionValueToRowDisplayedIdMap = null;
        let is3DAggregation = false;
        if (aggregateDimensionIndex.length === MAX_DIMENSION_NUMBER) {
            dimensionValueToRowDisplayedIdMap = this.getExistedOrEmptyAggregateResultMember(previousAggregatedResult,
                COLUMN_DISPLAYED_ID_ARRAY,
                CREATE_AND_RETURN_MAP_BY_DEFAULT,
                CONVERT_ARRAY_MEMBER_TO_SET);
            is3DAggregation = true;
        } else {
            rowDisplayedIdsSet = this.getExistedOrEmptyAggregateResultMember(previousAggregatedResult,
                COLUMN_DISPLAYED_ID_ARRAY,
                CREATE_AND_RETURN_SET_BY_DEFAULT,
                CONVERT_ARRAY_MEMBER_TO_SET);
        }

        if (this.viewName !== VIEW_NAMES_ENUM.POST_RECEIVE) {
            const subworkPoolAggregateListItems = [];
            let suffix = "";
            if (!_.isEmpty(shipmentTypes) && _.includes(_.values(FORECASTED_SHIPMENT_TYPES_ENUM), shipmentTypes[0])) {
                suffix = NON_POST_RECEIVE_STATUS_ENUM.FORECASTED;
            } else {
                suffix = NON_POST_RECEIVE_STATUS_ENUM.ACTUALS;
            }
            aggregateList.forEach((aggregateListItem) => {
                if (aggregateListItem.quantity !== 0) {
                    const dimensionValueList = aggregateListItem.aggregateDimensionValueList;
                    for (let j = 1; j <= aggregateDimensionIndex.length - 1; j++) {
                        const dimension = dimensionValueList[aggregateDimensionIndex[j]];
                        if (_.isNil(dimension)) {
                            return;
                        }
                        const dimensionValue = dimension.value;
                        const dimensionValueWithSuffix = dimensionValue + suffix;
                        if (WorkpoolUtilities.isAParentWorkPool(dimensionValue) && _.includes(this.rodeoFilters.workPoolFilter.values, dimensionValueWithSuffix)) {
                            const aggregateListItemClone = _.cloneDeep(aggregateListItem);
                            aggregateListItemClone.aggregateDimensionValueList[aggregateDimensionIndex[j]].value = dimensionValueWithSuffix;
                            subworkPoolAggregateListItems.push(aggregateListItemClone);
                        }
                    }
                }
            });
            aggregateList = _.concat(aggregateList, subworkPoolAggregateListItems);
        }

        aggregateList.forEach((aggregateListItem) => {
            if (aggregateListItem.quantity !== 0) {
                const itemQuantity = aggregateListItem.quantity;
                const dimensionValueList = aggregateListItem.aggregateDimensionValueList;
                const pastDimensionValues = [];
                const pastAggregateListMapPointers = [];
                let aggregateListMapKeyCursor = aggregateListMap;
                for (let j = aggregateDimensionIndex.length - 1; j > 0; j--) {
                    const dimension = dimensionValueList[aggregateDimensionIndex[j]];
                    if (_.isNil(dimension)) {
                        AggregationAction.destroyCreatedAggregateMapsForNullDimensionValue(pastDimensionValues, pastAggregateListMapPointers);
                        return;
                    }
                    const dimensionValue = dimension.value;
                    if (is3DAggregation && j === MAX_DIMENSION_NUMBER - 1) {
                        if (!dimensionValueToRowDisplayedIdMap.has(dimensionValue)) {
                            dimensionValueToRowDisplayedIdMap.set(dimensionValue, new Set());
                        }
                        rowDisplayedIdsSet = dimensionValueToRowDisplayedIdMap.get(dimensionValue);
                    }
                    if (!aggregateListMapKeyCursor.has(dimensionValue)) {
                        aggregateListMapKeyCursor.set(dimensionValue, new Map());
                    }
                    pastAggregateListMapPointers.push(aggregateListMapKeyCursor);
                    pastDimensionValues.push(dimensionValue);
                    aggregateListMapKeyCursor = aggregateListMapKeyCursor.get(dimensionValue);
                }

                if (_.isNil(dimensionValueList[aggregateDimensionIndex[0]])) {
                    AggregationAction.destroyCreatedAggregateMapsForNullDimensionValue(pastDimensionValues, pastAggregateListMapPointers);
                    return;
                }

                const timeStamp = _.parseInt(dimensionValueList[aggregateDimensionIndex[0]].value);
                const itemObject = {quantity: itemQuantity, shipmentTypes: AggregationAction.getUnifiedShipmentTypes(shipmentTypes)};

                this.addItemObjectToTargetBucketInMap(aggregationActionTimeStamp,
                    bucketSize,
                    timeStamp,
                    timeInterval,
                    itemObject,
                    aggregateListMapKeyCursor,
                    rowDisplayedIdsSet);
            }
        });

        if (is3DAggregation) {
            return new Map(_.toPairs(aggregateListMap).map(([zDimensionValue, aggregateListMapPerDimensionValue]) => {
                const displayedBucketIdArray = this.generateDisplayedBucketTimeStampArray(dimensionValueToRowDisplayedIdMap.get(zDimensionValue));
                return [zDimensionValue, {
                    columnDisplayedIdArray: displayedBucketIdArray,
                    aggregateResultMap: aggregateListMapPerDimensionValue,
                    dimensionList: aggregateDimensionReMapObject.names,
                    hasTimeRange: !_.isNil(timeInterval),
                    timeRange: timeInterval,
                    aggregationActionTimeStamp: aggregationActionTimeStamp,
                    zDimensionValue: zDimensionValue
                }];
            }));
        } else {
            const displayedBucketIdArray = this.generateDisplayedBucketTimeStampArray(rowDisplayedIdsSet);
            return {
                columnDisplayedIdArray: displayedBucketIdArray,
                aggregateResultMap: aggregateListMap,
                dimensionList: aggregateDimensionReMapObject.names,
                hasTimeRange: !_.isNil(timeInterval),
                timeRange: timeInterval,
                aggregationActionTimeStamp: aggregationActionTimeStamp
            };
        }
    }

    getAggregationActionTimeStamp(previousAggregatedResult, aggregateDimensionReMapObject) {
        if (!_.isNil(previousAggregatedResult)) {
            if (_.isMap(previousAggregatedResult)) {
                if (!_.isNil(_.last(_.head(_.toPairs(previousAggregatedResult))))) {
                    return _.last(_.head(_.toPairs(previousAggregatedResult)))[AGGREGATION_ACTION_TIME_STAMP];
                } else {
                    return this.getAggregationActionTimeStampForEmptyAggregationResult(aggregateDimensionReMapObject);
                }
            } else {
                return previousAggregatedResult[AGGREGATION_ACTION_TIME_STAMP];
            }
        } else {
            return this.getAggregationActionTimeStampForEmptyAggregationResult(aggregateDimensionReMapObject);
        }
    }

    getAggregationActionTimeStampForEmptyAggregationResult(aggregateDimensionReMapObject) {
        if (this.viewName === VIEW_NAMES_ENUM.DWELL_TIME) {
            return aggregateDimensionReMapObject.bucketReferencePoint;
        } else {
            return moment.tz(this.warehouseTimezone).unix();
        }
    }

    static addValueToMapById(map, key, value, addAction = null, defaultValue = 0) {
        if (!map.has(key)) {
            map.set(key, defaultValue);
        }
        if (_.isNil(addAction)) {
            map.set(key, map.get(key) + value);
        } else {
            map.set(key, addAction(map.get(key), value, defaultValue));
        }
    }

    static addObject(addendObject, newObject, defaultValue) {
        if (_.isEqual(addendObject, defaultValue)) {
            return newObject;
        }
        return {
            quantity: addendObject.quantity + newObject.quantity,
            shipmentTypes: _.union(addendObject.shipmentTypes, newObject.shipmentTypes)
        };
    }

    static destroyCreatedAggregateMapsForNullDimensionValue(pastDimensionValues, pastAggregateListMapPointers) {
        while (!_.isEmpty(pastDimensionValues)) {
            const lastDimensionValue = pastDimensionValues.pop();
            const lastAggregateListMapPointer = pastAggregateListMapPointers.pop();
            if (!_.isEmpty(lastAggregateListMapPointer.get(lastDimensionValue))) {
                break;
            }
            lastAggregateListMapPointer.delete(lastDimensionValue);
        }
    }

    static getUnifiedShipmentTypes(shipmentTypes){
        return shipmentTypes.map(shipmentType => UNIFIED_SHIPMENT_TYPES_MAP.has(shipmentType) ? UNIFIED_SHIPMENT_TYPES_MAP.get(shipmentType) : shipmentType);
    }


}
