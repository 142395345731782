import * as utils from '@amzn/aftx-common/dist/utils/filter_utils';
const aftxDomainSuffix = '.aftx.amazonoperations.app';
 
export const isAftxDomain = () => {
  return window.location.hostname.endsWith(aftxDomainSuffix);
};
 
export const getAftxWarehouse = () => {
  return utils.getAFTXWarehouseId(document.cookie);
};

export const getAftxMenuUrl = () => {
  return utils.getDefaultAFTXMenuURLWithReferrer();
}