import moment from 'moment-timezone';
import _ from 'lodash';

import {DateUtilities} from '../../util/DateUtilities';
import {WORKPOOL_PROPERTY_NAME} from '../../pojos/PropertyNames';

export default class ForecastedItemListRecord {

    constructor(builder) {
        this.containerSize = builder.containerSize;
        this.containerType = builder.containerType;
        this.dwellTime = builder.dwellTime;
        this.dwellTimeFormatted = builder.dwellTimeFormatted;
        this.id = builder.id;
        this.isaId = builder.isaId;
        this.processPath = builder.processPath;
        this.quantity = builder.quantity;
        this.sourceWarehouseIdOrSCACCode = builder.sourceWarehouseIdOrSCACCode;
        this.trailerDockingTime = builder.trailerDockingTime;
        this.vrId = builder.vrId;
        this.warehouseId = builder.warehouseId;
        this.workPool = builder.workPool;
        this.workPoolLastChangedTime = builder.workPoolLastChangedTime;
    }

    static get Builder() {
        class Builder {

            constructor(id) {
                this.id = id;
            }

            withContainerSize(containerSize) {
                this.containerSize = containerSize;
                return this;
            }

            withContainerType(containerType) {
                this.containerType = containerType;
                return this;
            }

            withIsaId(isaId) {
                this.isaId = isaId;
                return this;
            }

            withProcessPath(processPath) {
                this.processPath = processPath;
                return this;
            }

            withQuantity(quantity) {
                this.quantity = quantity;
                return this;
            }

            withSourceWarehouseIdOrSCACCode(sourceWarehouseIdOrSCACCode) {
                this.sourceWarehouseIdOrSCACCode = sourceWarehouseIdOrSCACCode;
                return this;
            }

            withTrailerDockingTime(trailerDockingTime, timezone) {
                if(!_.isNil(trailerDockingTime)) {
                    trailerDockingTime = DateUtilities.formatDate(trailerDockingTime, timezone);
                }
                this.trailerDockingTime = trailerDockingTime;
                return this;
            }

            withVrId(vrId) {
                this.vrId = vrId;
                return this;
            }

            withWarehouseId(warehouseId) {
                this.warehouseId = warehouseId;
                return this;
            }

            withWorkPool(workPool) {
                this.workPool = workPool;
                return this;
            }

            withWorkPoolLastChangedTime(workPoolLastChangedTime) {
                this.workPoolLastChangedTime = workPoolLastChangedTime;
                this.dwellTime = moment.now() - moment.unix(this.workPoolLastChangedTime);
                this.dwellTimeFormatted = DateUtilities.formatItemListViewDwellTime(this.dwellTime);
                return this;
            }

            build() {
                return new ForecastedItemListRecord(this);
            }
        }

        return Builder;
    }

    static createForecastedItemListRecordFromJSON(itemListRecordJSON, warehouseTimezone) {
            return new ForecastedItemListRecord.Builder(itemListRecordJSON.id)
                .withContainerSize(itemListRecordJSON.containerSize)
                .withContainerType(itemListRecordJSON.containerType)
                .withIsaId(itemListRecordJSON.isaId)
                .withQuantity(itemListRecordJSON.quantity)
                .withProcessPath(itemListRecordJSON.processPath)
                .withSourceWarehouseIdOrSCACCode(itemListRecordJSON.sourceWarehouseIdOrSCACCode)
                .withTrailerDockingTime(itemListRecordJSON.trailerDockingTime, warehouseTimezone)
                .withVrId(itemListRecordJSON.vrId)
                .withWarehouseId(itemListRecordJSON.warehouseId)
                .withWorkPool(itemListRecordJSON.workPool)
                .withWorkPoolLastChangedTime(itemListRecordJSON.workPoolLastChangedTime)
                .build();
    }
}

export const FORECASTED_DEFAULT_PROPERTY_NAME_TO_COLUMN_NAME_MAP = new Map([
    ["id", "Id"],
    ["containerSize", "Container Size"],
    ["containerType", "Container Type"],
    ["trailerDockingTime", "Trailer Docking Time"],
    ["isaId", "Isa Id"],
    ["quantity", "Quantity"],
    ["vrId", "VRID"],
    [WORKPOOL_PROPERTY_NAME, "Work Pool"]
])

export const FORECASTED_TRANSSHIPMENT_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP = new Map([
    ["sourceWarehouseIdOrSCACCode", "Source WID"],
]);

export const FORECASTED_OPTIONS_PROPERTY_NAME_TO_COLUMN_NAME_MAP = new Map([
    ["processPath", "Process Path"]
]);
