import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import { ButtonGroup, Button } from "react-bootstrap";

import "../../../css/footer.scss";
import { isAftxDomain } from "../util/AftxUtilities";

class Footer extends Component {
  render() {
    return (
        <footer className="footer mt-auto py-2 fixed-bottom">
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-md-12">
                        {!isAftxDomain() && (
                            <ButtonGroup aria-label="Useful Links" size="sm">
                                <Button size="sm" variant="outline-secondary" target="_blank" href={`http://tiny/submit/url?name=${encodeURIComponent(window.location.href)}`}>
                                    <i className="fa fa-external-link"/> TinyURL
                                </Button>
                                <Button size="sm" variant="outline-secondary" target="_blank" href="https://tiny.amazon.com/w3oglav9/starwwopamazdevboarbrow" rel="noopener noreferrer">
                                    User Guide <i className="fa fa-info-circle"/>
                                </Button>
                            </ButtonGroup>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <i className="fa fa-copyright">&nbsp;{moment().year()} Amazon Internal</i>
                    </div>
                </div>
            </div>
        </footer>);
    }
}

export default withRouter(Footer);
