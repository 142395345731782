import _ from 'lodash';
import {
    ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM,
    FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM,
    TOP_PRE_RECEIVE_WORKPOOLS_ENUM
} from '../pojos/WorkPools';
import {NON_POST_RECEIVE_STATUS_ENUM} from '../pojos/Dimensions';

export class WorkpoolUtilities {

    static findParentWorkPool(workPool) {
        if (_.includes(Object.values(ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM), workPool)) {
            return workPool.substring(0, workPool.indexOf(NON_POST_RECEIVE_STATUS_ENUM.ACTUALS));
        }
        if (_.includes(Object.values(FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM), workPool)) {
            return workPool.substring(0, workPool.indexOf(NON_POST_RECEIVE_STATUS_ENUM.FORECASTED));
        }
        return workPool;
    }

    static isASubWorkPool(workPool) {
        return _.includes(Object.values(ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM), workPool) || _.includes(Object.values(FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM), workPool);
    }
    static isAParentWorkPool(workPool) {
        return _.includes(Object.values(TOP_PRE_RECEIVE_WORKPOOLS_ENUM), workPool);
    }
}
