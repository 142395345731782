import AWS from "aws-sdk";

import AxiosClient from "./AxiosClient";
import * as aftxutils from "../components/util/AftxUtilities"
import * as config from "./config";

export default class AftxHttpClient {
  init = (callback) => {
    this.getCredentials().then((data) => {
      const sts = new AWS.STS();
      const currentRegion = data.region;

      sts.assumeRoleWithWebIdentity(
        {
          WebIdentityToken: data.token,
          RoleSessionName: "aftxUser",
          RoleArn: data.roleArn,
        },
        (err, data) => {
          if (err || !data.Credentials) {
            throw new Error("Could not assume the IAM role");
          }
          const creds = new AWS.Credentials(
            data.Credentials.AccessKeyId,
            data.Credentials.SecretAccessKey,
            data.Credentials.SessionToken
          );
          creds.get((err) => {
            if (err) {
              throw new Error("Could not get Cognito credentials");
            }
            const client = new AxiosClient(
              creds,
              config.getAftxApiDomainName(currentRegion),
              "execute-api",
              currentRegion
            );
            callback(client);
          });
        }
      );
    });
  };

  getCredentials = () => {
      return fetch(config.AftxCredentialsApiDomainName, {
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok) {
            window.location.href = aftxutils.getAftxMenuUrl();
          }
          return response.json();
        })
        .then((data) => {
          return data;
        }).catch(() => {
          window.location.href = aftxutils.getAftxMenuUrl();
      });
  };
}
