import moment from 'moment-timezone';
import {DATE_RANGE_ENUM} from '../pojos/Dimensions';
import _ from 'lodash';

export const DEFAULT_TIME_ZONE = 'UTC';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export class DateUtilities {

    static formatAggregateViewDwellTime(durationMillis) {
        const duration = moment.duration(durationMillis);
        if (_.floor(duration.asDays()) > 0) {
            return `${_.floor(duration.asDays())}d ${duration.hours()}h`;
        }
        if (duration.hours() > 0) {
            return `${duration.hours()}h ${duration.minutes()}m`;
        }
        if (duration.minutes() > 0) {
            return `${duration.minutes()}m`;
        }
        return '<1 minute';
    }

    static formatItemListViewDwellTime(durationMillis){
        const duration = moment.duration(durationMillis);
        if (duration.months() > 0) {
            return '>1 month';
        }
        if (duration.weeks() > 0) {
            return `${duration.weeks()}w ${duration.days() % 7}d`;
        }
        if (duration.days() > 0) {
            return `${duration.days()}d ${duration.hours()}h`;
        }
        if (duration.hours() > 0) {
            return `${duration.hours()}h ${duration.minutes()}m`;
        }
        if (duration.minutes() > 0) {
            return `${duration.minutes()}m`;
        }
        return '<1 minute';
    }

    static formatDate(dateTimeMillis, timezone = DEFAULT_TIME_ZONE) {
        return moment.unix(dateTimeMillis).tz(timezone).format(DATE_TIME_FORMAT);
    }

    static getStartAndEndMillisFromSelectedRangeName(rangeName, timeZone = DEFAULT_TIME_ZONE) {
        const day = 'day';
        const shortHandDay = 'd';
        switch (rangeName) {
            case DATE_RANGE_ENUM.ALL:
                return null;
            case DATE_RANGE_ENUM.TODAY: {
                const start = moment().tz(timeZone).startOf(day).unix();
                const end = moment().tz(timeZone).endOf(day).unix();
                return {start: start, end: end};
            }
            case DATE_RANGE_ENUM.NEXT_3_DAYS: {
                const start = moment().tz(timeZone).endOf(day).unix() + 1;
                const end = moment.unix(start).add(3, shortHandDay).unix();
                return {start: start, end: end};
            }
            case DATE_RANGE_ENUM.PLUS_MINUS_ONE_DAY: {
                const start = moment().tz(timeZone).startOf(day).subtract(1, shortHandDay).unix();
                const end = moment().tz(timeZone).endOf(day).add(1, shortHandDay).unix();
                return {start: start, end: end};
            }
            case DATE_RANGE_ENUM.PLUS_MINUS_THREE_DAYS: {
                const start = moment().tz(timeZone).startOf(day).subtract(3, shortHandDay).unix();
                const end = moment().tz(timeZone).endOf(day).add(3, shortHandDay).unix();
                return {start: start, end: end};
            }
            case DATE_RANGE_ENUM.PLUS_MINUS_SEVEN_DAYS: {
                const start = moment().tz(timeZone).startOf(day).subtract(7, shortHandDay).unix();
                const end = moment().tz(timeZone).endOf(day).add(7, shortHandDay).unix();
                return {start: start, end: end};
            }
            default:
                return null;
        }
    }
}