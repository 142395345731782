export const AftxCredentialsApiDomainName = `https://api.${getCurrentDomainName()}/aftxCognitoCredentials`;
export const ServedFromAftxDomain = isAftxDomain();

const apiDomainPrefix = "https://api";
const apiDomainSuffix = "inbound.rodeo.aft.a2z.com";
const aftxDomainSuffix = "aftx.amazonoperations.app";
const regionToRegionIdMap = {
  "eu-west-1": { aft: "dub", aftx: "eu" },
  "us-east-1": { aft: "iad", aftx: "na" },
};
const toolName = "inbound-rodeo";

function isNonProdStage(stage) {
  return "prod" !== stage;
}

export function getAftApiDomainName(region) {
  validateRegion(region);
  const regionId = regionToRegionIdMap[region]["aft"];
  const stage = getStage();
  /**
   * If stage is either beta or gamma the expected FQDNs are
   * beta: api.beta.dub.inbound.rodeo.aft.a2z.com
   * gamma: api.gamma.dub.inbound.rodeo.aft.a2z.com
   */
  if (isNonProdStage(stage)) {
    return `${apiDomainPrefix}.${stage}.${regionId}.${apiDomainSuffix}`;
  }
  /**
   * If stage is prod the expected FQDNs are
   * prodNA: api.iad.inbound.rodeo.aft.a2z.com
   * prodEU: api.dub.inbound.rodeo.aft.a2z.com
   */
  return `${apiDomainPrefix}.${regionId}.${apiDomainSuffix}`;
}

export function getAftxApiDomainName(region) {
  validateRegion(region);
  const regionId = regionToRegionIdMap[region]["aftx"];
  const stage = getStage();
  /**
   * If stage is either beta or gamma the expected FQDNs are
   * beta: api.external.inbound-rodeo.beta.na.integ.aftx.amazonoperations.app
   * gamma: api.external.inbound-rodeo.gamma.eu.integ.aftx.amazonoperations.app
   */
  if (isNonProdStage(stage)) {
    return `${apiDomainPrefix}.external.inbound-rodeo.${stage}.${regionId}.integ.${aftxDomainSuffix}`;
  }
  /**
   * If stage is prod the expected FQDNs are
   * prodNA: api.external.inbound-rodeo.na.aftx.amazonoperations.app
   * prodEU: api.external.inbound-rodeo.eu.aftx.amazonoperations.app
   */
  return `${apiDomainPrefix}.external.inbound-rodeo.${regionId}.${aftxDomainSuffix}`;
}

function getCurrentDomainName() {
  return window.location.hostname;
}

function isAftxDomain() {
  return getCurrentDomainName().endsWith("aftx.amazonoperations.app");
}

function getStage() {
  const prodRegex = new RegExp(`^${toolName}\\.(na|eu)`);
  const gammaRegex = new RegExp(`^${toolName}\\.gamma`);
  const betaRegex = new RegExp(`^((.+?)-)?${toolName}\\.integ\\.na`);

  if (prodRegex.test(getCurrentDomainName())) {
    return "prod";
  } else if (gammaRegex.test(getCurrentDomainName())) {
    return "gamma";
  } else if (betaRegex.test(getCurrentDomainName())) {
    return "beta";
  } else {
    throw new Error("No stage found for this domain name");
  }
}

function validateRegion(region) {
  if (!region || !regionToRegionIdMap[region]) {
    throw new Error(
      "Could not retrieve the configuration for the current region"
    );
  }
}
