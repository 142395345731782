export const BUCKET_SIZES = {
    FIFTEEN_MINUTES: "15_MIN",
    THIRTY_MINUTES: "30_MIN",
    ONE_HOUR: "60_MIN"
};

export const BUCKET_SIZES_IN_SECONDS = new Map([
    [BUCKET_SIZES.FIFTEEN_MINUTES, 900],
    [BUCKET_SIZES.THIRTY_MINUTES, 1800],
    [BUCKET_SIZES.ONE_HOUR, 3600]]
);

