import qs from 'qs';

/**
 * The latest version of the qs library can't parse encoded array comma values correctly. In order to prevent this from
 * causing issues with the AggregateReport API, we are using the 6.7.0 version of the qs library. This is a temporary
 * solution until we can find a way to parse the encoded array comma values correctly in the latest version of the qs
 * library.
 */
export class QueryStringUtilities {

     static parseQueryStrings(urlSearch) {
         return qs.parse(urlSearch, {ignoreQueryPrefix: true, comma: true, allowDots: true})
     }

     static stringifyQueryStrings(parsedQueryStringObject) {
         return qs.stringify(parsedQueryStringObject, {arrayFormat: 'comma'})
     }
 }
