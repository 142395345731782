import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

import {WarehouseIdsContext} from '../provider/WarehouseIdsProvider';
import NoWarehouseSelectedView from '../homepage/NoWarehouseSelectedView';

let RouteValidator = MainComponent => class extends Component {

    render() {
        return (
            <WarehouseIdsContext.Consumer>
                {context => !this.isValidWarehouse(context.warehouseIds) ? <Redirect to={NoWarehouseSelectedView}/> : <MainComponent {...this.props}/>}
            </WarehouseIdsContext.Consumer>);
    }

    isValidWarehouse(warehouseIds) {
        return warehouseIds.includes(this.props.match.params.warehouseId);
    }
};

export default RouteValidator;