import React, {PureComponent, useContext} from 'react';
import {Accordion, AccordionContext, Card, Collapse, useAccordionToggle} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
    AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP,
    AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM,
    AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_TO_TYPE_MAP,
    DEFAULT_AXIS_VALUES_ENUM,
    Dimensions,
    FORECAST_DISABLED_DIMENSIONS,
    FORECAST_ENABLED_DIMENSIONS,
    NON_POST_RECEIVE_STATUS_ENUM,
    PROPERTY_NAMES_ENUM,
    SHIPMENT_TYPES_ENUM,
    VIEW_NAME_TO_STATUS_NAME_MAP,
    VIEW_NAME_TO_X_AXIS_PROPERTY_NAME_MAP
} from '../../pojos/Dimensions';
import {
    POST_RECEIVE_WORKPOOLS_ENUM,
    PRE_RECEIVE_WORKPOOLS_ENUM,
    TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP,
    VIEW_NAME_TO_WORK_POOL_NAME_MAP,
    VIEW_NAME_TO_WORK_POOL_NAME_MAP_WITHOUT_FORECAST
} from '../../pojos/WorkPools';
import {DWELL_TIME_UNIT_DAYS, DWELL_TIME_UNIT_HOURS, DWELL_TIME_UNIT_MINUTES, END_DWELL_TYPE, START_DWELL_TYPE} from '../../pojos/DwellTime';
import AggregateOptionInputGroup from './AggregateOptionInputGroup';
import {AGGREGATE_OPTIONS_ENUM,} from '../../pojos/AggregateOptionParameters';
import {
    BOOLEAN_FILTER_TYPE,
    CONTAINER_ID_FILTER_NAME,
    CONTAINER_SIZE_FILTER_NAME,
    CONTAINER_TYPE_FILTER_NAME,
    DATE_RANGE_FILTER_TYPE,
    FC_SKU_FILTER_NAME,
    FILTER_NAME_TO_TYPE_MAP,
    FLOOR_FILTER_NAME,
    FN_SKU_FILTER_NAME,
    ISA_ID_FILTER_NAME,
    ISD_ID_FILTER_NAME,
    LONG_FILTER_TYPE,
    MOD_FILTER_NAME,
    OUTER_CONTAINER_ID_FILTER_NAME,
    OUTER_CONTAINER_TYPE_FILTER_NAME,
    OUTER_OUTER_SCANNABLE_ID_FILTER_NAME,
    OUTER_SCANNABLE_ID_FILTER_NAME,
    PROCESS_PATH_FILTER_NAME,
    SCANNABLE_ID_FILTER_NAME,
    SOURCE_WAREHOUSE_ID_FILTER_NAME,
    STATUS_FILTER_NAME,
    STRING_FILTER_TYPE,
    STRING_LIST_FILTER_TYPE,
    STRING_LIST_PREFIX_FILTER_TYPE,
    STRING_PREFIX_FILTER_TYPE,
    VR_ID_FILTER_NAME,
    IS_REACTIVE_FILTER_NAME,
    WORK_POOL_FILTER_NAME,
    WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME,
    WorkPoolLastChangedTimeFilter
} from '../../pojos/RodeoFilters';
import AppRouter from '../../router/AppRouter';
import {VIEW_NAMES_ENUM} from "../../pojos/ViewNames";
import {WorkpoolUtilities as WorkPools} from "../../util/WorkpoolUtilities";

const NOT_SELECTED = "Not Selected";

export default class AggregateOptions extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            ...this.setInitialFiltersAndAggregateParamsDefaults(props),
            ...this.createAdditionalFilterVariablesByProps(props)
        };

        this.updateFilterInputChange = this.updateFilterInputChange.bind(this);
        this.handleSelectYDimension = this.handleSelectYDimension.bind(this);
        this.handleSelectZDimension = this.handleSelectZDimension.bind(this);
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.swapAggregateYAndZAxis = this.swapAggregateYAndZAxis.bind(this);
        this.updateFilterOnCheck = this.updateFilterOnCheck.bind(this);
        this.updateAggregateToggleState = this.updateAggregateToggleState.bind(this);
        this.updateDwellTime = this.updateDwellTime.bind(this);
        this.updateAggregateToggleStateWithReactiveFilter = this.updateAggregateToggleStateWithReactiveFilter.bind(this);
        this.isCheckedForShipmentTypeGroup = this.isCheckedForShipmentTypeGroup.bind(this);
    }

    render() {
        let eventKey = 1;
        return (
            <Collapse data-testid="OptionPanel-TestId" className="options-panel-always-on-top" in={this.props.isOpen}>
                <div className="col-md-2">
                    <Accordion defaultActiveKey="0">
                        {this.generateYtoZAxisDimensionSelectionGroup()}
                    </Accordion>
                    <Accordion className="options-panel-accordion scrollable">
                        {this.generateFilterCheckboxGroup(PROPERTY_NAMES_ENUM.STATUS, STATUS_FILTER_NAME, VIEW_NAME_TO_STATUS_NAME_MAP.get(this.props.viewName), eventKey)}
                        {this.generateAggregateRadioGroup(PROPERTY_NAMES_ENUM.SHIPMENT_TYPE,
                            AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_TO_TYPE_MAP,eventKey += 1)}
                        {this.generateAggregateTimeDimensionComponentByViewName(this.props.viewName, eventKey += 1)}
                        {this.generateAggregateRadioGroup(PROPERTY_NAMES_ENUM.BUCKET_SIZE,
                            Dimensions.getValueToNameMapByPropertyName(PROPERTY_NAMES_ENUM.BUCKET_SIZE), eventKey += 1)}
                        {this.generateFilterCheckboxGroup(PROPERTY_NAMES_ENUM.WORK_POOLS, WORK_POOL_FILTER_NAME, this.filterUnloadedFromWorkPoolCheckboxOptions(this.props.aggregateParameters.shipmentType), eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.SCANNABLE_ID, SCANNABLE_ID_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.SOURCE_WAREHOUSE_IDS, SOURCE_WAREHOUSE_ID_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.FC_SKU, FC_SKU_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.FN_SKU, FN_SKU_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.ISA_ID, ISA_ID_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.ISD_ID, ISD_ID_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.PROCESS_PATHS, PROCESS_PATH_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.OUTER_CONTAINER_ID, OUTER_CONTAINER_ID_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.OUTER_CONTAINER_TYPES, OUTER_CONTAINER_TYPE_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.OUTER_SCANNABLE_ID, OUTER_SCANNABLE_ID_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.OUTER_OUTER_SCANNABLE_ID, OUTER_OUTER_SCANNABLE_ID_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.FLOOR, FLOOR_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.MOD, MOD_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.CONTAINER_ID, CONTAINER_ID_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.CONTAINER_SIZES, CONTAINER_SIZE_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.CONTAINER_TYPES, CONTAINER_TYPE_FILTER_NAME, eventKey += 1)}
                        {this.generateFilterInputGroup(PROPERTY_NAMES_ENUM.VR_ID, VR_ID_FILTER_NAME, eventKey += 1)}
                    </Accordion>
                    <div>
                        <button type="button" onClick={this.handleApplyFilters}
                                className="btn btn-secondary btn-block btn-sm">Load Report
                        </button>
                    </div>
                </div>
            </Collapse>
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.aggregateParameters.shipmentType !== prevState.aggregateParameters.shipmentType) {
            const newState = {};
            newState[WORK_POOL_FILTER_NAME] = [...this.filterUnloadedFromWorkPoolCheckboxOptions(this.state.aggregateParameters.shipmentType).keys()];
            this.setState(prevState => {
                return {rodeoFilters: {...prevState.rodeoFilters, ...newState}};
            });
        }
    }

    setInitialFiltersAndAggregateParamsDefaults(props) {
        const rodeoFilters = {};
        const aggregateParameters = {};
        _.toPairs(props.rodeoFilters).forEach(([filterName, filter]) => {
            rodeoFilters[filterName] = AggregateOptions.getFilterValue(filter, filterName);
        });
        _.toPairs(props.aggregateParameters).forEach(([aggregateKey, aggregateValue]) => {
            aggregateParameters[aggregateKey] = _.cloneDeep(aggregateValue);
        });
        return {rodeoFilters, aggregateParameters};
    }

    handleApplyFilters() {
        this.props.toggleOptionsPanel();
        this.props.addQueryParamsToURL(this.state.aggregateParameters, this.mixAdditionalFilterVariablesToRodeoFilters());
    }

    updateFilterOnCheck(event) {
        const filterValue = event.target.value;
        const filterName = event.target.dataset.filterName;
        const wasChecked = event.target.checked;

        this.setState(prevState => {
            const rodeoFilterValue = prevState.rodeoFilters[filterName];
            const filterIsAParentWorkPool = WorkPools.isAParentWorkPool(filterValue);
            const filterIsAChildWorkPool = WorkPools.isASubWorkPool(filterValue);

            const newState = {};
            let newFilterValue;
            if (!wasChecked) {
                newFilterValue = _.filter(rodeoFilterValue, filter => filter !== filterValue);
                if (filterIsAParentWorkPool) {
                    const childWorkPools = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[filterValue];
                    newFilterValue = _.difference(newFilterValue, childWorkPools);
                } else if (filterIsAChildWorkPool) {
                    const parentWorkPool = WorkPools.findParentWorkPool(filterValue);
                    const childWorkPools = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[parentWorkPool];
                    const allChildWorkPoolsHaveBeenDeselected = _.difference(childWorkPools, newFilterValue).length === childWorkPools.length;

                    if (allChildWorkPoolsHaveBeenDeselected) {
                        newFilterValue = _.filter(newFilterValue, filter => filter !== parentWorkPool);
                    }
                }
            } else {
                if (_.isNil(rodeoFilterValue)) {
                    newFilterValue = [];
                } else {
                    newFilterValue = [...rodeoFilterValue, filterValue];
                    if (filterIsAParentWorkPool) {
                        const childWorkPools = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[filterValue];
                        newFilterValue = _.uniq([...newFilterValue, ...childWorkPools]);
                    } else if (filterIsAChildWorkPool) {
                        const parentWorkPool = WorkPools.findParentWorkPool(filterValue);
                        const childWorkPools = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[parentWorkPool];
                        const allChildWorkPoolsHaveBeenSelected = _.difference(childWorkPools, newFilterValue).length === 0;

                        if (allChildWorkPoolsHaveBeenSelected) {
                            newFilterValue = _.uniq([...newFilterValue, parentWorkPool]);
                        }
                    }
                }
            }
            newState[filterName] = newFilterValue;
            return {rodeoFilters: {...prevState.rodeoFilters, ...newState}}
        });
    }

    updateAggregateToggleState(event) {
        const newAggregateParameter = {};
        newAggregateParameter[event.target.dataset.aggregatePropertyName] = event.target.value;
        this.setState(prevState => {
            return {aggregateParameters: {...prevState.aggregateParameters, ...newAggregateParameter}}
        });
    }

    updateAggregateToggleStateWithReactiveFilter(event) {
        const newFilterState = {};
        const newAggregateParameter = {};
        console.log('This method gets called value is'+ event.target.value);
        if (event.target.value === AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM.REACTIVE_TRANSSHIPMENT_ONLY) {
            newFilterState[STATUS_FILTER_NAME] = [NON_POST_RECEIVE_STATUS_ENUM.ACTUALS];
            newFilterState[IS_REACTIVE_FILTER_NAME] = true;
        } else {
            newFilterState[IS_REACTIVE_FILTER_NAME] = "";
        }
        newAggregateParameter[event.target.dataset.aggregatePropertyName] = AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_TO_TYPE_MAP.get(event.target.value);
        this.setState(prevState => {
            return {
                aggregateParameters: {...prevState.aggregateParameters, ...newAggregateParameter},
                rodeoFilters: {...prevState.rodeoFilters, ...newFilterState}
            }
        });
    }

    swapAggregateYAndZAxis() {
        this.setState(prevState => {
            const newYAxis = prevState.aggregateParameters.zAxis;
            const newZAxis = prevState.aggregateParameters.yAxis;
            if (_.isNil(newYAxis) || _.isNil(newZAxis)) {
                return prevState;
            }
            const newAggregateParameters = {
                yAxis: newYAxis,
                zAxis: newZAxis
            };
            return {aggregateParameters: {...prevState.aggregateParameters, ...newAggregateParameters}}
        });
    }

    updateFilterInputChange(filterName, value) {
        const newState = {};
        const filterType = FILTER_NAME_TO_TYPE_MAP.get(filterName);
        if ((filterType === STRING_LIST_FILTER_TYPE || filterType === STRING_LIST_PREFIX_FILTER_TYPE) && !_.isEmpty(_.last(value))) {
            value.push('');
        }
        newState[filterName] = value;

        this.setState(prevState => {
            return {rodeoFilters: {...prevState.rodeoFilters, ...newState}}
        });
    }

    handleSelectYDimension(event) {
        const newYAxis = {yAxis: event.target.value};
        this.setState(prevState => {
            return {aggregateParameters: {...prevState.aggregateParameters, ...newYAxis}}
        });
    }

    handleSelectZDimension(event) {
        const newZAxis = {zAxis: event.target.value === NOT_SELECTED ? undefined : event.target.value};
        this.setState(prevState => {
            return {aggregateParameters: {...prevState.aggregateParameters, ...newZAxis}}
        });
    }

    filterUnloadedFromWorkPoolCheckboxOptions(shipmentType) {
        const workPoolLabelToNameMap = (!AppRouter.isPostReceiveAggregateView(this.props.viewName) && _.includes(FORECAST_ENABLED_DIMENSIONS, this.state.aggregateParameters.yAxis) && _.includes(FORECAST_ENABLED_DIMENSIONS, this.state.aggregateParameters.zAxis)) ?
            VIEW_NAME_TO_WORK_POOL_NAME_MAP.get(this.props.viewName) : VIEW_NAME_TO_WORK_POOL_NAME_MAP_WITHOUT_FORECAST.get(this.props.viewName);
        const isDwellTime = AppRouter.isDwellTimeAggregateView(this.props.viewName);
        const isPreReceive = AppRouter.isPreReceiveAggregateView(this.props.viewName);
        const unloadedWorkPool = isPreReceive ? PRE_RECEIVE_WORKPOOLS_ENUM.UNLOADED : POST_RECEIVE_WORKPOOLS_ENUM.UNLOADED;
        const filteredWorkPools = new Map([...workPoolLabelToNameMap.entries()].filter(([workPoolValue, workPoolName]) => workPoolName !== unloadedWorkPool));

        if (isDwellTime) {
            return workPoolLabelToNameMap;
        } else {
            switch (shipmentType) {
                case SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE:
                    return !isPreReceive ? filteredWorkPools : workPoolLabelToNameMap;
                case SHIPMENT_TYPES_ENUM.TRANSSHIPMENT:
                    if (this.props.isEURealm) {
                        return isPreReceive ? workPoolLabelToNameMap : filteredWorkPools;
                    }
                    return isPreReceive ? filteredWorkPools : workPoolLabelToNameMap;
                default:
                    return workPoolLabelToNameMap;
            }
        }
    }

    generateFilterInputGroup(propertyName, filterName, eventKey) {
        const filterValue = this.state.rodeoFilters[filterName];
        return (
            <Card bg="light">
                <ContextAwareToggle eventKey={eventKey}>
                    {propertyName}
                </ContextAwareToggle>
                <Accordion.Collapse eventKey={eventKey}>
                    <Card.Body>
                        <div className="form-group">
                            <AggregateOptionInputGroup filterName={filterName}
                                                       filterValue={filterValue}
                                                       updateInputChange={this.updateFilterInputChange}/>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        );
    }

    generateYtoZAxisDimensionSelectionGroup() {
        const xDimensionSelectProperty = VIEW_NAME_TO_X_AXIS_PROPERTY_NAME_MAP.get(this.props.viewName);
        const dimensionValueToDimensionNameMap = AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP;
        const yDimensionSelectId = "dimensions-y-select";
        const yDimensionSelectProperty = AGGREGATE_OPTIONS_ENUM.Y_AXIS;
        const zDimensionSelectId = "dimensions-z-select";
        const zDimensionSelectProperty = AGGREGATE_OPTIONS_ENUM.Z_AXIS;
        const yDimensionOptions = _.toPairs(dimensionValueToDimensionNameMap).map(([dimensionValue, dimensionName]) => {
            return this.state.aggregateParameters.zAxis === dimensionValue ? null : (
                <option key={dimensionName} value={dimensionValue}>{dimensionName}</option>);
        });
        const zDimensionOptions = _.toPairs(dimensionValueToDimensionNameMap).map(([dimensionValue, dimensionName]) => {
            return this.state.aggregateParameters.yAxis === dimensionValue ? null : (
                <option key={dimensionName} value={dimensionValue}>{dimensionName}</option>);
        });

        return (
            <Card bg="light">
                <Accordion.Toggle as={Card.Header} eventKey="0" className="clickable">
                    Dimensions
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="form-group">
                            <div className="row">
                                <div className="col">
                                    <input id="xDimensionSelect"
                                           type="text"
                                           value={xDimensionSelectProperty}
                                           className="form-control"
                                           disabled={true}>
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row align-items-center">
                                <div className="col pr-0">
                                    <div className="row">
                                        <div className="col">
                                            <select id={yDimensionSelectId}
                                                    name={yDimensionSelectProperty}
                                                    value={this.state.aggregateParameters.yAxis}
                                                    onChange={this.handleSelectYDimension}
                                                    className="form-control">
                                                {yDimensionOptions}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <select id={zDimensionSelectId}
                                                    name={zDimensionSelectProperty}
                                                    value={this.state.aggregateParameters.zAxis}
                                                    onChange={this.handleSelectZDimension}
                                                    className="form-control">
                                                <option key={NOT_SELECTED}
                                                        value={DEFAULT_AXIS_VALUES_ENUM.Z_DEFAULT}>{NOT_SELECTED}</option>
                                                {zDimensionOptions}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 pl-0">
                                    <button className="fa fa-exchange fa-rotate-90 btn btn-secondary btn-sm"
                                            type="button"
                                            disabled={_.isNil(this.state.aggregateParameters.zAxis)}
                                            onClick={this.swapAggregateYAndZAxis}/>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        );
    }

    generateFilterCheckboxGroup(propertyName, filterName, checkOptionValueToNameMap, eventKey) {
        if (!AppRouter.isPostReceiveAggregateView(this.props.viewName) || filterName !== STATUS_FILTER_NAME) {
            const checkOptionValueToNameMapClone = _.cloneDeep(checkOptionValueToNameMap);
            if (filterName === STATUS_FILTER_NAME && (_.includes(FORECAST_DISABLED_DIMENSIONS, this.state.aggregateParameters.yAxis) || _.includes(FORECAST_DISABLED_DIMENSIONS, this.state.aggregateParameters.zAxis))) {
                checkOptionValueToNameMapClone.delete(NON_POST_RECEIVE_STATUS_ENUM.FORECASTED);
                checkOptionValueToNameMap = checkOptionValueToNameMapClone;
            }
            const checkboxItems = _.toPairs(checkOptionValueToNameMap).map(([valueName, labelName]) => {
                const checkboxId = `${valueName}-checkbox`;
                const isChildWorkPoolOption = filterName === WORK_POOL_FILTER_NAME &&
                    (labelName.endsWith(NON_POST_RECEIVE_STATUS_ENUM.FORECASTED) || labelName.endsWith(NON_POST_RECEIVE_STATUS_ENUM.ACTUALS));

                return (
                    <div key={checkboxId}
                         className={`form-check ${isChildWorkPoolOption ? 'filter-subworkpool-div' : ''}`}>
                        <input type="checkbox"
                               className="form-check-input"
                               id={checkboxId}
                               value={valueName}
                               data-filter-name={filterName}
                               onChange={this.updateFilterOnCheck}
                               disabled={checkboxId === "Forecasted-checkbox"? this.state.rodeoFilters[IS_REACTIVE_FILTER_NAME]:false}
                               checked={_.includes(this.state.rodeoFilters[filterName], valueName)}/>
                        <label className="form-check-label" htmlFor={checkboxId}>{labelName}</label>
                    </div>
                );
            });
            return (
                <Card bg="light">
                    <ContextAwareToggle eventKey={eventKey}>
                        {propertyName}
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey={eventKey}>
                        <Card.Body>
                            {checkboxItems}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            );
        }
    }

    updateDwellTime(event) {
        const unitValue = event.target.value;
        const timeUnit = event.target.dataset.dwellTimeUnit;
        const dwellType = event.target.dataset.dwellType;
        const changeValueObject = {};
        changeValueObject[timeUnit] = unitValue;
        this.setState((prevState) => {
            if (dwellType === START_DWELL_TYPE) {
                return {dwellTimeStartInUnits: {...prevState.dwellTimeStartInUnits, ...changeValueObject}};
            } else {
                return {dwellTimeEndInUnits: {...prevState.dwellTimeEndInUnits, ...changeValueObject}};
            }
        });
    }

    generateDwellTimeInputGroup(propertyName, filterName, eventKey) {
        const dwellTimeInputGroupBody = (
            <div className="form-group">
                From
                <div className="row no-gutters">
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={START_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_DAYS}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_DAYS}/>
                    </div>
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={START_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_HOURS}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_HOURS}/>
                    </div>
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={START_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_MINUTES}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_MINUTES}/>
                    </div>
                </div>
                To
                <div className="row no-gutters">
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={END_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_DAYS}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_DAYS}/>
                    </div>
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={END_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_HOURS}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_HOURS}/>
                    </div>
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={END_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_MINUTES}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_MINUTES}/>
                    </div>
                </div>
            </div>
        );
        return (
            <Card bg="light">
                <ContextAwareToggle eventKey={eventKey}>
                    {propertyName}
                </ContextAwareToggle>
                <Accordion.Collapse eventKey={eventKey}>
                    <Card.Body>
                        {dwellTimeInputGroupBody}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        );

    }

    generateAggregateRadioGroup(propertyName, radioOptionValueToNameMap,eventKey) {
        const property = _.camelCase(propertyName);
        const isShipmentTypeGroup = propertyName === PROPERTY_NAMES_ENUM.SHIPMENT_TYPE;
        const radioItems = _.toPairs(radioOptionValueToNameMap).map(([valueName, labelName]) => {
            const radioButtonId = `${valueName}-${property}-radio`;
            return (
                <div key={isShipmentTypeGroup? valueName : labelName} className="form-check">
                    <input type="radio"
                           className="form-check-input"
                           id={radioButtonId}
                           name={`${property}-${this.props.viewName}`}
                           value={valueName}
                           data-aggregate-property-name={property}
                           onChange={isShipmentTypeGroup ? this.updateAggregateToggleStateWithReactiveFilter : this.updateAggregateToggleState}
                           defaultChecked={isShipmentTypeGroup ? this.isCheckedForShipmentTypeGroup(valueName, labelName, this.state.aggregateParameters[property]) : valueName === this.state.aggregateParameters[property]}/>
                    <label className="form-check-label clickable"
                           htmlFor={radioButtonId}>{isShipmentTypeGroup ? valueName : labelName}</label>
                </div>
            );
        });

        return (
            <Card bg="light">
                <ContextAwareToggle eventKey={eventKey}>
                    {propertyName}
                </ContextAwareToggle>
                <Accordion.Collapse eventKey={eventKey}>
                    <Card.Body>
                        {radioItems}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        );

    }

    isCheckedForShipmentTypeGroup(valueName, labelName, shipmentType) {
        const reactiveFilterValue = this.state.rodeoFilters[IS_REACTIVE_FILTER_NAME];
        const isTransshipment = shipmentType === SHIPMENT_TYPES_ENUM.TRANSSHIPMENT;
        switch (valueName) {
            case AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM.REACTIVE_TRANSSHIPMENT_ONLY:
                return reactiveFilterValue === "true" && isTransshipment;
            case AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM.TRANSSHIPMENT:
                return reactiveFilterValue === "" && isTransshipment;
            default:
                return labelName === shipmentType;
        }
    }

    generateAggregateTimeDimensionComponentByViewName(viewName, eventKey) {
        if (viewName === VIEW_NAMES_ENUM.DWELL_TIME) {
            return this.generateDwellTimeInputGroup('Dwell Time', WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME, eventKey);
        } else {
            return this.generateAggregateRadioGroup(
                Dimensions.getRangeNameByViewName(this.props.viewName),
                Dimensions.getRangeValuesToNamesMapByViewName(this.props.viewName), eventKey
            );
        }
    }

    createAdditionalFilterVariablesByProps(props) {
        if (props.viewName === VIEW_NAMES_ENUM.DWELL_TIME) {
            return {
                dwellTimeStartInUnits: AggregateOptions.getDwellTimeInUnits(0),
                dwellTimeEndInUnits: AggregateOptions.getDwellTimeInUnits(0)
            }
        }
    }

    mixAdditionalFilterVariablesToRodeoFilters() {
        const newRodeoFilters = {};
        if (this.props.viewName === VIEW_NAMES_ENUM.DWELL_TIME) {
            const workPoolLastChangeFilter = new WorkPoolLastChangedTimeFilter();
            let dwellTimeStartInMillis = AggregateOptions.getDwellTimeInMillis(this.state.dwellTimeStartInUnits);
            let dwellTimeEndInMillis = AggregateOptions.getDwellTimeInMillis(this.state.dwellTimeEndInUnits);
            if (dwellTimeStartInMillis !== 0 || dwellTimeEndInMillis !== 0) {
                workPoolLastChangeFilter.withFrom(dwellTimeStartInMillis);
                workPoolLastChangeFilter.withTo(dwellTimeEndInMillis);
                newRodeoFilters[WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME] = workPoolLastChangeFilter;
            }
        }
        return {...this.state.rodeoFilters, ...newRodeoFilters}
    }

    static getDwellTimeInMillis(dwellTimeObject) {
        const ONE_MINUTE_IN_MILLIS = 60 * 1000;
        const DWELL_TIME_DAYS_IN_MILLIS = dwellTimeObject[DWELL_TIME_UNIT_DAYS] * 24 * 60 * ONE_MINUTE_IN_MILLIS;
        const DWELL_TIME_HOURS_IN_MILLIS = dwellTimeObject[DWELL_TIME_UNIT_HOURS] * 60 * ONE_MINUTE_IN_MILLIS;
        const DWELL_TIME_MINUTES_IN_MILLIS = dwellTimeObject[DWELL_TIME_UNIT_MINUTES] * ONE_MINUTE_IN_MILLIS;
        return DWELL_TIME_DAYS_IN_MILLIS + DWELL_TIME_HOURS_IN_MILLIS + DWELL_TIME_MINUTES_IN_MILLIS;
    }

    static getDwellTimeInUnits(dwellTimeInMillis) {
        const dwellTimeObject = moment.duration(dwellTimeInMillis);
        const dwellTimeInUnits = {};
        dwellTimeInUnits[DWELL_TIME_UNIT_DAYS] = dwellTimeObject.days();
        dwellTimeInUnits[DWELL_TIME_UNIT_HOURS] = dwellTimeObject.hours();
        dwellTimeInUnits[DWELL_TIME_UNIT_MINUTES] = dwellTimeObject.minutes();
        return dwellTimeInUnits;
    }

    static getFilterValue(filter, filterName) {
        const filterIsNil = _.isNil(filter);
        const filterType = FILTER_NAME_TO_TYPE_MAP.get(filterName);

        if (filterType === STRING_PREFIX_FILTER_TYPE) {
            return filterIsNil ? '' : filter.prefix;
        }
        if (filterType === STRING_FILTER_TYPE || filterType === LONG_FILTER_TYPE || filterType === BOOLEAN_FILTER_TYPE) {
            return filterIsNil ? '' : filter.value;
        }
        if (filterType === STRING_LIST_FILTER_TYPE) {
            return filterIsNil ? [''] : filter.values;
        }
        if (filterType === STRING_LIST_PREFIX_FILTER_TYPE) {
            return filterIsNil ? [''] : filter.values;
        }
        if (filterType === DATE_RANGE_FILTER_TYPE) {
            return filterIsNil ? {} : {from: filter.from, to: filter.to}
        }
    }
}

export function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <Card.Header
            className="clickable"
            onClick={decoratedOnClick}>
            <i className={`p-1 fa ${isCurrentEventKey ? 'fa-chevron-down' : 'fa-chevron-right'}`}/>{children}
        </Card.Header>
    );
}
