import moment from 'moment-timezone';
import _ from 'lodash';

import {DateUtilities} from '../../util/DateUtilities';
import {WORKPOOL_PROPERTY_NAME} from '../../pojos/PropertyNames';

export default class ItemListRecord {

    constructor(builder) {
        this.appointmentCheckInTime = builder.appointmentCheckInTime;
        this.asnId = builder.asnId;
        this.containerId = builder.containerId;
        this.containerSize = builder.containerSize;
        this.containerType = builder.containerType;
        this.dwellTime = builder.dwellTime;
        this.dwellTimeFormatted = builder.dwellTimeFormatted;
        this.expectedStowDate = builder.expectedStowDate;
        this.fcSku = builder.fcSku;
        this.fnSku = builder.fnSku;
        this.id = builder.id;
        this.isaId = builder.isaId;
        this.isdId = builder.isdId;
        this.isReactiveTransfer = builder.isReactiveTransfer;
        this.manifestId = builder.manifestId;
        this.outerContainerId = builder.outerContainerId;
        this.outerContainerType = builder.outerContainerType;
        this.outerScannableId = builder.outerScannableId;
        this.outerOuterContainerId = builder.outerOuterContainerId;
        this.outerOuterContainerType = builder.outerOuterContainerType;
        this.outerOuterScannableId = builder.outerOuterScannableId;
        this.floor = builder.floor;
        this.mod = builder.mod;
        this.processPath = builder.processPath;
        this.quantity = builder.quantity;
        this.scannableId = builder.scannableId;
        this.sourceWarehouseId = builder.sourceWarehouseId;
        this.trailerDockingTime = builder.trailerDockingTime;
        this.trailerId = builder.trailerId;
        this.vrId = builder.vrId;
        this.warehouseId = builder.warehouseId;
        this.workPool = builder.workPool;
        this.workPoolLastChangedTime = builder.workPoolLastChangedTime;
    }

    static get Builder() {
        class Builder {

            constructor(id) {
                this.id = id;
            }

            withASNId(asnId) {
                this.asnId = asnId;
                return this;
            }

            withContainerId(containerId) {
                this.containerId = containerId;
                return this;
            }

            withContainerSize(containerSize) {
                this.containerSize = containerSize;
                return this;
            }

            withContainerType(containerType) {
                this.containerType = containerType;
                return this;
            }

            withExpectedStowDate(expectedStowDate, timezone) {
                if(!_.isNil(expectedStowDate)) {
                    expectedStowDate = DateUtilities.formatDate(expectedStowDate, timezone);
                }
                this.expectedStowDate = expectedStowDate;
                return this;
            }

            withFcSku(fcSku) {
                this.fcSku = fcSku;
                return this;
            }

            withFnSku(fnSku) {
                this.fnSku = fnSku;
                return this;
            }

            withIsaId(isaId) {
                this.isaId = isaId;
                return this;
            }

            withIsdId(isdId) {
                this.isdId = isdId;
                return this;
            }

            withIsReactiveTransfer(isReactiveTransfer) {
                this.isReactiveTransfer = isReactiveTransfer ? "R" : "NR";
                return this;
            }

            withManifestId(manifestId) {
                this.manifestId = manifestId;
                return this;
            }

            withOuterContainerId(outerContainerId) {
                this.outerContainerId = outerContainerId;
                return this;
            }

            withOuterContainerType(outerContainerType) {
                this.outerContainerType = outerContainerType;
                return this;
            }

            withOuterScannableId(outerScannableId) {
                this.outerScannableId = outerScannableId;
                return this;
            }

            withOuterOuterContainerId(outerOuterContainerId) {
                this.outerOuterContainerId = outerOuterContainerId;
                return this;
            }

            withOuterOuterContainerType(outerOuterContainerType) {
                this.outerOuterContainerType = outerOuterContainerType;
                return this;
            }

            withOuterOuterScannableId(outerOuterScannableId) {
                this.outerOuterScannableId = outerOuterScannableId;
                return this;
            }

            withFloor(floor) {
                this.floor = floor;
                return this;
            }

            withMod(mod) {
                this.mod = mod;
                return this;
            }

            withProcessPath(processPath) {
                this.processPath = processPath;
                return this;
            }

            withQuantity(quantity) {
                this.quantity = quantity;
                return this;
            }

            withScannableId(scannableId) {
                this.scannableId = scannableId;
                return this;
            }

            withSourceWarehouseId(sourceWarehouseId) {
                this.sourceWarehouseId = sourceWarehouseId;
                return this;
            }

            withTrailerDockingTime(trailerDockingTime, timezone) {
                if(!_.isNil(trailerDockingTime)) {
                    trailerDockingTime = DateUtilities.formatDate(trailerDockingTime, timezone);
                }
                this.trailerDockingTime = trailerDockingTime;
                return this;
            }

            withTrailerId(trailerId) {
                this.trailerId = trailerId;
                return this;
            }

            withVrId(vrId) {
                this.vrId = vrId;
                return this;
            }

            withWarehouseId(warehouseId) {
                this.warehouseId = warehouseId;
                return this;
            }

            withWorkPool(workPool) {
                this.workPool = workPool;
                return this;
            }

            withWorkPoolLastChangedTime(workPoolLastChangedTime) {
                this.workPoolLastChangedTime = workPoolLastChangedTime;
                this.dwellTime = moment.now() - moment.unix(this.workPoolLastChangedTime);
                this.dwellTimeFormatted = DateUtilities.formatItemListViewDwellTime(this.dwellTime);
                return this;
            }

            withAppointmentCheckInTime(appointmentCheckInTime, timezone) {
                if(!_.isNil(appointmentCheckInTime)) {
                    appointmentCheckInTime = DateUtilities.formatDate(appointmentCheckInTime, timezone);
                }
                this.appointmentCheckInTime = appointmentCheckInTime;
                return this;
            }

            build() {
                return new ItemListRecord(this);
            }
        }

        return Builder;
    }

    static createItemListRecordFromJSON(itemListRecordJSON, warehouseTimezone) {
        const sourceWarehouseId = !_.isEmpty(itemListRecordJSON.sourceWarehouseId) ? itemListRecordJSON.sourceWarehouseId : itemListRecordJSON.sourceWarehouseIdOrSCACCode;
        return new ItemListRecord.Builder(itemListRecordJSON.id)
            .withAppointmentCheckInTime(itemListRecordJSON.appointmentCheckInTime, warehouseTimezone)
            .withASNId(itemListRecordJSON.asnId)
            .withContainerId(itemListRecordJSON.containerId)
            .withContainerSize(itemListRecordJSON.containerSize)
            .withContainerType(itemListRecordJSON.containerType)
            .withExpectedStowDate(itemListRecordJSON.expectedStowDate, warehouseTimezone)
            .withFcSku(itemListRecordJSON.fcSku)
            .withFnSku(itemListRecordJSON.fnSku)
            .withIsaId(itemListRecordJSON.isaId)
            .withIsdId(itemListRecordJSON.isdId)
            .withIsReactiveTransfer(itemListRecordJSON.isReactiveTransfer)
            .withManifestId(itemListRecordJSON.manifestId)
            .withQuantity(itemListRecordJSON.quantity)
            .withOuterContainerId(itemListRecordJSON.outerContainerId)
            .withOuterContainerType(itemListRecordJSON.outerContainerType)
            .withOuterScannableId(itemListRecordJSON.outerScannableId)
            .withOuterOuterContainerId(itemListRecordJSON.outerOuterContainerId)
            .withOuterOuterContainerType(itemListRecordJSON.outerOuterContainerType)
            .withOuterOuterScannableId(itemListRecordJSON.outerOuterScannableId)
            .withFloor(itemListRecordJSON.floor)
            .withMod(itemListRecordJSON.mod)
            .withProcessPath(itemListRecordJSON.processPath)
            .withScannableId(itemListRecordJSON.scannableId)
            .withSourceWarehouseId(sourceWarehouseId)
            .withTrailerDockingTime(itemListRecordJSON.trailerDockingTime, warehouseTimezone)
            .withTrailerId(itemListRecordJSON.trailerId)
            .withVrId(itemListRecordJSON.vrId)
            .withWarehouseId(itemListRecordJSON.warehouseId)
            .withWorkPool(itemListRecordJSON.workPool)
            .withWorkPoolLastChangedTime(itemListRecordJSON.workPoolLastChangedTime)
            .build();
    }
}

export const DEFAULT_PROPERTY_NAME_TO_COLUMN_NAME_MAP = new Map([
    ["scannableId", "Scannable Id"],
    ["containerSize", "Container Size"],
    ["containerType", "Container Type"],
    ["fnSku", "FN SKU"],
    ["fcSku", "FC SKU"],
    ["quantity", "Quantity"],
    ["expectedStowDate", "Expected Stow Date"],
    ["outerScannableId", "Outer Scannable Id"],
    ["outerContainerType", "Outer Container Type"],
    ["isaId", "Isa Id"],
    ["vrId", "VRID"],
    ["trailerDockingTime", "Trailer Docking Time"]
]);

export const TRANSSHIPMENT_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP = new Map([
    ["isReactiveTransfer", "Reactive(R/NR)"],
    ["manifestId", "Manifest Id"],
    ["sourceWarehouseId", "Source WID"],
]);

export const VENDOR_RECEIVE_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP = new Map([
    ["asnId", "Asn Id"]
]);

export const DWELL_TIME_NAME_TO_COLUMN_MAP = new Map([
    ["dwellTimeFormatted", "Dwell Time"]
]);

export const WORKPOOL_ONLY_PROPERTY_NAME_TO_TO_COLUMN_MAP = new Map([
    [WORKPOOL_PROPERTY_NAME, "Work Pool"]
]);

export const OPTIONS_PROPERTY_NAME_TO_COLUMN_NAME_MAP = new Map([
    ["id", "Id"],
    ["isdId", "Isd Id"],
    ["containerId", "Container Id"],
    ["outerContainerId", "Outer Container Id"],
    ["floor", "Floor"],
    ["mod", "Mod"],
    ["trailerId", "Trailer Id"],
    ["processPath", "Process Path"],
    ["outerOuterScannableId", "Outer Outer Scannable Id"],
    ["outerOuterContainerType", "Outer Outer Container Type"],
]);

export const POST_RECEIVE_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP = new Map([
    ["appointmentCheckInTime", "Appointment Check In Time"]
]);
