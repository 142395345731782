import React, {Component} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import _ from 'lodash';

import AggregateView from '../aggregate/AggregateView';
import ItemListView from '../itemlist/ItemListView';
import Header from '../header/Header';
import NoWarehouseSelectedView from '../homepage/NoWarehouseSelectedView';
import Footer from '../footer/Footer';
import RouteValidator from './RouteValidator';
import NotFound404 from '../error/NotFound404';
import WarehouseIdsProvider from '../provider/WarehouseIdsProvider';
import APIGatewayClientProvider from '../provider/APIGatewayClientProvider';
import IsItDown from '../isItDown/IsItDown';
import {VIEW_NAMES_ENUM} from '../pojos/ViewNames';

export const PRE_RECEIVE_URL = 'pre';
export const POST_RECEIVE_URL = 'post';
export const DWELL_TIME_URL = 'dwell';
export const SEARCH = 'search';
export const WAREHOUSE_ID_KEY = ':warehouseId';

export const HOME_URL = '/';
export const WAREHOUSE_ID_URL = `/${WAREHOUSE_ID_KEY}`;
export const SEARCH_URL = `${WAREHOUSE_ID_URL}/${SEARCH}`;
export const AGGREGATE_VIEW_URL = `${WAREHOUSE_ID_URL}/:receive(${PRE_RECEIVE_URL}|${POST_RECEIVE_URL}|${DWELL_TIME_URL})`;
export const DEFAULT_AGGREGATE_VIEW = `${WAREHOUSE_ID_URL}/${PRE_RECEIVE_URL}`;
export const ITEM_LIST_URL = `${WAREHOUSE_ID_URL}/itemList`;

/**
 * Class to handle the Routing of the application via ReactRouter
 */
export default class AppRouter extends Component {

    componentDidMount() {
        IsItDown.load();
    }

    render() {
        return (
            <Router>
                <APIGatewayClientProvider>
                    <WarehouseIdsProvider>
                        <Header/>
                        <div className="body-container flex-shrink-0">
                            <Switch>
                                <Route exact path={HOME_URL} component={NoWarehouseSelectedView}/>
                                <Route exact path={SEARCH_URL} component={RouteValidator(ItemListView)}/>
                                <Route exact path={AGGREGATE_VIEW_URL} component={RouteValidator(AggregateView)}/>
                                <Route exact path={ITEM_LIST_URL} component={RouteValidator(ItemListView)}/>
                                <Redirect exact from={WAREHOUSE_ID_URL} to={DEFAULT_AGGREGATE_VIEW}/>
                                <Route path="*" component={NotFound404}/>
                            </Switch>
                        </div>
                        <Footer/>
                    </WarehouseIdsProvider>
                </APIGatewayClientProvider>
            </Router>
        );
    }

    static isSearchView(currentView) {
        return _.includes(_.toLower(currentView), _.toLower(SEARCH));
    }

    static isPreReceiveAggregateView(currentView) {
        return currentView === VIEW_NAMES_ENUM.PRE_RECEIVE;
    }

    static isPostReceiveAggregateView(currentView) {
        return currentView === VIEW_NAMES_ENUM.POST_RECEIVE;
    }

    static isDwellTimeAggregateView(currentView) {
        return currentView === VIEW_NAMES_ENUM.DWELL_TIME;
    }

    static generateNewUrl(warehouseId, view) {
        const urlWarehouseId = !_.isNil(warehouseId) ? `/${warehouseId}` : '';
        const urlView = !_.isNil(view) ? `/${view}` : '';

        return `${urlWarehouseId}${urlView}`;
    }
}