import React, {Component} from 'react';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import moment from "moment-timezone";

import AggregateReportRequestInput from '../../pojos/AggregateReportRequestInput';
import {
    LOADING_DATA,
    NO_DATA_FOUND
} from './AggregateViewMainTable';
import AggregateOptions from '../options/AggregateOptions';
import ReceiveActionBar from './ReceiveActionBar';
import AggregationAction from '../../pojos/AggregationAction';
import {
    ACTUAL_TO_FORECASTED_SHIPMENT_TYPE_MAP,
    AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP,
    DEFAULT_AXIS_VALUES_ENUM,
    FORECASTED_SHIPMENT_TYPES_ENUM,
    NON_POST_RECEIVE_STATUS_ENUM,
    SHIPMENT_TYPES_ENUM,
    VIEW_NAME_TO_STATUS_NAME_MAP,
    VIEW_NAME_TO_X_AXIS_DIMENSION_MAP,
    VIEW_NAME_TO_X_AXIS_PROPERTY_NAME_MAP,
    AGGREGATE_DIMENSION_VALUE_ENUM,
    FORECAST_DISABLED_DIMENSIONS, DATE_RANGE_ENUM
} from '../../pojos/Dimensions';
import APIGatewayClientProvider, {APIGatewayClientContext} from '../../provider/APIGatewayClientProvider';
import NotificationContainer from '../../notification/NotificationContainer';
import Notification from '../../notification/Notification';
import {ExpandRowButton} from '../ExpandRowButton'
import {
    AGGREGATE_OPTIONS_ENUM,
    AggregateOptionParameters,
    DEFAULT_AGGREGATE_OPTION_VALUE_MAP,
} from '../../pojos/AggregateOptionParameters';
import RodeoFilters, {
    ContainerIdFilter,
    ContainerSizeFilter,
    ContainerTypeFilter,
    FcSkuFilter,
    FnSkuFilter,
    ISAIdFilter,
    ISDIdFilter,
    OuterContainerIdFilter,
    OuterContainerTypeFilter,
    OuterScannableIdFilter,
    OuterOuterScannableIdFilter,
    FloorFilter,
    ModFilter,
    ProcessPathFilter,
    RODEO_FILTER_NAME_TO_QUERY_STRING_KEY_MAP,
    ScannableIdFilter,
    SourceWarehouseIdFilter,
    StatusFilter,
    VRIdFilter,
    IsReactiveFilter,
    WorkPoolFilter,
    WorkPoolLastChangedTimeFilter,
    WORK_POOL_FILTER_NAME,
    WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME,
    VALUES_FIELD, TrailerDockingTimeFilter
} from '../../pojos/RodeoFilters';
import * as RodeoFilterQueryStrings from '../../pojos/RodeoFilterQueryStrings';
import {DateUtilities, DEFAULT_TIME_ZONE} from '../../util/DateUtilities';
import {QueryStringUtilities} from '../../util/QueryStringUtilities';
import {WorkpoolUtilities} from '../../util/WorkpoolUtilities';
import AppRouter from '../../router/AppRouter';
import {
    VIEW_NAME_TO_WORK_POOL_NAME_MAP,
    PRE_RECEIVE_WORKPOOLS_ENUM,
    POST_RECEIVE_WORKPOOLS_ENUM,
    TOP_PRE_RECEIVE_WORKPOOLS_ENUM,
    TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP,
    POST_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP,
    DWELL_TIME_WORKPOOLS_WITHOUT_SUB_WORKPOOLS_ENUM
} from '../../pojos/WorkPools';

import '../../../../css/aggregate-view.scss';

const AGGREGATION_BUCKET_SIZE = 15;
const NA_REALM = "NAFulfillment";
const EU_REALM = "EUFulfillment";
const DEFAULT_REALM = NA_REALM;
const DEFAULT_DWELL_TIME_RANGE_END_VALUE = moment.duration(60, 'days');
const AGGREGATE_RESULT_MAP_FIELD_NAME = "aggregateResultMap";

class AggregateViewReceive extends Component {

    constructor(props) {
        super(props);

        const parsedQueryStrings = QueryStringUtilities.parseQueryStrings(this.props.location.search);

        this.state = {
            showOptionsPanel: false,
            originalResponseInJSONArray: null,
            aggregatedResponseObject: null,
            isLoaded: false,
            rodeoFilters: this.createRodeoFiltersFromQueryStrings(parsedQueryStrings),
            aggregateParameters: this.createAggregateParameters(parsedQueryStrings),
            warehouseTimezone: DEFAULT_TIME_ZONE,
            realm: DEFAULT_REALM,
            isJavaScriptError: false,
            visibleRows: _.values(DWELL_TIME_WORKPOOLS_WITHOUT_SUB_WORKPOOLS_ENUM)
        };

        this.cancelTokenSource = APIGatewayClientProvider.createNewCancelTokenSource();
        this.notificationRef = React.createRef();

        this.toggleOptionsPanel = this.toggleOptionsPanel.bind(this);
        this.loadAggregateReport = this.loadAggregateReport.bind(this);
        this.addQueryParamsToURL = this.addQueryParamsToURL.bind(this);
        this.createAggregateDimensionList = this.createAggregateDimensionList.bind(this);
        this.setExtendedRows = this.setExtendedRows.bind(this);
    }

    componentDidMount() {
        this.retrieveBackendData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            this.parseQueryStringState();
        } else if (!_.isEqual(this.state.rodeoFilters, prevState.rodeoFilters) ||
            this.state.aggregateParameters.xAxis !== prevState.aggregateParameters.xAxis ||
            this.state.aggregateParameters.yAxis !== prevState.aggregateParameters.yAxis ||
            this.state.aggregateParameters.zAxis !== prevState.aggregateParameters.zAxis ||
            this.state.aggregateParameters.expectedStowDateRangeOption !== prevState.aggregateParameters.expectedStowDateRangeOption ||
            this.state.aggregateParameters.trailerDockingTimeRangeOption !== prevState.aggregateParameters.trailerDockingTimeRangeOption ||
            this.state.aggregateParameters.workPoolLastChangeTimeRangeOption !== prevState.aggregateParameters.workPoolLastChangeTimeRangeOption ||
            this.state.aggregateParameters.shipmentType !== prevState.aggregateParameters.shipmentType) {
            this.loadAggregateReport();
        } else if (this.state.aggregateParameters.bucketSize !== prevState.aggregateParameters.bucketSize) {
            this.regenerateReport();
        }
    }

    componentWillUnmount() {
        this.cancelTokenSource.cancel('Unmounting component cancel fetch requests');
    }

    render() {
        return (
            <div data-testid="AggregateViewReceive-TestId" className="container-fluid h-100">
                <NotificationContainer ref={this.notificationRef}/>
                <ReceiveActionBar className="container-fluid aggregate-view-filter-row"
                                  isLoaded={this.state.isLoaded}
                                  viewName={this.props.viewName}
                                  aggregateParameters={this.state.aggregateParameters}
                                  rodeoFilters={this.state.rodeoFilters}
                                  toggleOptionsPanel={this.toggleOptionsPanel}
                                  loadAggregateReport={this.loadAggregateReport}/>
                <div data-testid={`${this.props.viewName}-AggregateOptionsAndMainTable-TestId`}
                     className="row h-100 d-flex flex-row flex-nowrap">
                    <AggregateOptions viewName={this.props.viewName}
                                      isEURealm={this.isEURealm()}
                                      isOpen={this.state.showOptionsPanel}
                                      aggregateParameters={this.state.aggregateParameters}
                                      rodeoFilters={this.state.rodeoFilters}
                                      warehouseTimezone={this.state.warehouseTimezone}
                                      addQueryParamsToURL={this.addQueryParamsToURL}
                                      toggleOptionsPanel={this.toggleOptionsPanel}/>
                    <div className={'col aggregate-table-flex'}>
                        {this.createTableLabel()}
                        {this.generateAggregateViewTables()}
                    </div>
                </div>
            </div>
        );
    }

    generateAggregateViewMainTable(aggregatedResponseObject, MainTable, initialVisibleRows, workPoolAxis) {
        const visibleRows = this.state.aggregateParameters.zAxis === DEFAULT_AXIS_VALUES_ENUM.Z_DEFAULT && this.state.aggregateParameters.yAxis === AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL ? this.state.visibleRows : initialVisibleRows;
        return (<MainTable viewName={this.props.viewName}
                           isLoaded={this.state.isLoaded}
                           warehouseTimezone={this.state.warehouseTimezone}
                           aggregatedResponseObject={aggregatedResponseObject}
                           visibleRows={visibleRows}
                           setAggregateViewReceiveVisibleRows={this.setExtendedRows}
                           workPoolAxis={workPoolAxis}
                           aggregateParameters={this.state.aggregateParameters}
                           rodeoFilters={this.state.rodeoFilters}/>);
    }

    generateAggregateViewTables() {
        const AggregateViewMainTable = this.props.mainTable;
        const aggregatedResponseObject = this.state.aggregatedResponseObject;
        return !AppRouter.isPostReceiveAggregateView(this.props.viewName) ? this.generatePreReceiveAndDwellTimeAggregateViewTables(AggregateViewMainTable, aggregatedResponseObject) : this.generatePostReceiveAggregateViewTables();
    }

    generatePreReceiveAndDwellTimeAggregateViewTables(AggregateViewMainTable, aggregatedResponseObject) {
        let workPoolAxis = "";
        const TOP_PRE_RECEIVE_WORKPOOLS = _.values(TOP_PRE_RECEIVE_WORKPOOLS_ENUM);
        const POST_RECEIVE_WORKPOOLS = Array.from(POST_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP.keys());
        let initialVisibleRows = AppRouter.isDwellTimeAggregateView(this.props.viewName) ? [...POST_RECEIVE_WORKPOOLS, ...TOP_PRE_RECEIVE_WORKPOOLS] : TOP_PRE_RECEIVE_WORKPOOLS;
        if (_.isMap(aggregatedResponseObject)) {
            if (_.isEmpty(aggregatedResponseObject)) {
                let jumboTronCaption = NO_DATA_FOUND;
                if (!this.state.isLoaded) {
                    jumboTronCaption = LOADING_DATA;
                }
                return <div data-testid="Jumbotron-MultiDimension-TestId"
                            className={`jumbotron text-center ${this.state.isLoaded ? '' : 'opaque-loading'}`}>{jumboTronCaption}</div>;
            } else {
                if (this.state.aggregateParameters.yAxis === AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL) {
                    workPoolAxis = AGGREGATE_OPTIONS_ENUM.Y_AXIS
                }
                if (this.state.aggregateParameters.zAxis === AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL) {
                    workPoolAxis = AGGREGATE_OPTIONS_ENUM.Z_AXIS
                    if (_.includes(FORECAST_DISABLED_DIMENSIONS, this.state.aggregateParameters.yAxis)) {
                        Array.from(aggregatedResponseObject.keys()).forEach((key) => {
                            if (WorkpoolUtilities.isASubWorkPool(key)) {
                                aggregatedResponseObject.delete(key);
                            }
                        });
                    }
                    return _.toPairs(aggregatedResponseObject).sort().map(([zAxisValue, aggregatedResponseObjectByZAxisValue]) => {
                        if (_.includes(this.state.visibleRows, zAxisValue)) {
                            let buttonComponent;
                            if (WorkpoolUtilities.isAParentWorkPool(zAxisValue) && !_.includes(FORECAST_DISABLED_DIMENSIONS, this.state.aggregateParameters.yAxis)) {
                                const subworkPools = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[zAxisValue];
                                const isButtonExpanded = subworkPools.some(subworkPool => _.includes(this.state.visibleRows, subworkPool));
                                buttonComponent = <ExpandRowButton rowHeaderValue={zAxisValue}
                                                                    setExtendedRows={this.setExtendedRows}
                                                                    visibleRows={this.state.visibleRows}
                                                                    isOpened={isButtonExpanded}/>
                            }
                            const divClassNameWithIndentation = !_.includes(FORECAST_DISABLED_DIMENSIONS, this.state.aggregateParameters.yAxis) ? (_.isNil(buttonComponent) && !(_.includes(Array.from(POST_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP.keys()), zAxisValue)) && (_.includes(this.state.rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD], WorkpoolUtilities.findParentWorkPool(zAxisValue))) ? 'subworkpool-div' : '') : '';
                            return (
                                <div key={`${zAxisValue}-table`} className={divClassNameWithIndentation}>
                                    <div className="text-primary py-2">{buttonComponent}{zAxisValue}</div>
                                    {this.generateAggregateViewMainTable(aggregatedResponseObjectByZAxisValue, AggregateViewMainTable, Array.from(aggregatedResponseObjectByZAxisValue[AGGREGATE_RESULT_MAP_FIELD_NAME].keys()), workPoolAxis)}
                                </div>
                            );
                        }
                        return <div key={`${zAxisValue}-table`}></div>
                    });
                }
                return _.toPairs(aggregatedResponseObject).sort().map(([zAxisValue, aggregatedResponseObjectByZAxisValue]) => {
                    return (
                        <div key={`${zAxisValue}-table`}>
                            <div className="text-primary py-2">{zAxisValue}</div>
                            {this.generateAggregateViewMainTable(aggregatedResponseObjectByZAxisValue, AggregateViewMainTable, Array.from(aggregatedResponseObjectByZAxisValue[AGGREGATE_RESULT_MAP_FIELD_NAME].keys()), workPoolAxis)}
                        </div>);
                });
            }
        }
        if (this.state.aggregateParameters.yAxis !== AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL && !_.isNil(aggregatedResponseObject)) {
            initialVisibleRows = Array.from(aggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME].keys());
        }
        return this.generateAggregateViewMainTable(aggregatedResponseObject, AggregateViewMainTable, initialVisibleRows, workPoolAxis);
    }

    generatePostReceiveAggregateViewTables() {
        const AggregateViewMainTable = this.props.mainTable;
        const aggregatedResponseObject = this.state.aggregatedResponseObject;
        if (_.isMap(aggregatedResponseObject)) {
            if (_.isEmpty(aggregatedResponseObject)) {
                let jumboTronCaption = NO_DATA_FOUND;
                if (!this.state.isLoaded) {
                    jumboTronCaption = LOADING_DATA;
                }
                return <div data-testid="Jumbotron-MultiDimension-TestId"
                            className={`jumbotron text-center ${this.state.isLoaded ? '' : 'opaque-loading'}`}>{jumboTronCaption}</div>;
            } else {
                return _.toPairs(aggregatedResponseObject).map(([zAxisValue, aggregatedResponseObjectByZAxisValue]) => {
                    return (
                        <div key={`${zAxisValue}-table`}>
                            <div className="text-primary py-2">{zAxisValue}</div>
                            {this.generateAggregateViewMainTable(aggregatedResponseObjectByZAxisValue, AggregateViewMainTable)}
                        </div>);
                });
            }
        } else {
            return this.generateAggregateViewMainTable(aggregatedResponseObject, AggregateViewMainTable);
        }
    }

    setExtendedRows(updatedExtendedRows) {
        this.setState({
            visibleRows: updatedExtendedRows
        });
    }

    createTableLabel() {
        return (
            <div className="h6">
                <strong>
                    {this.state.aggregateParameters.zAxis && `${AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP.get(this.state.aggregateParameters.zAxis)} based `}
                    {`${AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP.get(this.state.aggregateParameters.yAxis)} by ${VIEW_NAME_TO_X_AXIS_PROPERTY_NAME_MAP.get(this.props.viewName)}`}
                </strong>
            </div>
        );
    }

    parseQueryStringState() {
        const parsedQueryStrings = QueryStringUtilities.parseQueryStrings(this.props.location.search);
        this.setState({
            aggregateParameters: this.createAggregateParameters(parsedQueryStrings),
            rodeoFilters: this.createRodeoFiltersFromQueryStrings(parsedQueryStrings)
        });
    }

    retrieveBackendData() {
        Promise.all([this.retrieveWarehouseTimezone(), this.retrieveRealm()]).then(() => this.loadAggregateReport());
    }

    createAggregateParameters(parsedQueryStrings) {
        const aggregateParameters = new AggregateOptionParameters.Builder()
            .withXAxis(AggregateViewReceive.getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, AGGREGATE_OPTIONS_ENUM.X_AXIS, VIEW_NAME_TO_X_AXIS_DIMENSION_MAP.get(this.props.viewName)))
            .withYAxis(AggregateViewReceive.getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, AGGREGATE_OPTIONS_ENUM.Y_AXIS, DEFAULT_AGGREGATE_OPTION_VALUE_MAP.get(AGGREGATE_OPTIONS_ENUM.Y_AXIS)))
            .withZAxis(AggregateViewReceive.getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, AGGREGATE_OPTIONS_ENUM.Z_AXIS, DEFAULT_AGGREGATE_OPTION_VALUE_MAP.get(AGGREGATE_OPTIONS_ENUM.Z_AXIS)))
            .withBucketSize(AggregateViewReceive.getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, AGGREGATE_OPTIONS_ENUM.BUCKET_SIZE, DEFAULT_AGGREGATE_OPTION_VALUE_MAP.get(AGGREGATE_OPTIONS_ENUM.BUCKET_SIZE)))
            .withShipmentType(AggregateViewReceive.getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, AGGREGATE_OPTIONS_ENUM.SHIPMENT_TYPE, DEFAULT_AGGREGATE_OPTION_VALUE_MAP.get(AGGREGATE_OPTIONS_ENUM.SHIPMENT_TYPE)));
        if (AppRouter.isDwellTimeAggregateView(this.props.viewName)) {
            aggregateParameters.withWorkPoolLastChangeTimeRangeOption(AggregateViewReceive.getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, AGGREGATE_OPTIONS_ENUM.WORK_POOL_LAST_CHANGE_TIME_RANGE_OPTION, DEFAULT_AGGREGATE_OPTION_VALUE_MAP.get(AGGREGATE_OPTIONS_ENUM.WORK_POOL_LAST_CHANGE_TIME_RANGE_OPTION)));
        } else if (AppRouter.isPreReceiveAggregateView(this.props.viewName)) {
            aggregateParameters.withTrailerDockingTimeRangeOption(AggregateViewReceive.getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, AGGREGATE_OPTIONS_ENUM.TRAILER_DOCKING_TIME_RANGE_OPTION, DEFAULT_AGGREGATE_OPTION_VALUE_MAP.get(AGGREGATE_OPTIONS_ENUM.TRAILER_DOCKING_TIME_RANGE_OPTION)));
        } else {
            aggregateParameters.withExpectedStowDateRangeOption(AggregateViewReceive.getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, AGGREGATE_OPTIONS_ENUM.EXPECTED_STOW_DATE_RANGE_OPTION, DEFAULT_AGGREGATE_OPTION_VALUE_MAP.get(AGGREGATE_OPTIONS_ENUM.EXPECTED_STOW_DATE_RANGE_OPTION)));
        }
        return aggregateParameters.build();
    }

    createRodeoFiltersFromQueryStrings(parsedQueryStrings) {
        const containerIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.CONTAINER_ID_QUERY_STRING_KEY];
        const containerSizesQueryString = parsedQueryStrings[RodeoFilterQueryStrings.CONTAINER_SIZES_QUERY_STRING_KEY];
        const containerTypesQueryString = parsedQueryStrings[RodeoFilterQueryStrings.CONTAINER_TYPES_QUERY_STRING_KEY];
        const dwellTimeMillisQueryString = parsedQueryStrings[RodeoFilterQueryStrings.DWELL_TIME_MILLIS_QUERY_STRING_KEY];
        const fcSkuQueryString = parsedQueryStrings[RodeoFilterQueryStrings.FC_SKU_QUERY_STRING_KEY];
        const fnSkuQueryString = parsedQueryStrings[RodeoFilterQueryStrings.FN_SKU_QUERY_STRING_KEY];
        const isaIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.ISA_ID_QUERY_STRING_KEY];
        const isdIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.ISD_ID_QUERY_STRING_KEY];
        const outerContainerIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.OUTER_CONTAINER_ID_QUERY_STRING_KEY];
        const outerContainerTypesQueryString = parsedQueryStrings[RodeoFilterQueryStrings.OUTER_CONTAINER_TYPES_QUERY_STRING_KEY];
        const outerScannableIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.OUTER_SCANNABLE_ID_QUERY_STRING_KEY];
        const outerOuterScannableIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.OUTER_OUTER_SCANNABLE_ID_QUERY_STRING_KEY];
        const floorQueryString = parsedQueryStrings[RodeoFilterQueryStrings.FLOOR_QUERY_STRING_KEY];
        const modQueryString = parsedQueryStrings[RodeoFilterQueryStrings.MOD_QUERY_STRING_KEY];
        const processPathsQueryString = parsedQueryStrings[RodeoFilterQueryStrings.PROCESS_PATHS_QUERY_STRING_KEY];
        const scannableIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.SCANNABLE_ID_QUERY_STRING_KEY];
        const sourceWarehouseIdsQueryString = parsedQueryStrings[RodeoFilterQueryStrings.SOURCE_WAREHOUSE_IDS_QUERY_STRING_KEY];
        const statusQueryString = parsedQueryStrings[RodeoFilterQueryStrings.STATUS_QUERY_STRING_KEY];
        const vrIdQueryString = parsedQueryStrings[RodeoFilterQueryStrings.VR_ID_QUERY_STRING_KEY];
        const isReactiveQueryString = parsedQueryStrings[RodeoFilterQueryStrings.IS_REACTIVE_QUERY_STRING_KEY];
        const workPoolsQueryString = parsedQueryStrings[RodeoFilterQueryStrings.WORK_POOLS_QUERY_STRING_KEY];
        const statusFilters = !_.isEmpty(statusQueryString) ? statusQueryString : [...VIEW_NAME_TO_STATUS_NAME_MAP.get(this.props.viewName).keys()];
        const rodeoFiltersBuilder = new RodeoFilters.Builder();
        if (!_.isEmpty(containerIdQueryString)) {
            rodeoFiltersBuilder.withContainerIdFilter(new ContainerIdFilter().withValue(containerIdQueryString))
        }
        if (!_.isEmpty(containerSizesQueryString)) {
            rodeoFiltersBuilder.withContainerSizeFilter(new ContainerSizeFilter().withValues(containerSizesQueryString));
        }
        if (!_.isEmpty(containerTypesQueryString)) {
            rodeoFiltersBuilder.withContainerTypeFilter(new ContainerTypeFilter().withValues(containerTypesQueryString));
        }
        if (!_.isEmpty(fcSkuQueryString)) {
            rodeoFiltersBuilder.withFcSkuFilter(new FcSkuFilter().withValue(fcSkuQueryString));
        }
        if (!_.isEmpty(fnSkuQueryString)) {
            rodeoFiltersBuilder.withFnSkuFilter(new FnSkuFilter().withValue(fnSkuQueryString));
        }
        if (!_.isEmpty(isaIdQueryString)) {
            rodeoFiltersBuilder.withISAIdFilter(new ISAIdFilter().withValue(isaIdQueryString));
        }
        if (!_.isEmpty(isdIdQueryString)) {
            rodeoFiltersBuilder.withISDIdFilter(new ISDIdFilter().withValue(isdIdQueryString));
        }
        if (!_.isEmpty(outerContainerIdQueryString)) {
            rodeoFiltersBuilder.withOuterContainerIdFilter(new OuterContainerIdFilter().withValue(outerContainerIdQueryString));
        }
        if (!_.isEmpty(outerContainerTypesQueryString)) {
            rodeoFiltersBuilder.withOuterContainerTypeFilter(new OuterContainerTypeFilter().withValues(outerContainerTypesQueryString));
        }
        if (!_.isEmpty(outerScannableIdQueryString)) {
            rodeoFiltersBuilder.withOuterScannableIdFilter(new OuterScannableIdFilter().withValues(outerScannableIdQueryString));
        }
        if (!_.isEmpty(outerOuterScannableIdQueryString)) {
            rodeoFiltersBuilder.withOuterOuterScannableIdFilter(new OuterOuterScannableIdFilter().withValues(outerOuterScannableIdQueryString));
        }
        if (!_.isEmpty(floorQueryString)) {
            rodeoFiltersBuilder.withFloorFilter(new FloorFilter().withValues(floorQueryString));
        }
        if (!_.isEmpty(modQueryString)) {
            rodeoFiltersBuilder.withModFilter(new ModFilter().withValues(modQueryString));
        }
        if (!_.isEmpty(processPathsQueryString)) {
            rodeoFiltersBuilder.withProcessPathFilter(new ProcessPathFilter().withValues(processPathsQueryString));
        }
        if (!_.isEmpty(scannableIdQueryString)) {
            rodeoFiltersBuilder.withScannableIdFilter(new ScannableIdFilter().withValues(scannableIdQueryString));
        }
        if (!_.isEmpty(sourceWarehouseIdsQueryString)) {
            rodeoFiltersBuilder.withSourceWarehouseIdFilter(new SourceWarehouseIdFilter().withValues(sourceWarehouseIdsQueryString));
        }
        if (!_.isEmpty(vrIdQueryString)) {
            rodeoFiltersBuilder.withVRIdFilter(new VRIdFilter().withValue(vrIdQueryString));
        }
        if (!_.isEmpty(isReactiveQueryString)){
            rodeoFiltersBuilder.withIsReactiveFilter(new IsReactiveFilter().withValue(isReactiveQueryString));
        }
        if (!_.isEmpty(workPoolsQueryString)) {
            rodeoFiltersBuilder.withWorkPoolFilter(new WorkPoolFilter().withValues(workPoolsQueryString));
        } else {
            const defaultWorkPoolFilters = [...VIEW_NAME_TO_WORK_POOL_NAME_MAP.get(this.props.viewName).keys()];
            const filteredWorkPools = defaultWorkPoolFilters.filter(workPool => workPool !== POST_RECEIVE_WORKPOOLS_ENUM.STOWED);
            rodeoFiltersBuilder.withWorkPoolFilter(new WorkPoolFilter().withValues(filteredWorkPools));
        }
        rodeoFiltersBuilder.withStatusFilter(new StatusFilter().withValues(statusFilters));
        if (!_.isEmpty(dwellTimeMillisQueryString) && !(_.isEmpty(dwellTimeMillisQueryString.start) && _.isEmpty(dwellTimeMillisQueryString.end))) {
            const filter = new WorkPoolLastChangedTimeFilter();
            if (!_.isEmpty(dwellTimeMillisQueryString.start)) {
                filter.withTo(moment().unix() - dwellTimeMillisQueryString.start / 1000);
            }
            if (!_.isEmpty(dwellTimeMillisQueryString.end)) {
                filter.withFrom(moment().unix() - dwellTimeMillisQueryString.end / 1000);
            }
            rodeoFiltersBuilder.withWorkPoolLastChangedTimeFilter(filter);
        }

        return rodeoFiltersBuilder.build();
    }

    toggleOptionsPanel() {
        this.setState(prevState => {
            return {showOptionsPanel: !prevState.showOptionsPanel}
        });
    }

    createAggregateDimensionList(selectedBucketReferencePoint) {
        const aggregateDimensionList = {};
        aggregateDimensionList[this.state.aggregateParameters.xAxis] = {"bucketSize": AGGREGATION_BUCKET_SIZE};
        if (!_.isNil(selectedBucketReferencePoint)) {
            aggregateDimensionList[this.state.aggregateParameters.xAxis] = {
                "bucketSize": AGGREGATION_BUCKET_SIZE,
                "bucketReferencePoint": selectedBucketReferencePoint
            };
        }
        aggregateDimensionList[this.state.aggregateParameters.yAxis] = {};
        if (this.state.aggregateParameters.zAxis !== DEFAULT_AXIS_VALUES_ENUM.Z_DEFAULT) {
            aggregateDimensionList[this.state.aggregateParameters.zAxis] = {};
        }
        return aggregateDimensionList;
    }

    createAggregateReportRequestInput(shipmentTypes, rodeoFilters, selectedBucketReferencePoint) {
        return new AggregateReportRequestInput.Builder()
            .withWarehouseId(this.props.warehouseId)
            .withShipmentTypes(shipmentTypes)
            .withFilterList(rodeoFilters)
            .withAggregateDimensionList(this.createAggregateDimensionList(selectedBucketReferencePoint))
            .build();
    }

    retrieveWarehouseTimezone() {
        return this.context.client.getTimezoneForWarehouseId(this.props.match.params.warehouseId, this.cancelTokenSource.token).then(response => {
            const warehouseTimezone = response.data.timezone;
            if (_.isEmpty(warehouseTimezone)) {
                this.notificationRef.current.addNotification(new Notification.Builder('aggregateViewNoTimezoneInfoFoundError')
                    .withType(Notification.TYPES.WARN)
                    .withMessage(`No warehouse timezone information found, defaulting to ${DEFAULT_TIME_ZONE}`)
                    .build());
            } else {
                this.setState({warehouseTimezone});
            }
        }).catch(error => {
            if (!axios.isCancel(error)) {
                const errorMessage = APIGatewayClientProvider.parseError(error);
                this.notificationRef.current.addNotification(new Notification.Builder('aggregateViewTimezoneLoadError')
                    .withType(Notification.TYPES.WARN)
                    .withMessage(`Problem retrieving warehouse timezone, defaulting to ${DEFAULT_TIME_ZONE}: ${errorMessage}`)
                    .build());
            }
        });
    }

    retrieveRealm() {
        return this.context.client.getRealm(this.cancelTokenSource.token).then(response => {
            this.setState({realm: response.data.realm});
        }).catch(error => {
            if (!axios.isCancel(error)) {
                const errorMessage = APIGatewayClientProvider.parseError(error);
                this.notificationRef.current.addNotification(new Notification.Builder('aggregateViewRealmLoadError')
                    .withType(Notification.TYPES.WARN)
                    .withMessage(`Problem retrieving the current realm. Defaulting to ${DEFAULT_REALM}: ${errorMessage}`)
                    .build());
            }
        });
    }

    regenerateReport() {
        const aggregatedResponseObject = this.aggregateReport();
        this.setState({aggregatedResponseObject: aggregatedResponseObject, isLoaded: true})
    }

    aggregateReport(originalResponseInJSONArray = null) {
        if (_.isNil(originalResponseInJSONArray)) {
            originalResponseInJSONArray = this.state.originalResponseInJSONArray;
        }
        let previousAggregatedResponseObject = null;
        const aggregationAction = new AggregationAction(this.props.viewName,
            this.state.aggregateParameters,
            this.state.warehouseTimezone,
            this.state.rodeoFilters);
        originalResponseInJSONArray.forEach((originalResponseInJSON) => {
            previousAggregatedResponseObject = aggregationAction.aggregate(originalResponseInJSON, previousAggregatedResponseObject);
        });
        return this.updatePreviousAggregatedResponseObjectForWorkPoolDimension(previousAggregatedResponseObject);
    }

    updatePreviousAggregatedResponseObjectForWorkPoolDimension(previousAggregatedResponseObject) {
        if (!AppRouter.isPostReceiveAggregateView(this.props.viewName) && (_.includes(this.state.aggregateParameters.yAxis, AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL) || _.includes(this.state.aggregateParameters.zAxis, AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL)) && !_.isNil(previousAggregatedResponseObject)) {
            let previousAggregatedResponseObjectKeys;
            if (this.state.aggregateParameters.yAxis === AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL) {
                previousAggregatedResponseObjectKeys = _.isNil(previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME]) ? Array.from(previousAggregatedResponseObject.values()) : Array.from(previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME].keys());
                if (this.state.aggregateParameters.zAxis === DEFAULT_AXIS_VALUES_ENUM.Z_DEFAULT) {
                    previousAggregatedResponseObjectKeys.forEach((previousAggregatedResponseObjectKey) => {
                        if (!_.includes(this.state.rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD], previousAggregatedResponseObjectKey)) {
                            previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME].delete(previousAggregatedResponseObjectKey);
                            this.updateVisibleRows(previousAggregatedResponseObjectKey);
                        }
                        previousAggregatedResponseObject = this.removeParentWorkPoolWithNoChildren(previousAggregatedResponseObjectKeys, previousAggregatedResponseObjectKey, previousAggregatedResponseObject);
                    });
                } else {
                    previousAggregatedResponseObjectKeys.forEach((previousAggregatedResponseObjectKey) => {
                        const workPoolList = Array.from(previousAggregatedResponseObjectKey[AGGREGATE_RESULT_MAP_FIELD_NAME].keys());
                        workPoolList.forEach((workPool) => {
                            if (!_.includes(this.state.rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD], workPool)) {
                                previousAggregatedResponseObjectKey[AGGREGATE_RESULT_MAP_FIELD_NAME].delete(workPool);
                                this.updateVisibleRows(previousAggregatedResponseObjectKey);
                            }
                            previousAggregatedResponseObject = this.removeParentWorkPoolWithNoChildren(previousAggregatedResponseObjectKey[AGGREGATE_RESULT_MAP_FIELD_NAME], workPool, previousAggregatedResponseObject);
                        });
                    });
                }
            } else {
                previousAggregatedResponseObjectKeys = _.isNil(previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME]) ? Array.from(previousAggregatedResponseObject.keys()) : Array.from(previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME].keys());
                previousAggregatedResponseObjectKeys.forEach((previousAggregatedResponseObjectKey) => {
                    if (!_.includes(this.state.rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD], previousAggregatedResponseObjectKey)) {
                        _.isNil(previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME]) ? previousAggregatedResponseObject.delete(previousAggregatedResponseObjectKey) : previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME].delete(previousAggregatedResponseObjectKey);
                        this.updateVisibleRows(previousAggregatedResponseObjectKey);
                    }
                    previousAggregatedResponseObject = this.removeParentWorkPoolWithNoChildren(previousAggregatedResponseObjectKeys, previousAggregatedResponseObjectKey, previousAggregatedResponseObject);
                });
            }
        }
        return previousAggregatedResponseObject;
    }

    updateVisibleRows(workPool) {
        const subworkPools = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[workPool];
        if (!_.isNil(subworkPools)) {
            subworkPools.forEach((subworkPool) => {
                if (_.includes(_.values(this.state.rodeoFilters[WORK_POOL_FILTER_NAME])[0], subworkPool)) {
                    this.setState({visibleRows: [...this.state.visibleRows, subworkPool]});
                }
            });
        }
    }

    removeParentWorkPoolWithNoChildren(workPools, workPool, previousAggregatedResponseObject) {
        if (WorkpoolUtilities.isAParentWorkPool(workPool)) {
            const actualsSubWorkPool = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[workPool][0];
            const forecastedSubWorkPool = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[workPool][1];
            if (_.isMap(workPools)) {
                const workPoolList = Array.from(workPools.keys());
                if (!_.includes(workPoolList, actualsSubWorkPool) && !_.includes(workPoolList, forecastedSubWorkPool)) {
                    workPools.delete(workPool);
                }
            } else {
                if (!_.includes(workPools, actualsSubWorkPool) && !_.includes(workPools, forecastedSubWorkPool)) {
                    _.isNil(previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME]) ? previousAggregatedResponseObject.delete(workPool) : previousAggregatedResponseObject[AGGREGATE_RESULT_MAP_FIELD_NAME].delete(workPool);
                }
            }
        }
        return previousAggregatedResponseObject;
    }

    createRequestsForAllShipmentTypes(rodeoFilters, workPools, unloadedWorkPool = null) {
        const workPoolsWithoutUnloaded = _.filter(rodeoFilters.workPoolFilter.values, workPool => !workPool.startsWith(unloadedWorkPool));
        const newRodeoFilterWithUnloaded = {
            ...rodeoFilters,
            workPoolFilter: new WorkPoolFilter().withValues(workPools)
        };
        const newRodeoFilterWithoutUnloaded = {
            ...rodeoFilters,
            workPoolFilter: new WorkPoolFilter().withValues(workPoolsWithoutUnloaded)
        };
        const shipmentTypeContainingUnloadedWorkPool = AppRouter.isPreReceiveAggregateView(this.props.viewName) && !this.isEURealm() ? SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE : SHIPMENT_TYPES_ENUM.TRANSSHIPMENT;
        const secondAndOppositeShipmentType = shipmentTypeContainingUnloadedWorkPool === SHIPMENT_TYPES_ENUM.TRANSSHIPMENT ? SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE : SHIPMENT_TYPES_ENUM.TRANSSHIPMENT;

        let selectedBucketReferencePoint;
        const requests = [];
        if (AppRouter.isDwellTimeAggregateView(this.props.viewName)) {
            selectedBucketReferencePoint = moment.tz(this.state.warehouseTimezone).unix();
        }

        if (AppRouter.isPostReceiveAggregateView(this.props.viewName)) {
            const firstRequestInput = this.createAggregateReportRequestInput(shipmentTypeContainingUnloadedWorkPool, newRodeoFilterWithUnloaded, selectedBucketReferencePoint);
            const secondRequestInput = this.createAggregateReportRequestInput(secondAndOppositeShipmentType, this.isEURealm() && secondAndOppositeShipmentType === SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE ? newRodeoFilterWithUnloaded : newRodeoFilterWithoutUnloaded, selectedBucketReferencePoint);

            const firstRequest = this.context.client.getAggregateReport(firstRequestInput, this.cancelTokenSource.token);
            const secondRequest = this.context.client.getAggregateReport(secondRequestInput, this.cancelTokenSource.token);
            requests.push(firstRequest, secondRequest);
        } else {
            const aggregateDimensionList = Object.keys(this.createAggregateDimensionList(selectedBucketReferencePoint));
            if (_.includes(rodeoFilters.statusFilter.values, NON_POST_RECEIVE_STATUS_ENUM.FORECASTED) && !aggregateDimensionList.some(aggregateDimension => _.includes(FORECAST_DISABLED_DIMENSIONS, aggregateDimension))) {
                const newRodeoFilterWithUnloadedAndParentsAppended = this.appendParentFilters(newRodeoFilterWithUnloaded);
                const newRodeoFilterWithoutUnloadedAndParentsAppended = this.appendParentFilters(newRodeoFilterWithoutUnloaded);

                const firstForecastedShipmentType = ACTUAL_TO_FORECASTED_SHIPMENT_TYPE_MAP.get(shipmentTypeContainingUnloadedWorkPool);
                const secondAndOppositeForecastedShipmentType = firstForecastedShipmentType === FORECASTED_SHIPMENT_TYPES_ENUM.FORECASTED_TRANSSHIPMENT ? FORECASTED_SHIPMENT_TYPES_ENUM.FORECASTED_VENDOR_RECEIVE : FORECASTED_SHIPMENT_TYPES_ENUM.FORECASTED_TRANSSHIPMENT;

                const forecastedFirstRequestInput = this.createAggregateReportRequestInput(firstForecastedShipmentType, newRodeoFilterWithUnloadedAndParentsAppended, selectedBucketReferencePoint);
                const forecastedSecondRequestInput = this.createAggregateReportRequestInput(secondAndOppositeForecastedShipmentType, this.isEURealm() && secondAndOppositeShipmentType === SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE ? newRodeoFilterWithUnloaded : newRodeoFilterWithoutUnloadedAndParentsAppended, selectedBucketReferencePoint);

                const firstForecastedRequest = this.context.client.getAggregateReport(forecastedFirstRequestInput, this.cancelTokenSource.token);
                const secondForecastedRequest = this.context.client.getAggregateReport(forecastedSecondRequestInput, this.cancelTokenSource.token);
                requests.push(firstForecastedRequest, secondForecastedRequest);
            }
            if (_.includes(rodeoFilters.statusFilter.values, NON_POST_RECEIVE_STATUS_ENUM.ACTUALS)) {
                const firstRequestInput = this.createAggregateReportRequestInput(shipmentTypeContainingUnloadedWorkPool, newRodeoFilterWithUnloaded, selectedBucketReferencePoint);
                const secondRequestInput = this.createAggregateReportRequestInput(secondAndOppositeShipmentType, this.isEURealm() && secondAndOppositeShipmentType === SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE ? newRodeoFilterWithUnloaded : newRodeoFilterWithoutUnloaded, selectedBucketReferencePoint);

                const firstRequest = this.context.client.getAggregateReport(firstRequestInput, this.cancelTokenSource.token);
                const secondRequest = this.context.client.getAggregateReport(secondRequestInput, this.cancelTokenSource.token);
                requests.push(firstRequest, secondRequest);
            }
        }
        return requests;
    }

    appendParentFilters(rodeoFilters) {
        const workPools = rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD];
        _.values(workPools).forEach(workPool => {
            if (WorkpoolUtilities.isASubWorkPool(workPool)) {
                const parentFilter = WorkpoolUtilities.findParentWorkPool(workPool);
                if (!_.includes(workPools, parentFilter)) {
                    rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD].push(parentFilter);
                }
            }
        });
        return rodeoFilters;
    }

    loadAggregateReport() {
        this.cancelTokenSource.cancel('Cancelling previous running AggregateView ajax requests');
        this.cancelTokenSource = APIGatewayClientProvider.createNewCancelTokenSource();
        this.setState({isLoaded: false});

        const parsedQueryStrings = QueryStringUtilities.parseQueryStrings(this.props.location.search);
        const rodeoFilters = this.createRodeoFiltersFromQueryStrings(parsedQueryStrings);

        const trailerDockingTimeRangeOption  = this.state.aggregateParameters[AGGREGATE_OPTIONS_ENUM.TRAILER_DOCKING_TIME_RANGE_OPTION];
        if (AppRouter.isPreReceiveAggregateView(this.props.viewName) && trailerDockingTimeRangeOption !== DATE_RANGE_ENUM.ALL) {
            const trailerDockingTimeInterval = DateUtilities.getStartAndEndMillisFromSelectedRangeName(trailerDockingTimeRangeOption, this.state.warehouseTimezone);
            const trailerDockingTimeFilter = new TrailerDockingTimeFilter();
            trailerDockingTimeFilter.withTo(trailerDockingTimeInterval.end);
            trailerDockingTimeFilter.withFrom(0);
            rodeoFilters.trailerDockingTimeFilter = trailerDockingTimeFilter;
        }

        let requests = [];
        if (this.state.aggregateParameters.shipmentType !== SHIPMENT_TYPES_ENUM.ALL) {
            if (!AppRouter.isPostReceiveAggregateView(this.props.viewName)) {
                let selectedBucketReferencePoint;
                if (AppRouter.isDwellTimeAggregateView(this.props.viewName)) {
                    selectedBucketReferencePoint = moment.tz(this.state.warehouseTimezone).unix();
                }
                for (const statusFilter of rodeoFilters.statusFilter.values) {
                    const aggregateReportRequestInput = (statusFilter === NON_POST_RECEIVE_STATUS_ENUM.FORECASTED) ? this.createAggregateReportRequestInput(ACTUAL_TO_FORECASTED_SHIPMENT_TYPE_MAP.get(this.state.aggregateParameters.shipmentType), rodeoFilters, selectedBucketReferencePoint)
                        : this.createAggregateReportRequestInput(this.state.aggregateParameters.shipmentType, rodeoFilters, selectedBucketReferencePoint);
                    const aggregateReportRequest = this.context.client.getAggregateReport(aggregateReportRequestInput, this.cancelTokenSource.token);
                    requests.push(aggregateReportRequest);
                }
            } else {
                const aggregateReportRequestInput = this.createAggregateReportRequestInput(this.state.aggregateParameters.shipmentType, rodeoFilters);
                const aggregateReportRequest = this.context.client.getAggregateReport(aggregateReportRequestInput, this.cancelTokenSource.token);
                requests.push(aggregateReportRequest);
            }
        } else {
            if (AppRouter.isDwellTimeAggregateView(this.props.viewName)) {
                requests = this.createRequestsForAllShipmentTypes(rodeoFilters, rodeoFilters.workPoolFilter.values);
            } else {
                const unloadedWorkPool = AppRouter.isPreReceiveAggregateView(this.props.viewName) ? PRE_RECEIVE_WORKPOOLS_ENUM.UNLOADED : POST_RECEIVE_WORKPOOLS_ENUM.UNLOADED;
                requests = this.createRequestsForAllShipmentTypes(rodeoFilters, rodeoFilters.workPoolFilter.values, unloadedWorkPool);
            }
        }

        Promise.all(requests).then(responses => responses.map((response) => {
            return response.data;
        })).then((originalResponseInJSONArray) => {
            const aggregatedResponseObject = this.aggregateReport(originalResponseInJSONArray);
            this.setState({
                aggregatedResponseObject: aggregatedResponseObject,
                originalResponseInJSONArray: originalResponseInJSONArray,
                isLoaded: true
            });
        }).catch(error => {
                if (!axios.isCancel(error)) {
                    const errorMessage = APIGatewayClientProvider.parseError(error);
                    this.notificationRef.current.addNotification(new Notification.Builder('aggregateViewDataLoadError')
                        .withType(Notification.TYPES.ERROR)
                        .withMessage(`Error retrieving aggregations: ${errorMessage}`)
                        .build());
                    this.setState({originalResponseInJSONArray: [], isLoaded: true});
                }
            }
        );
    }

    addQueryParamsToURL(aggregateParameters, rodeoFilters) {
        const newQueryString = {};
        _.toPairs(aggregateParameters).forEach(([parameterKey, parameterValue]) => {
            newQueryString[parameterKey] = parameterValue;
        });
        _.toPairs(rodeoFilters).forEach(([filterName, filterValue]) => {
            const queryStringKey = RODEO_FILTER_NAME_TO_QUERY_STRING_KEY_MAP.get(filterName);
            if (!_.isNil(filterValue)) {
                if (filterName === WORK_POOL_LAST_CHANGED_TIME_FILTER_NAME) {
                    const start = filterValue.from;
                    let end = filterValue.to;
                    if (start !== 0 && end === 0) {
                        end = start + DEFAULT_DWELL_TIME_RANGE_END_VALUE;
                    }
                    newQueryString[queryStringKey] = {start, end};
                } else {
                    newQueryString[queryStringKey] = filterValue;
                }
            }
        });

        this.props.history.push(`${this.props.location.pathname}?${QueryStringUtilities.stringifyQueryStrings({...newQueryString})}`);
    }

    isEURealm() {
        return _.toLower(this.state.realm) === _.toLower(EU_REALM);
    }

    static getParameterValueFromQueryStringsOrDefault(parsedQueryStrings, property, defaultValue) {
        return _.isNil(parsedQueryStrings[property]) ? defaultValue : parsedQueryStrings[property];
    }
}

export {AggregateViewReceive};

AggregateViewReceive.contextType = APIGatewayClientContext;
export default withRouter(AggregateViewReceive);
