import {DATE_RANGE_ENUM, DEFAULT_AXIS_VALUES_ENUM, REACTIVE_TRANSFER_VALUE_ENUM, SHIPMENT_TYPES_ENUM} from './Dimensions';
import {BUCKET_SIZES} from './Buckets';
import {VIEW_NAMES_ENUM} from './ViewNames';

export const AGGREGATE_OPTIONS_ENUM = {
    X_AXIS: "xAxis",
    Y_AXIS: "yAxis",
    Z_AXIS: "zAxis",
    BUCKET_SIZE: "bucketSize",
    REACTIVE_TRANSFER: "reactiveTransfer",
    EXPECTED_STOW_DATE_RANGE_OPTION: "expectedStowDateRangeOption",
    TRAILER_DOCKING_TIME_RANGE_OPTION: "trailerDockingTimeRangeOption",
    WORK_POOL_LAST_CHANGE_TIME_RANGE_OPTION: "workPoolLastChangeTimeRangeOption",
    SHIPMENT_TYPE: "shipmentType"
};

export const DEFAULT_AGGREGATE_OPTION_VALUE_MAP = new Map([
    [AGGREGATE_OPTIONS_ENUM.Y_AXIS, DEFAULT_AXIS_VALUES_ENUM.Y_DEFAULT],
    [AGGREGATE_OPTIONS_ENUM.Z_AXIS, DEFAULT_AXIS_VALUES_ENUM.Z_DEFAULT],
    [AGGREGATE_OPTIONS_ENUM.BUCKET_SIZE, BUCKET_SIZES.ONE_HOUR],
    [AGGREGATE_OPTIONS_ENUM.REACTIVE_TRANSFER, REACTIVE_TRANSFER_VALUE_ENUM.ALL],
    [AGGREGATE_OPTIONS_ENUM.EXPECTED_STOW_DATE_RANGE_OPTION, DATE_RANGE_ENUM.TODAY],
    [AGGREGATE_OPTIONS_ENUM.TRAILER_DOCKING_TIME_RANGE_OPTION, DATE_RANGE_ENUM.TODAY],
    [AGGREGATE_OPTIONS_ENUM.WORK_POOL_LAST_CHANGE_TIME_RANGE_OPTION, DATE_RANGE_ENUM.ALL],
    [AGGREGATE_OPTIONS_ENUM.SHIPMENT_TYPE, SHIPMENT_TYPES_ENUM.ALL]
]);

export const VIEW_NAME_TO_TIME_RANGE_PROPERTY_MAP = new Map([
    [VIEW_NAMES_ENUM.POST_RECEIVE, AGGREGATE_OPTIONS_ENUM.EXPECTED_STOW_DATE_RANGE_OPTION],
    [VIEW_NAMES_ENUM.PRE_RECEIVE, AGGREGATE_OPTIONS_ENUM.TRAILER_DOCKING_TIME_RANGE_OPTION],
    [VIEW_NAMES_ENUM.DWELL_TIME, AGGREGATE_OPTIONS_ENUM.WORK_POOL_LAST_CHANGE_TIME_RANGE_OPTION]
]);

export class AggregateOptionParameters {

    constructor(builder) {
        this.xAxis = builder.xAxis;
        this.yAxis = builder.yAxis;
        this.zAxis = builder.zAxis;
        this.bucketSize = builder.bucketSize;
        this.expectedStowDateRangeOption = builder.expectedStowDateRangeOption;
        this.trailerDockingTimeRangeOption = builder.trailerDockingTimeRangeOption;
        this.workPoolLastChangeTimeRangeOption = builder.workPoolLastChangeTimeRangeOption;
        this.shipmentType = builder.shipmentType;
    }

    static get Builder() {

        class Builder {

            withXAxis(xAxis) {
                this.xAxis = xAxis;
                return this;
            }

            withYAxis(yAxis) {
                this.yAxis = yAxis;
                return this;
            }

            withZAxis(zAxis) {
                this.zAxis = zAxis;
                return this;
            }

            withBucketSize(bucketSize) {
                this.bucketSize = bucketSize;
                return this;
            }

            withExpectedStowDateRangeOption(expectedStowDateRangeOption) {
                this.expectedStowDateRangeOption = expectedStowDateRangeOption;
                return this;
            }

            withTrailerDockingTimeRangeOption(trailerDockingTimeRangeOption) {
                this.trailerDockingTimeRangeOption = trailerDockingTimeRangeOption;
                return this;
            }

            withWorkPoolLastChangeTimeRangeOption(workPoolLastChangeTimeRangeOption) {
                this.workPoolLastChangeTimeRangeOption = workPoolLastChangeTimeRangeOption;
                return this;
            }

            withShipmentType(shipmentType) {
                this.shipmentType = shipmentType;
                return this;
            }

            build() {
                return new AggregateOptionParameters(this);
            }
        }

        return Builder;
    }
}