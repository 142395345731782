import React, {Component} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Alert} from 'react-bootstrap';
import _ from 'lodash';

import Notification from './Notification';

import '../../../css/notifications.scss';
import '../../../css/animations.scss';

export default class NotificationContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notifications: []
        };
    }

    render() {
        return (
            <TransitionGroup>
                {this.state.notifications.map(notification => {
                    this.setupTimeout(notification);
                    const notificationType = !_.isNil(notification.type) ? notification.type : Notification.TYPES.INFO;
                    return (
                        <CSSTransition key={notification.id}
                                       classNames='fade'
                                       timeout={200}>
                            <Alert variant={notificationType}
                                   dismissible={notification.isDismissible}
                                   data-notification-id={notification.id}
                                   onClose={notification.isDismissible ? () => this.removeNotification(notification.id) : null}
                                   data-testid={notification.id}
                            >
                                <div>{notification.message}</div>
                            </Alert>
                        </CSSTransition>);
                })}
            </TransitionGroup>
        );
    }

    setupTimeout(notification) {
        if (notification.timeoutSeconds) {
            setTimeout(() => this.removeNotification(notification.id), notification.timeoutSeconds * 1000);
        }
    }

    addNotification(notification) {
        this.setState(prevState => {
            return {notifications: [...prevState.notifications, notification]};
        })
    }

    removeNotification(notificationId) {
        this.setState(prevState => {
            return {notifications: prevState.notifications.filter(notification => notification.id !== notificationId)};
        });
    }
}