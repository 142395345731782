import React, {Component} from 'react';
import {Accordion, Card, Collapse} from 'react-bootstrap';
import _ from 'lodash';

import {OPTIONS_PROPERTY_NAME_TO_COLUMN_NAME_MAP} from '../pojos/ItemListRecord';
import {FORECASTED_OPTIONS_PROPERTY_NAME_TO_COLUMN_NAME_MAP} from '../pojos/ForecastedItemListRecord';
import {
    START_DWELL_TYPE,
    END_DWELL_TYPE,
    DWELL_TIME_UNIT_DAYS,
    DWELL_TIME_UNIT_HOURS,
    DWELL_TIME_UNIT_MINUTES
} from '../../pojos/DwellTime';
import {ContextAwareToggle} from "../../aggregate/options/AggregateOptions";

export default class ItemListOptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedPropertyNameToColumnNameMap: new Map(this.props.additionalPropertyNameToHeaderNameMap),
            dwellTimeDaysStart: null,
            dwellTimeHoursStart: null,
            dwellTimeMinutesStart: null,
            dwellTimeDaysEnd: null,
            dwellTimeHoursEnd: null,
            dwellTimeMinutesEnd: null
        };

        this.addOrRemoveAdditionalColumnToMap = this.addOrRemoveAdditionalColumnToMap.bind(this);
        this.filterReport = this.filterReport.bind(this);
        this.updateDwellTime = this.updateDwellTime.bind(this);
    }

    render() {
        const OPTIONS_NAME_TO_COLUMN_NAME_MAP = this.props.isForecast ? FORECASTED_OPTIONS_PROPERTY_NAME_TO_COLUMN_NAME_MAP : OPTIONS_PROPERTY_NAME_TO_COLUMN_NAME_MAP;
        return (
            <Collapse in={this.props.isOpen}>
                <div className="col-md-2">
                    <Accordion defaultActiveKey="0">
                        <Card bg="light">
                            <ContextAwareToggle eventKey="0">
                                Additional Columns
                            </ContextAwareToggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {[...OPTIONS_NAME_TO_COLUMN_NAME_MAP.entries()].map(([propertyName, columnName]) => this.createCheckboxAdditionalHeaderRow(propertyName, columnName))}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card bg="light">
                            <ContextAwareToggle eventKey="1">
                                Dwell Time
                            </ContextAwareToggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    {this.createDwellTimeInputGroup()}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <Card bg="light">
                        <button type="button" className="btn btn-secondary btn-sm" onClick={this.filterReport}>Apply
                            Filters
                        </button>
                    </Card>
                </div>
            </Collapse>
        );
    }

    createDwellTimeInputGroup() {
        return (
            <div className="form-group">
                From
                <div className="row no-gutters">
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={START_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_DAYS}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_DAYS}/>
                    </div>
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={START_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_HOURS}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_HOURS}/>
                    </div>
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={START_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_MINUTES}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_MINUTES}/>
                    </div>
                </div>
                To
                <div className="row no-gutters">
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={END_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_DAYS}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_DAYS}/>
                    </div>
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={END_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_HOURS}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_HOURS}/>
                    </div>
                    <div className="col">
                        <input type="number"
                               min="0"
                               className="form-control"
                               data-dwell-type={END_DWELL_TYPE}
                               data-dwell-time-unit={DWELL_TIME_UNIT_MINUTES}
                               onChange={this.updateDwellTime}
                               placeholder={DWELL_TIME_UNIT_MINUTES}/>
                    </div>
                </div>
            </div>
        );
    }

    createCheckboxAdditionalHeaderRow(propertyName, columnName) {
        const checkboxId = `${propertyName}-checkbox`;
        return (
            <div className="form-check" key={checkboxId}>
                <input type="checkbox"
                       defaultChecked={!_.isNil(this.state.selectedPropertyNameToColumnNameMap.get(propertyName))}
                       className="form-check-input"
                       id={checkboxId}
                       data-property-name={propertyName}
                       data-column-name={columnName}
                       onClick={this.addOrRemoveAdditionalColumnToMap}/>
                <label className="form-check-label" htmlFor={checkboxId}>{columnName}</label>
            </div>);
    }

    addOrRemoveAdditionalColumnToMap(event) {
        const isChecked = event.target.checked;
        const propertyName = event.target.dataset.propertyName;
        const columnName = event.target.dataset.columnName;
        this.setState(prevState => {
            const oldMap = prevState.selectedPropertyNameToColumnNameMap;
            if (isChecked) {
                oldMap.set(propertyName, columnName);
            } else {
                oldMap.delete(propertyName);
            }
            return new Map(oldMap);
        })
    }

    filterReport() {
        const dwellTimeMillisStart = this.convertDwellTimeToMillis(START_DWELL_TYPE);
        const dwellTimeMillisEnd = this.convertDwellTimeToMillis(END_DWELL_TYPE);
        this.props.applyFilters(this.state.selectedPropertyNameToColumnNameMap, dwellTimeMillisStart, dwellTimeMillisEnd);
        this.props.toggleOptionsPanel();
    }

    updateDwellTime(event) {
        const unitValue = event.target.value;
        const timeUnit = event.target.dataset.dwellTimeUnit;
        const isStart = event.target.dataset.dwellType === START_DWELL_TYPE;

        if (timeUnit === DWELL_TIME_UNIT_DAYS) {
            isStart ? this.setState({dwellTimeDaysStart: unitValue}) : this.setState({dwellTimeDaysEnd: unitValue});
        } else if (timeUnit === DWELL_TIME_UNIT_HOURS) {
            isStart ? this.setState({dwellTimeHoursStart: unitValue}) : this.setState({dwellTimeHoursEnd: unitValue});
        } else if (timeUnit === DWELL_TIME_UNIT_MINUTES) {
            isStart ? this.setState({dwellTimeMinutesStart: unitValue}) : this.setState({dwellTimeMinutesEnd: unitValue});
        }
    }

    convertDwellTimeToMillis(dwellType) {
        let days, hours, minutes;
        if (dwellType === START_DWELL_TYPE) {
            days = this.state.dwellTimeDaysStart;
            hours = this.state.dwellTimeHoursStart;
            minutes = this.state.dwellTimeMinutesStart;
        } else {
            days = this.state.dwellTimeDaysEnd;
            hours = this.state.dwellTimeHoursEnd;
            minutes = this.state.dwellTimeMinutesEnd;
        }
        if (_.isNil(days) && _.isNil(hours) && _.isNil(minutes)) {
            return null;
        }
        const daysInMills = _.parseInt(days || 0) * 24 * 60 * 60 * 1000;
        const hoursInMills = _.parseInt(hours || 0) * 60 * 60 * 1000;
        const minutesInMills = _.parseInt(minutes || 0) * 60 * 1000;
        return daysInMills + hoursInMills + minutesInMills;
    }
}
