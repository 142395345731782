import React, {Component} from 'react';

export default class PageLoadError extends Component {

    render(){
        return(
            <div className="jumbotron text-center text-danger">
                <h4>Uh, oh!</h4>
                <div>There was a bug loading the website page</div>
            </div>
        );
    }
}