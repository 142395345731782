import React, {Component} from 'react';
import _ from 'lodash';

import DataLoading from '../homepage/DataLoading';
import {APIGatewayClientContext} from './APIGatewayClientProvider';
import { isAftxDomain, getAftxWarehouse } from '../util/AftxUtilities';

export const WarehouseIdsContext = React.createContext();

/**
 * Loads the list of Warehouse Ids for the application.
 * This must be loaded before all other components in order to perform URL validation of the warehouse.
 * As such, all children of the component will not load until it has finished loading.
 *
 * You may access the warehouses via the React Context: https://reactjs.org/docs/context.html#classcontexttype
 */
class WarehouseIdsProvider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            warehouseIds: []
        };
    }

    componentDidMount() {
        if (isAftxDomain()) {
            this.setState({warehouseIds: [getAftxWarehouse()], isLoaded: true});
        } else {
            this.retrieveBackendData();
        }
    }

    render() {
        if (!this.state.isLoaded) {
            return <DataLoading/>;
        }
        return (
            <WarehouseIdsContext.Provider value={{warehouseIds: this.state.warehouseIds}}>
                {this.props.children}
            </WarehouseIdsContext.Provider>
        );
    }

    retrieveBackendData() {
        this.context.client.getAllWarehouseIds().then(response => {
            const warehouseIds = response.data.warehouseIds;
            if (!_.isNil(warehouseIds)) {
                this.setState({warehouseIds: warehouseIds.sort(), isLoaded: true});
            }
        });
    }
}

WarehouseIdsProvider.contextType = APIGatewayClientContext;
export default WarehouseIdsProvider;