import _ from 'lodash';
import {BUCKET_SIZES} from './Buckets';
import {VIEW_NAMES_ENUM} from './ViewNames';
import {ACTUALS, FORECASTED} from './WorkPools';

export const DEFAULT_CATEGORY_NAME = "Category One";

export const MAX_DIMENSION_NUMBER = 3;

export const SUMMATION_NAMES_ENUM = {
    TOTAL: "Total",
    RANGE_TOTAL: "Range Total",
    EARLIER_TOTAL: "Earlier Total",
    LATER_TOTAL: "Later Total"
};

export const PROPERTY_NAMES_ENUM = {
    WORK_POOLS: "Work Pools",
    DWELL_TIME: "Dwell Time",
    DWELL_TIME_RANGE: "Dwell Time Range",
    EXPECTED_STOW_DATE_RANGE: "Expected Stow Date Range Option",
    EXPECTED_STOW_DATE: "Expected Stow Date",
    TRAILER_DOCKING_TIME_RANGE: "Trailer Docking Time Range Option",
    TRAILER_DOCKING_TIME: "Trailer Docking Time",
    BUCKET_SIZE: "Bucket Size",
    REACTIVE_TRANSFER: "Reactive Transfer",
    SCANNABLE_ID: "Scannable Id",
    SOURCE_WAREHOUSE_IDS: "Source Warehouse Ids",
    FC_SKU: "FC Sku",
    FN_SKU: "FN Sku",
    ISA_ID: "ISA Id",
    ISD_ID: "ISD Id",
    PROCESS_PATHS: "Process Paths",
    OUTER_CONTAINER_ID: "Outer Container Id",
    OUTER_CONTAINER_TYPES: "Outer Container Types",
    OUTER_SCANNABLE_ID: "Outer Scannable Id",
    OUTER_OUTER_CONTAINER_TYPES: "Outer Outer Container Types",
    OUTER_OUTER_SCANNABLE_ID: "Outer Outer Scannable Id",
    FLOOR: "Floor",
    MOD: "Mod",
    CONTAINER_ID: "Container Id",
    CONTAINER_SIZES: "Container Sizes",
    CONTAINER_TYPES: "Container Types",
    SHIPMENT_TYPE: "Shipment Type",
    STATUS: "Status",
    VR_ID: "VRID"
};

export const AGGREGATE_DIMENSION_VALUE_ENUM = {
    WORK_POOL: 'WorkPoolDimension',
    CONTAINER_SIZE: 'ContainerSizeDimension',
    CONTAINER_TYPE: 'ContainerTypeDimension',
    OUTER_CONTAINER_TYPE: 'OuterContainerTypeDimension',
    OUTER_SCANNABLE_ID: 'OuterScannableIdDimension',
    OUTER_OUTER_CONTAINER_TYPE: 'OuterOuterContainerTypeDimension',
    OUTER_OUTER_SCANNABLE_ID: 'OuterOuterScannableIdDimension',
    FLOOR: 'FloorDimension',
    MOD: 'ModDimension',
    PROCESS_PATH: 'ProcessPathDimension',
    SOURCE_WAREHOUSE_ID: 'SourceWarehouseIdDimension',
    WORK_POOL_LAST_CHANGED_TIME: 'WorkPoolLastChangedTimeDimension',
    TRAILER_DOCKING_TIME: 'TrailerDockingTimeDimension',
    EXPECTED_STOW_DATE: 'ExpectedStowDateDimension',
    ISA_ID: 'ISAIdDimension',
    VR_ID: 'VRIdDimension'
};

export const DEFAULT_AXIS_VALUES_ENUM = {
    Y_DEFAULT: AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL,
    Z_DEFAULT: undefined
};

export const VIEW_NAME_TO_X_AXIS_DIMENSION_MAP = new Map([
    [VIEW_NAMES_ENUM.PRE_RECEIVE, AGGREGATE_DIMENSION_VALUE_ENUM.TRAILER_DOCKING_TIME],
    [VIEW_NAMES_ENUM.POST_RECEIVE, AGGREGATE_DIMENSION_VALUE_ENUM.EXPECTED_STOW_DATE],
    [VIEW_NAMES_ENUM.DWELL_TIME, AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL_LAST_CHANGED_TIME]
]);

export const VIEW_NAME_TO_X_AXIS_PROPERTY_NAME_MAP = new Map([
    [VIEW_NAMES_ENUM.PRE_RECEIVE, PROPERTY_NAMES_ENUM.TRAILER_DOCKING_TIME],
    [VIEW_NAMES_ENUM.POST_RECEIVE, PROPERTY_NAMES_ENUM.EXPECTED_STOW_DATE],
    [VIEW_NAMES_ENUM.DWELL_TIME, PROPERTY_NAMES_ENUM.DWELL_TIME]
]);

export const AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP = new Map([
    [AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL, PROPERTY_NAMES_ENUM.WORK_POOLS],
    [AGGREGATE_DIMENSION_VALUE_ENUM.CONTAINER_SIZE, PROPERTY_NAMES_ENUM.CONTAINER_SIZES],
    [AGGREGATE_DIMENSION_VALUE_ENUM.CONTAINER_TYPE, PROPERTY_NAMES_ENUM.CONTAINER_TYPES],
    [AGGREGATE_DIMENSION_VALUE_ENUM.OUTER_CONTAINER_TYPE, PROPERTY_NAMES_ENUM.OUTER_CONTAINER_TYPES],
    [AGGREGATE_DIMENSION_VALUE_ENUM.OUTER_SCANNABLE_ID, PROPERTY_NAMES_ENUM.OUTER_SCANNABLE_ID],
    [AGGREGATE_DIMENSION_VALUE_ENUM.OUTER_OUTER_CONTAINER_TYPE, PROPERTY_NAMES_ENUM.OUTER_OUTER_CONTAINER_TYPES],
    [AGGREGATE_DIMENSION_VALUE_ENUM.OUTER_OUTER_SCANNABLE_ID, PROPERTY_NAMES_ENUM.OUTER_OUTER_SCANNABLE_ID],
    [AGGREGATE_DIMENSION_VALUE_ENUM.FLOOR, PROPERTY_NAMES_ENUM.FLOOR],
    [AGGREGATE_DIMENSION_VALUE_ENUM.MOD, PROPERTY_NAMES_ENUM.MOD],
    [AGGREGATE_DIMENSION_VALUE_ENUM.PROCESS_PATH, PROPERTY_NAMES_ENUM.PROCESS_PATHS],
    [AGGREGATE_DIMENSION_VALUE_ENUM.SOURCE_WAREHOUSE_ID, PROPERTY_NAMES_ENUM.SOURCE_WAREHOUSE_IDS],
    [AGGREGATE_DIMENSION_VALUE_ENUM.ISA_ID, PROPERTY_NAMES_ENUM.ISA_ID],
    [AGGREGATE_DIMENSION_VALUE_ENUM.VR_ID, PROPERTY_NAMES_ENUM.VR_ID]]);

export const REACTIVE_TRANSFER_VALUE_ENUM = {
    ALL: "",
    YES: true,
    NO: false
};

const BUCKET_SIZE_VALUE_TO_NAME_MAP = new Map([
    [BUCKET_SIZES.FIFTEEN_MINUTES, '15 Min'],
    [BUCKET_SIZES.THIRTY_MINUTES, '30 Min'],
    [BUCKET_SIZES.ONE_HOUR, '60 Min']
]);

export const REACTIVE_TRANSFER_VALUE_TO_NAME_MAP = new Map([
    [REACTIVE_TRANSFER_VALUE_ENUM.ALL, 'All'],
    [REACTIVE_TRANSFER_VALUE_ENUM.YES, 'Yes'],
    [REACTIVE_TRANSFER_VALUE_ENUM.NO, 'No']
]);

export const SHIPMENT_TYPES_ENUM = {
    ALL: 'ALL',
    TRANSSHIPMENT: 'TRANSSHIPMENT',
    VENDOR_RECEIVE: 'VENDOR_RECEIVE'
};

export const AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM = {
    ALL: 'All',
    TRANSSHIPMENT: 'Transshipment',
    REACTIVE_TRANSSHIPMENT_ONLY: 'Reactive Transshipment Only',
    VENDOR_RECEIVE: 'Vendor Receive'
};

export const SHIPMENT_TYPE_VALUE_TO_NAME_MAP = new Map([
    [SHIPMENT_TYPES_ENUM.ALL, 'All'],
    [SHIPMENT_TYPES_ENUM.TRANSSHIPMENT, 'Transshipment'],
    [SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE, 'Vendor Receive']
]);

export const AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_TO_TYPE_MAP = new Map([
    [AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM.ALL, SHIPMENT_TYPES_ENUM.ALL,],
    [AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM.TRANSSHIPMENT, SHIPMENT_TYPES_ENUM.TRANSSHIPMENT],
    [AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM.REACTIVE_TRANSSHIPMENT_ONLY, SHIPMENT_TYPES_ENUM.TRANSSHIPMENT],
    [AGGREGATE_OPTION_ONLY_SHIPMENT_LABEL_NAME_ENUM.VENDOR_RECEIVE, SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE]
]);

export const FORECASTED_SHIPMENT_TYPES_ENUM = {
    FORECASTED_TRANSSHIPMENT: 'FORECASTED_TRANSSHIPMENT',
    FORECASTED_VENDOR_RECEIVE: 'FORECASTED_VENDOR_RECEIVE'
};

export const UNIFIED_SHIPMENT_TYPES_MAP = new Map([
    ['FORECASTED_TRANSSHIPMENT', 'TRANSSHIPMENT'],
    ['FORECASTED_VENDOR_RECEIVE', 'VENDOR_RECEIVE'],
    ['TRANSSHIPMENT', 'TRANSSHIPMENT'],
    ['VENDOR_RECEIVE', 'VENDOR_RECEIVE'],
]);

export const ACTUAL_TO_FORECASTED_SHIPMENT_TYPE_MAP = new Map([
    [SHIPMENT_TYPES_ENUM.TRANSSHIPMENT, FORECASTED_SHIPMENT_TYPES_ENUM.FORECASTED_TRANSSHIPMENT],
    [SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE, FORECASTED_SHIPMENT_TYPES_ENUM.FORECASTED_VENDOR_RECEIVE]
]);

export const NON_POST_RECEIVE_STATUS_ENUM = {
    ACTUALS: ACTUALS,
    FORECASTED: FORECASTED
};

export const POST_RECEIVE_STATUS_ENUM = {
    ACTUALS: ACTUALS,
};

export const PRE_RECEIVE_STATUS_VALUE_TO_LABEL_MAP = new Map(_.values(NON_POST_RECEIVE_STATUS_ENUM).map((statusName) => [_.replace(statusName, / /g, ""), statusName]));
export const POST_RECEIVE_STATUS_VALUE_TO_LABEL_MAP = new Map(_.values(POST_RECEIVE_STATUS_ENUM).map((statusName) => [_.replace(statusName, / /g, ""), statusName]));
export const DWELL_STATUS_VALUE_TO_LABEL_MAP = new Map(_.values(NON_POST_RECEIVE_STATUS_ENUM).map((statusName) => [_.replace(statusName, / /g, ""), statusName]));

export const VIEW_NAME_TO_STATUS_NAME_MAP = new Map([
    [VIEW_NAMES_ENUM.PRE_RECEIVE, PRE_RECEIVE_STATUS_VALUE_TO_LABEL_MAP],
    [VIEW_NAMES_ENUM.POST_RECEIVE, POST_RECEIVE_STATUS_VALUE_TO_LABEL_MAP],
    [VIEW_NAMES_ENUM.DWELL_TIME, DWELL_STATUS_VALUE_TO_LABEL_MAP]
]);

export const DATE_RANGE_ENUM = {
    ALL: "ALL",
    TODAY: "TODAY",
    NEXT_3_DAYS: "NEXT_3_DAYS",
    PLUS_MINUS_ONE_DAY: "PLUS_MINUS_ONE_DAY",
    PLUS_MINUS_THREE_DAYS: "PLUS_MINUS_THREE_DAYS",
    PLUS_MINUS_SEVEN_DAYS: "PLUS_MINUS_SEVEN_DAYS"
};

const EXPECTED_STOW_DATE_RANGE_VALUE_TO_NAME_MAP = new Map([
    [DATE_RANGE_ENUM.ALL, 'All'],
    [DATE_RANGE_ENUM.TODAY, 'Today'],
    [DATE_RANGE_ENUM.NEXT_3_DAYS, 'Next 3 days'],
    [DATE_RANGE_ENUM.PLUS_MINUS_ONE_DAY, '\xB1 1 day'],
    [DATE_RANGE_ENUM.PLUS_MINUS_THREE_DAYS, '\xB1 3 days'],
    [DATE_RANGE_ENUM.PLUS_MINUS_SEVEN_DAYS, '\xB1 7 days']
]);

const TRAILER_DOCKING_TIME_RANGE_VALUE_TO_NAME_MAP = new Map(EXPECTED_STOW_DATE_RANGE_VALUE_TO_NAME_MAP);

const PROPERTY_NAME_TO_VALUES_MAP = new Map([
    [PROPERTY_NAMES_ENUM.EXPECTED_STOW_DATE_RANGE, EXPECTED_STOW_DATE_RANGE_VALUE_TO_NAME_MAP],
    [PROPERTY_NAMES_ENUM.TRAILER_DOCKING_TIME_RANGE, TRAILER_DOCKING_TIME_RANGE_VALUE_TO_NAME_MAP],
    [PROPERTY_NAMES_ENUM.BUCKET_SIZE, BUCKET_SIZE_VALUE_TO_NAME_MAP],
    [PROPERTY_NAMES_ENUM.REACTIVE_TRANSFER, REACTIVE_TRANSFER_VALUE_TO_NAME_MAP],
    [PROPERTY_NAMES_ENUM.SHIPMENT_TYPE, SHIPMENT_TYPE_VALUE_TO_NAME_MAP]
]);

const VIEW_NAME_TO_RANGE_NAME_MAP = new Map([
    [VIEW_NAMES_ENUM.POST_RECEIVE, PROPERTY_NAMES_ENUM.EXPECTED_STOW_DATE_RANGE],
    [VIEW_NAMES_ENUM.PRE_RECEIVE, PROPERTY_NAMES_ENUM.TRAILER_DOCKING_TIME_RANGE],
    [VIEW_NAMES_ENUM.DWELL_TIME, PROPERTY_NAMES_ENUM.DWELL_TIME_RANGE]
]);

export const FORECAST_DISABLED_DIMENSIONS = [AGGREGATE_DIMENSION_VALUE_ENUM.OUTER_CONTAINER_TYPE,AGGREGATE_DIMENSION_VALUE_ENUM.OUTER_OUTER_CONTAINER_TYPE,AGGREGATE_DIMENSION_VALUE_ENUM.FLOOR,AGGREGATE_DIMENSION_VALUE_ENUM.MOD,AGGREGATE_DIMENSION_VALUE_ENUM.OUTER_SCANNABLE_ID,AGGREGATE_DIMENSION_VALUE_ENUM.OUTER_OUTER_SCANNABLE_ID];
export const FORECAST_ENABLED_DIMENSIONS = [AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL,AGGREGATE_DIMENSION_VALUE_ENUM.CONTAINER_SIZE,AGGREGATE_DIMENSION_VALUE_ENUM.CONTAINER_TYPE,AGGREGATE_DIMENSION_VALUE_ENUM.PROCESS_PATH,AGGREGATE_DIMENSION_VALUE_ENUM.ISA_ID,AGGREGATE_DIMENSION_VALUE_ENUM.VR_ID,AGGREGATE_DIMENSION_VALUE_ENUM.SOURCE_WAREHOUSE_ID,DEFAULT_AXIS_VALUES_ENUM.Z_DEFAULT];
export class Dimensions {

    static getRangeValuesToNamesMapByViewName(viewName) {
        return Dimensions.getValueToNameMapByPropertyName(VIEW_NAME_TO_RANGE_NAME_MAP.get(viewName));
    }

    static getRangeNameByViewName(viewName) {
        return VIEW_NAME_TO_RANGE_NAME_MAP.get(viewName);
    }

    static getValueToNameMapByPropertyName(propertyName) {
        return PROPERTY_NAME_TO_VALUES_MAP.get(propertyName);
    }
}
