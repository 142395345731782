export default class IsItDown {

    //Is-it-down onboarding: https://w.amazon.com/index.php/IsItDown/UserGuide-->
    static load() {
        const jqueryScript = document.createElement('script');
        jqueryScript.async = false;
        jqueryScript.src="https://m.media-amazon.com/images/G/01/oneg/assets/3.2.6/js/jquery.min.js";
        const isItDownBannerScript = document.createElement('script');
        isItDownBannerScript.async = false;
        isItDownBannerScript.src = "https://is-it-down.amazon.com/ibrodeowebsite/components/InboundRodeoWebsite/stripe.js";
        document.body.appendChild(jqueryScript);
        document.body.appendChild(isItDownBannerScript);
    }
}