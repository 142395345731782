import _ from 'lodash';

import {VIEW_NAMES_ENUM} from './ViewNames';

export const ACTUALS = "Actuals";
export const FORECASTED = "Forecasted";

export const PRE_RECEIVE_WORKPOOLS_ENUM = {
    NOT_YET_ARRIVED: "Not Yet Arrived",
    NOT_YET_ARRIVED_ACTUALS: "Not Yet Arrived " + ACTUALS,
    NOT_YET_ARRIVED_FORECASTED: "Not Yet Arrived " + FORECASTED,
    IN_YARD: "In Yard",
    IN_YARD_ACTUALS: "In Yard " + ACTUALS,
    IN_YARD_FORECASTED: "In Yard " + FORECASTED,
    PRE_RECEIVE_DEFECT: "Pre Receive Defect",
    PRE_RECEIVE_DEFECT_ACTUALS: "Pre Receive Defect " + ACTUALS,
    PRE_RECEIVE_DEFECT_FORECASTED: "Pre Receive Defect " + FORECASTED,
    NOT_YET_RECEIVED: "Not Yet Received",
    NOT_YET_RECEIVED_ACTUALS: "Not Yet Received " + ACTUALS,
    NOT_YET_RECEIVED_FORECASTED: "Not Yet Received " + FORECASTED,
    UNLOADED: "Unloaded",
    UNLOADED_ACTUALS: "Unloaded " + ACTUALS,
    UNLOADED_FORECASTED: "Unloaded " + FORECASTED
};

export const POST_RECEIVE_WORKPOOLS_ENUM = {
    TRANS_RECEIVED: "Trans Received",
    UNLOADED: "Unloaded",
    VENDOR_RECEIVED: "Vendor Received",
    UNLOADED_TO_CONVEYANCE: "Unloaded To Conveyance",
    DECANTED: "Decanted",
    PENDING_STOW: "Pending Stow",
    STOWED: "Stowed"
};

export const TOP_PRE_RECEIVE_WORKPOOLS_ENUM = {
    NOT_YET_ARRIVED: "NotYetArrived",
    IN_YARD: "InYard",
    PRE_RECEIVE_DEFECT: "PreReceiveDefect",
    NOT_YET_RECEIVED: "NotYetReceived",
    UNLOADED: "Unloaded"
};

export const ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM = {
    NOT_YET_ARRIVED_ACTUALS: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_ARRIVED + ACTUALS,
    IN_YARD_ACTUALS: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.IN_YARD + ACTUALS,
    PRE_RECEIVE_DEFECT_ACTUALS: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.PRE_RECEIVE_DEFECT + ACTUALS,
    NOT_YET_RECEIVED_ACTUALS: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_RECEIVED + ACTUALS,
    UNLOADED_ACTUALS: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.UNLOADED + ACTUALS
};

export const FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM = {
    NOT_YET_ARRIVED_FORECASTED: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_ARRIVED + FORECASTED,
    IN_YARD_FORECASTED: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.IN_YARD + FORECASTED,
    PRE_RECEIVE_DEFECT_FORECASTED: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.PRE_RECEIVE_DEFECT + FORECASTED,
    NOT_YET_RECEIVED_FORECASTED: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_RECEIVED + FORECASTED,
    UNLOADED_FORECASTED: TOP_PRE_RECEIVE_WORKPOOLS_ENUM.UNLOADED + FORECASTED
};

export const TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP = {
    [TOP_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_ARRIVED]: [ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_ARRIVED_ACTUALS,FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_ARRIVED_FORECASTED],
    [TOP_PRE_RECEIVE_WORKPOOLS_ENUM.IN_YARD]: [ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM.IN_YARD_ACTUALS,FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM.IN_YARD_FORECASTED],
    [TOP_PRE_RECEIVE_WORKPOOLS_ENUM.PRE_RECEIVE_DEFECT]: [ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM.PRE_RECEIVE_DEFECT_ACTUALS,FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM.PRE_RECEIVE_DEFECT_FORECASTED],
    [TOP_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_RECEIVED]: [ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_RECEIVED_ACTUALS,FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM.NOT_YET_RECEIVED_FORECASTED],
    [TOP_PRE_RECEIVE_WORKPOOLS_ENUM.UNLOADED]: [ACTUALS_PRE_RECEIVE_WORKPOOLS_ENUM.UNLOADED_ACTUALS,FORECASTED_PRE_RECEIVE_WORKPOOLS_ENUM.UNLOADED_FORECASTED]
};

export const DWELL_TIME_WORKPOOLS_ENUM = {...PRE_RECEIVE_WORKPOOLS_ENUM, ...POST_RECEIVE_WORKPOOLS_ENUM};

export const PRE_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP = new Map(_.values(PRE_RECEIVE_WORKPOOLS_ENUM).map((workPoolName) => [_.replace(workPoolName, / /g, ""), workPoolName]));
export const POST_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP = new Map(_.values(POST_RECEIVE_WORKPOOLS_ENUM).map((workPoolName) => [_.replace(workPoolName, / /g, ""), workPoolName]));
export const DWELL_TIME_WORKPOOLS_VALUE_TO_LABEL_MAP = new Map(_.values(DWELL_TIME_WORKPOOLS_ENUM).map((workPoolName) => [_.replace(workPoolName, / /g, ""), workPoolName]));

export const DWELL_TIME_WORKPOOLS_WITHOUT_SUB_WORKPOOLS_ENUM = {...TOP_PRE_RECEIVE_WORKPOOLS_ENUM, ...Array.from(POST_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP.keys())};

export const VIEW_NAME_TO_WORK_POOL_NAME_MAP = new Map([
    [VIEW_NAMES_ENUM.PRE_RECEIVE, PRE_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP],
    [VIEW_NAMES_ENUM.POST_RECEIVE, POST_RECEIVE_WORKPOOLS_VALUE_TO_LABEL_MAP],
    [VIEW_NAMES_ENUM.DWELL_TIME, DWELL_TIME_WORKPOOLS_VALUE_TO_LABEL_MAP]
]);

export const VIEW_NAME_TO_WORK_POOL_NAME_MAP_WITHOUT_FORECAST = new Map([
    [VIEW_NAMES_ENUM.PRE_RECEIVE, new Map(_.values(TOP_PRE_RECEIVE_WORKPOOLS_ENUM).map((workPoolName) => [workPoolName, workPoolName]))],
    [VIEW_NAMES_ENUM.POST_RECEIVE, new Map(_.values(POST_RECEIVE_WORKPOOLS_ENUM).map((workPoolName) => [_.replace(workPoolName, / /g, ""), workPoolName]))],
    [VIEW_NAMES_ENUM.DWELL_TIME, new Map(_.values(DWELL_TIME_WORKPOOLS_WITHOUT_SUB_WORKPOOLS_ENUM).map((workPoolName) => [workPoolName, workPoolName]))]
]);
