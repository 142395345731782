import React, {Component} from 'react';

import ReceiveTabs from '../header/ReceiveTabs';
import PageLoadError from '../error/PageLoadError';

export default class AggregateView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isJavaScriptError: false
        }
    }

    componentDidCatch(error) {
        this.setState({isJavaScriptError: true});
    }

    render() {
        if (this.state.isJavaScriptError) {
            return <PageLoadError/>
        }
        return <ReceiveTabs/>;
    }
}