import React, {Component} from 'react';
import _ from 'lodash';
import {TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP} from '../pojos/WorkPools';
import '../../../css/aggregate-view.scss';

class ExpandRowButton extends Component {

    constructor(props) {
        super(props);
        this.handleClickEvent = this.handleClickEvent.bind(this);
    }

    render() {
        return <span id={`${this.props.rowHeaderValue}-header-expand-button`}
                     className="pl-1 pr-1 clickable"
                     onClick={this.handleClickEvent}>
            {this.props.isOpened
            ? <i data-testid={`open-${this.props.rowHeaderValue}`} className="fa fa-chevron-down"/>
            : <i data-testid={`closed-${this.props.rowHeaderValue}`} className="fa fa-chevron-right"/>}
        </span>;
    }

    handleClickEvent() {
        const subWorkPools = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[this.props.rowHeaderValue]
        if (!this.props.isOpened) {
            const updatedExtendedRows = [...this.props.visibleRows, ...subWorkPools]
            this.props.setExtendedRows(updatedExtendedRows);
        } else {
            const currentVisibleRows = [...this.props.visibleRows];
            subWorkPools.forEach(eachItem => _.remove(currentVisibleRows, n => n === eachItem))
            this.props.setExtendedRows(currentVisibleRows)
        }
    }
}

export {ExpandRowButton};
