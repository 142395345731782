import _ from 'lodash';

export default class AggregateReportRequestInput {

    constructor(builder) {
        this.warehouseId = builder.warehouseId;
        this.shipmentTypes = builder.shipmentTypes;
        this.filterList = builder.filterList;
        this.aggregateDimensionList = builder.aggregateDimensionList;
    }

    static get Builder() {

        class Builder {
            withWarehouseId(warehouseId) {
                this.warehouseId = warehouseId;
                return this;
            }

            withShipmentTypes(shipmentTypes) {
                this.shipmentTypes = _.isArray(shipmentTypes) ? shipmentTypes : [shipmentTypes];
                return this;
            }

            withFilterList(filterList) {
                this.filterList = filterList;
                return this;
            }

            withAggregateDimensionList(aggregateDimensionList) {
                this.aggregateDimensionList = aggregateDimensionList;
                return this;
            }

            build() {
                return new AggregateReportRequestInput(this);
            }

        }

        return Builder;
    }


}