export const CONTAINER_ID_QUERY_STRING_KEY = "containerId";
export const CONTAINER_SIZES_QUERY_STRING_KEY = "containerSizes";
export const CONTAINER_TYPES_QUERY_STRING_KEY = "containerTypes";
export const EXPECTED_STOW_DATE_QUERY_STRING_KEY = "expectedStowDate";
export const DWELL_TIME_MILLIS_QUERY_STRING_KEY = "dwellTimeMillis";
export const FC_SKU_QUERY_STRING_KEY = "fcSku";
export const FN_SKU_QUERY_STRING_KEY = "fnSku";
export const ISA_ID_QUERY_STRING_KEY = "isaId";
export const ISD_ID_QUERY_STRING_KEY = "isdId";
export const IS_REACTIVE_QUERY_STRING_KEY = "isReactive";
export const OUTER_CONTAINER_ID_QUERY_STRING_KEY = "outerContainerId";
export const OUTER_CONTAINER_TYPES_QUERY_STRING_KEY = "outerContainerTypes";
export const OUTER_SCANNABLE_ID_QUERY_STRING_KEY = "outerScannableId";
export const OUTER_OUTER_SCANNABLE_ID_QUERY_STRING_KEY = "outerOuterScannableId";
export const FLOOR_QUERY_STRING_KEY = "floor";
export const MOD_QUERY_STRING_KEY = "mod";
export const PROCESS_PATHS_QUERY_STRING_KEY = "processPaths";
export const SCANNABLE_ID_QUERY_STRING_KEY = "scannableId";
export const SOURCE_WAREHOUSE_IDS_QUERY_STRING_KEY = "sourceWarehouseIds";
export const STATUS_QUERY_STRING_KEY = "status";
export const TRAILER_DOCKING_TIME_QUERY_STRING_KEY = "trailerDockingTime";
export const VR_ID_QUERY_STRING_KEY = "vrId";
export const WORK_POOLS_QUERY_STRING_KEY = "workPools";
