import React, {PureComponent} from 'react';
import {Pagination} from 'react-bootstrap';
import _ from 'lodash';

const MAX_PAGINATION_IN_VIEW = 6;

export default class ItemListPagination extends PureComponent {

    render() {
        const paginationItems = this.createPaginationItems();
        const isEmptyPagination = _.isEmpty(paginationItems);

        return (
            <Pagination className="justify-content-end item-list-pagination">
                {!isEmptyPagination && <Pagination.Prev disabled={this.props.activePageNum === 1}
                                                        data-pagination-number={this.props.activePageNum - 1}
                                                        onClick={this.props.setActivePaginationNumber}/>}
                {isEmptyPagination && <Pagination.Item disabled>{0}</Pagination.Item>}
                {paginationItems}
                {!isEmptyPagination && <Pagination.Next disabled={this.props.activePageNum === paginationItems.length}
                                                        data-pagination-number={this.props.activePageNum + 1}
                                                        onClick={this.props.setActivePaginationNumber}/>}
            </Pagination>)
    }

    createPaginationItems() {
        const {itemCount, defaultPageSize, activePageNum} = this.props;
        const numPaginationItems = _.ceil(itemCount / defaultPageSize);
        const paginationItems = [];
        if (numPaginationItems > MAX_PAGINATION_IN_VIEW) {
            const paginationBufferOnEitherSideOfActive = MAX_PAGINATION_IN_VIEW / 2;
            const startToActiveDiff = activePageNum - paginationBufferOnEitherSideOfActive;
            const endToActiveDiff = activePageNum + paginationBufferOnEitherSideOfActive;
            const startPageNum = startToActiveDiff < 1 ? 1 : startToActiveDiff;
            const endPageNum = endToActiveDiff > numPaginationItems ? numPaginationItems : endToActiveDiff;
            if (startPageNum > 1) {
                paginationItems.push(this.createPaginationItem(1, activePageNum));
                paginationItems.push(this.createPaginationEllipsis('start'));
            }
            for (let pageNum = startPageNum; pageNum <= endPageNum; pageNum++) {
                paginationItems.push(this.createPaginationItem(pageNum, activePageNum));
            }
            if (endPageNum < numPaginationItems) {
                paginationItems.push(this.createPaginationEllipsis('end'));
                paginationItems.push(this.createPaginationItem(numPaginationItems, activePageNum));
            }
        } else {
            for (let pageNum = 1; pageNum <= numPaginationItems; pageNum++) {
                paginationItems.push(this.createPaginationItem(pageNum, activePageNum));
            }
        }

        return paginationItems;
    }

    createPaginationItem(paginationNumber, currentlyActivePaginationNumber) {
        return(
            <Pagination.Item key={paginationNumber}
                             active={currentlyActivePaginationNumber === paginationNumber}
                             data-pagination-number={paginationNumber}
                             onClick={this.props.setActivePaginationNumber}>
                {paginationNumber}
            </Pagination.Item>
        );
    }

    createPaginationEllipsis(key) {
        return <Pagination.Ellipsis key={key} disabled/>;
    }
}
