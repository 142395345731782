import React, {Component} from 'react';
import _ from 'lodash';
import axios from 'axios';

import BONESFederateClientBuilder from '@amzn/inbound-rodeo-website-federate-identity-java-script-client';
import * as aftxconfig from '../../aftx/config';
import AftxHttpClient from '../../aftx/AftxHttpClient';
import DataLoading from '../homepage/DataLoading';

export const APIGatewayClientContext = React.createContext();

/**
 * A creator for the BONESJavaScriptClient which exchanges Midway Credentials for an APIGateway Role.
 * This object should define all fetch calls for the application and be reused for all calls.
 *
 * This provider must be loaded before all other components in the application before it can be used.
 * You may access this client via the React Context: https://reactjs.org/docs/context.html#classcontexttype
 */
export default class APIGatewayClientProvider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false
        };

        this.clientLoaded = this.clientLoaded.bind(this);

        if (aftxconfig.ServedFromAftxDomain) {
            const aftxHttpClient = new AftxHttpClient();
            aftxHttpClient.init(this.clientLoaded);
        } else {
            new BONESFederateClientBuilder({onClientCreated: this.clientLoaded});
        } 
    }

    render() {
        if (!this.state.isLoaded) {
            return <DataLoading/>;
        }
        return (
            <APIGatewayClientContext.Provider value={{client: this}}>
                {this.props.children}
            </APIGatewayClientContext.Provider>
        );
    }

    clientLoaded(client) {
        this.client = client;
        this.setState({isLoaded: true});
    }

    getAllWarehouseIds() {
        return this.client.get('getAllWarehouseIds');
    }

    getTimezoneForWarehouseId(warehouseId, cancelToken) {
        const params = {params: {warehouseId}};
        return this.client.get('getTimeZoneByWarehouseId', {...params, ...this.addCancelTokenParams(cancelToken)});
    }

    getRealm(cancelToken) {
        return this.client.get('getRealm', this.addCancelTokenParams(cancelToken));
    }

    getIdentity() {
        return this.client.get('identity');
    }

    getTransshipmentItemList(itemListRequestInput, cancelToken) {
        return this.client.post('getTransshipmentItemList', itemListRequestInput, this.addCancelTokenParams(cancelToken));
    }

    getVendorReceiveItemList(itemListRequestInput, cancelToken) {
        return this.client.post('getVendorReceiveItemList', itemListRequestInput, this.addCancelTokenParams(cancelToken));
    }

    getForecastedTransshipmentItemList(itemListRequestInput, cancelToken) {
        return this.client.post('getForecastedTransshipmentItemList', itemListRequestInput, this.addCancelTokenParams(cancelToken));
    }

    getForecastedVendorReceiveItemList(itemListRequestInput, cancelToken) {
        return this.client.post('getForecastedVendorReceiveItemList', itemListRequestInput, this.addCancelTokenParams(cancelToken));
    }

    getSearchResults(searchRequestInput, cancelToken) {
        return this.client.post('getSearchResults', searchRequestInput, this.addCancelTokenParams(cancelToken));
    }

    getAggregateReport(aggregateReportRequestInput, cancelToken) {
        return this.client.post('getAggregateReport', aggregateReportRequestInput, this.addCancelTokenParams(cancelToken));
    }

    addCancelTokenParams(cancelToken) {
        const extraParams = {};
        if (!_.isNil(cancelToken)) {
            extraParams.cancelToken = cancelToken;
        }
        return extraParams;
    }

    static createNewCancelTokenSource() {
        return axios.CancelToken.source();
    }

    static parseError(error) {
        let errorMessage;
        if (error.response) {
            errorMessage = error.response.data.message;
        } else {
            errorMessage = error;
        }
        return errorMessage;
    }
}
