import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';

import AppRouter from '../router/AppRouter';

import '../../../css/warehouse-autosuggest.scss';

class WarehouseIdAutoCompleter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            warehouseId: this.determineCurrentWarehouse(),
            warehouseSuggestions: []
        };

        this.updateSelectedWarehouse = this.updateSelectedWarehouse.bind(this);
        this.updateWarehouseInputValue = this.updateWarehouseInputValue.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.resetWarehouseId = this.resetWarehouseId.bind(this);
        this.highlightOnFocus = this.highlightOnFocus.bind(this);
    }

    render() {
        const inputProps = {
            placeholder: "FC",
            className: "form-control form-control-sm text-center warehouse-autosuggest",
            value: this.state.warehouseId,
            onChange: this.updateWarehouseInputValue,
            onFocus: this.highlightOnFocus,
            onBlur: this.resetWarehouseId
        };
        return (
            <Autosuggest
                suggestions={this.state.warehouseSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                shouldRenderSuggestions={this.shouldRenderSuggestions}
                focusInputOnSuggestionClick={false}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                onSuggestionSelected={this.updateSelectedWarehouse}/>
        );
    }

    onSuggestionsFetchRequested(warehouseInput) {
        this.setState({warehouseSuggestions: this.getSuggestions(warehouseInput.value)});
    };

    onSuggestionsClearRequested() {
        this.setState({warehouseSuggestions: []});
    };

    getSuggestions(warehouseInput) {
        const escapedValue = WarehouseIdAutoCompleter.escapeRegexCharacters(warehouseInput.trim());

        const validWarehouses = this.props.getValidWarehouses();
        if (_.isEmpty(escapedValue)) {
            return validWarehouses;
        }

        const regex = new RegExp(escapedValue, 'i');
        return validWarehouses.filter(warehouseId => regex.test(warehouseId));
    }

    getSuggestionValue(warehouseInput) {
        return warehouseInput;
    }

    renderSuggestion(warehouseId) {
        return (
            <div className="text-center clickable">{warehouseId}</div>
        );
    }

    /**
     * By default, suggestions are rendered when the input isn't blank.
     This function gets the current value of the input, and it should return a boolean.
     By overriding this to always true, we will always show all suggestions
     @return boolean should we render suggestions
     */
    shouldRenderSuggestions() {
        return true;
    }

    updateWarehouseInputValue(event, {newValue}) {
        this.setState({warehouseId: newValue});
    }

    resetWarehouseId() {
        this.setState({warehouseId: this.determineCurrentWarehouse()})
    }

    highlightOnFocus(event) {
        event.target.select();
    }

    updateSelectedWarehouse(event, {suggestion, suggestionValue}) {
        const validWarehouseIds = this.props.getValidWarehouses();
        const currentWarehouseId = this.determineCurrentWarehouse();
        const newWarehouseId = suggestionValue;
        if (currentWarehouseId !== newWarehouseId && validWarehouseIds.includes(newWarehouseId)) {
            this.props.history.push(AppRouter.generateNewUrl(newWarehouseId));
            event.target.blur();
        }
    }

    determineCurrentWarehouse() {
        return this.props.getCurrentWarehouseIdFromUrl() || '';
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
    static escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
}

export default withRouter(WarehouseIdAutoCompleter);