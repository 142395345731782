import _ from 'lodash';

const NOTIFICATION_TYPES = Object.freeze({
    SUCCESS: 'success', WARN: 'warning', ERROR: 'danger', INFO: 'info'
});
const MAX_MESSAGE_LENGTH = 2000;

export default class Notification {

    constructor(builder) {
        this.id = builder.id;
        this.message = builder.message;
        this.type = builder.type;
        this.timeoutSeconds = builder.timeoutSeconds;
        this.isDismissible = _.isNil(builder.isDismissible) ? true : builder.isDismissible;
    }

    static get Builder() {
        class Builder {

            constructor(id) {
                this.id = id;
            }

            withMessage(message) {
                this.message = _.truncate(message, {length: MAX_MESSAGE_LENGTH});
                return this;
            }

            withType(type) {
                this.type = type;
                return this;
            }

            withTimeoutSeconds(timeoutSeconds) {
                this.timeoutSeconds = timeoutSeconds;
                return this;
            }

            withDismissible(isDismissible) {
                this.isDismissible = isDismissible;
                return this;
            }

            build() {
                return new Notification(this);
            }
        }

        return Builder;
    }

    static get TYPES() {
        return NOTIFICATION_TYPES;
    }
}