import _ from 'lodash';

export default class SearchRequestInput {

    constructor(builder) {
        this.batchSize = builder.batchSize;
        this.rodeoFilters = builder.rodeoFilters;
        this.searchQuery = builder.searchQuery;
        this.shipmentTypes = builder.shipmentTypes;
        this.startIndex = builder.startIndex;
        this.warehouseId = builder.warehouseId;
    }

    static get Builder() {
        class Builder {

            withWarehouseId(warehouseId) {
                this.warehouseId = warehouseId;
                return this;
            }

            withBatchSize(batchSize) {
                this.batchSize = batchSize;
                return this;
            }

            withStartIndex(startIndex) {
                this.startIndex = startIndex;
                return this;
            }

            withRodeoFilters(rodeoFilters) {
                this.rodeoFilters = rodeoFilters;
                return this;
            }

            withSearchQuery(searchQuery) {
                this.searchQuery = searchQuery;
                return this;
            }

            withShipmentTypes(shipmentTypes) {
                this.shipmentTypes = _.isArray(shipmentTypes) ? shipmentTypes : [shipmentTypes];
                return this;
            }

            build() {
                return new SearchRequestInput(this);
            }
        }

        return Builder;
    }
}