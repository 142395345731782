import React, {Component} from 'react';
import _ from 'lodash';

export default class NoWarehouseSelectedView extends Component {

    componentDidMount() {
        if (!_.isNil(window.history.state) && !_.isNil(window.history.state.federatedUrl)) {
            this.props.history.push(window.history.state.federatedUrl);
        }
    }

    render() {
        return (
            <div className="jumbotron text-center">
                <h4>Select A Warehouse</h4>
            </div>);
    }

}