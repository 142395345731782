import React, {PureComponent} from 'react';
import {Badge, Button, ButtonGroup} from 'react-bootstrap';
import _ from 'lodash';

import DataLoading from '../../homepage/DataLoading';
import {VIEW_NAME_TO_WORK_POOL_NAME_MAP} from '../../pojos/WorkPools';
import {WORK_POOL_FILTER_NAME} from '../../pojos/RodeoFilters';
import {AGGREGATE_OPTIONS_ENUM, DEFAULT_AGGREGATE_OPTION_VALUE_MAP} from '../../pojos/AggregateOptionParameters';

const AGGREGATE_REFRESH_RATE_KEY = 'aggregateRefreshRate';
const ONE_MIN = '1 Min';
const FIVE_MIN = '5 Min';
const FIFTEEN_MIN = '15 Min';
const OFF = 'Off';

const REFRESH_OPTION_TO_MILLIS = new Map([
    [ONE_MIN, 60 * 1000], [FIVE_MIN, 5 * 60 * 1000], [FIFTEEN_MIN, 15 * 60 * 1000], [OFF, 0]
]);

export default class ReceiveActionBar extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            refreshRateOption: sessionStorage.getItem(AGGREGATE_REFRESH_RATE_KEY) || OFF
        };

        this.selectRefreshOption = this.selectRefreshOption.bind(this);
    }

    componentDidMount() {
        this.setupPageRefresh();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.refreshRateOption !== prevState.refreshRateOption) {
            this.unsetPageRefresh();
            this.setupPageRefresh();
        }
    }

    componentWillUnmount() {
        this.unsetPageRefresh();
    }

    render() {
        const numFiltersApplied = this.determineNumFiltersInReport();
        return (
            <div data-testid="ReceiveActionBar-TestId" className={this.props.className}>
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <Button onClick={this.props.toggleOptionsPanel}
                                variant="secondary"
                                size="sm">
                            Options
                        </Button>
                        <span className="mx-2">
                            <Badge variant="light">{numFiltersApplied} filter{numFiltersApplied === 1 ? '' : 's'} applied</Badge>
                        </span>
                    </div>
                    <div className="col-md-2 text-center d-flex flex-column">
                        <span>Refresh Rate</span>
                        <ButtonGroup aria-label="Refresh Rate" size="sm">
                            {[...REFRESH_OPTION_TO_MILLIS.keys()].map(option => <Button key={option}
                                                                                        variant="outline-secondary"
                                                                                        onClick={this.selectRefreshOption}
                                                                                        data-refresh-rate-option={option}
                                                                                        active={this.state.refreshRateOption === option}>{option}</Button>)}
                        </ButtonGroup>
                    </div>
                    <div className="col-md-5 pull-right">
                        {!this.props.isLoaded && <DataLoading justifyContent="end"/>}
                    </div>
                </div>
            </div>
        );
    }

    determineNumFiltersInReport() {
        let filterCount = 0;
        let rodeoFilters = this.props.rodeoFilters;
        const workPoolFilter = rodeoFilters.workPoolFilter;
        const workPoolFilterIsDefault = !_.isNil(workPoolFilter) &&
            _.size(workPoolFilter.values) === _.size(VIEW_NAME_TO_WORK_POOL_NAME_MAP.get(this.props.viewName));
        if (workPoolFilterIsDefault) {
            rodeoFilters = _.cloneDeep(this.props.rodeoFilters);
            _.unset(rodeoFilters, WORK_POOL_FILTER_NAME);
        }
        if (this.props.aggregateParameters.shipmentType !== DEFAULT_AGGREGATE_OPTION_VALUE_MAP.get(AGGREGATE_OPTIONS_ENUM.SHIPMENT_TYPE)) {
            filterCount += 1;
        }
        filterCount += _.filter(_.values(rodeoFilters), filter => !_.isNil(filter)).length;
        return filterCount;
    }

    selectRefreshOption(event) {
        const refreshRateOption = event.target.dataset.refreshRateOption;
        this.setState({refreshRateOption});
        sessionStorage.setItem(AGGREGATE_REFRESH_RATE_KEY, refreshRateOption);
    }

    setupPageRefresh() {
        if (this.state.refreshRateOption !== OFF) {
            this.pageRefresh = setInterval(this.props.loadAggregateReport, REFRESH_OPTION_TO_MILLIS.get(this.state.refreshRateOption));
        }
    }

    unsetPageRefresh() {
        clearInterval(this.pageRefresh);
    }
}