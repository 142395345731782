import React from 'react';
import ReactDOM from 'react-dom';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../css/index.scss';

import AppRouter from './components/router/AppRouter';

ReactDOM.render(
    <AppRouter/>,
    document.getElementById('root')
);