import React, {PureComponent} from 'react';
import {Link, matchPath, withRouter} from 'react-router-dom';
import _ from 'lodash';

import {SEARCH_URL, WAREHOUSE_ID_KEY, WAREHOUSE_ID_URL} from '../router/AppRouter';
import {WarehouseIdsContext} from '../provider/WarehouseIdsProvider';
import WarehouseIdAutoCompleter from './WarehouseIdAutoCompleter';
import {SEARCH_QUERY_QUERY_STRING_KEY} from '../itemlist/ItemListView';
import {QueryStringUtilities} from '../util/QueryStringUtilities';
import {AGGREGATE_OPTIONS_ENUM} from '../pojos/AggregateOptionParameters';

import '../../../css/header.scss';
import Logo from '../../../images/logo.png';


class Header extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            searchQuery: null
        };

        this.redirectToSearch = this.redirectToSearch.bind(this);
        this.updateSearchQuery = this.updateSearchQuery.bind(this);
        this.getValidWarehouses = this.getValidWarehouses.bind(this);
        this.getCurrentWarehouseIdFromUrl = this.getCurrentWarehouseIdFromUrl.bind(this);
    }

    render() {
        const currentWarehouseId = this.getCurrentWarehouseIdFromUrl();
        const logoRedirectURL = _.isNil(currentWarehouseId) ? '/' : `/${currentWarehouseId}`;

        return (
            <header>
                <nav className="header navbar navbar-expand-md">
                    <Link to={logoRedirectURL} className="navbar-brand">
                        <img src={Logo} alt="Inbound Rodeo Img"/>
                    </Link>
                    <div className="navbar-collapse collapse">
                        <div className="navbar-nav col-md-1 mr-auto">
                            <WarehouseIdAutoCompleter getValidWarehouses={this.getValidWarehouses}
                                                      getCurrentWarehouseIdFromUrl={this.getCurrentWarehouseIdFromUrl}/>
                        </div>
                        {this.getCurrentWarehouseIdFromUrl() && <form className="input-group col-md-2" onSubmit={this.redirectToSearch}>
                            <input type="search" className="form-control form-control-sm" placeholder="Search"
                                   onChange={this.updateSearchQuery}/>
                            <span className="input-group-append">
                                <div className="input-group-text" onClick={this.redirectToSearch}><i className={`fa fa-search clickable`}/></div>
                            </span>
                        </form>}
                    </div>
                </nav>
            </header>
        );
    }

    updateSearchQuery(event) {
        this.setState({searchQuery: event.target.value});
    }

    redirectToSearch(event) {
        event.preventDefault();
        const searchQuery = this.state.searchQuery;
        if (!_.isEmpty(searchQuery)) {
            const warehouseId = this.getCurrentWarehouseIdFromUrl();
            const searchQueryQueryStringObject = {[SEARCH_QUERY_QUERY_STRING_KEY]: searchQuery};
            const queryStrings = QueryStringUtilities.stringifyQueryStrings({...searchQueryQueryStringObject, ...this.parseCurrentShipmentTypeFromQueryParams()});
            this.props.history.push(`${SEARCH_URL.replace(WAREHOUSE_ID_KEY, warehouseId)}?${queryStrings}`);
        }
    }

    parseCurrentShipmentTypeFromQueryParams() {
        const parsedQueryStrings = QueryStringUtilities.parseQueryStrings(this.props.location.search);
        const selectedShipmentType = parsedQueryStrings[AGGREGATE_OPTIONS_ENUM.SHIPMENT_TYPE];
        return !_.isEmpty(selectedShipmentType) ? {[AGGREGATE_OPTIONS_ENUM.SHIPMENT_TYPE]: selectedShipmentType} : {};
    }

    getCurrentWarehouseIdFromUrl() {
        const match = matchPath(this.props.history.location.pathname, {
            path: WAREHOUSE_ID_URL,
            exact: false,
            strict: false
        });
        if (match && match.params.warehouseId) {
            const validWarehouseIds = this.getValidWarehouses();
            if (validWarehouseIds.includes(match.params.warehouseId)) {
                return match.params.warehouseId;
            }
        }
        return null;
    }

    getValidWarehouses() {
        return this.context.warehouseIds;
    }
}

Header.contextType = WarehouseIdsContext;
export default withRouter(Header);