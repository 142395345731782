import React, {PureComponent} from 'react';
import {Table} from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment-timezone';
import {Link, withRouter} from 'react-router-dom';

import {
    AGGREGATE_DIMENSION_VALUE_ENUM,
    AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP,
    DATE_RANGE_ENUM,
    DEFAULT_CATEGORY_NAME,
    PROPERTY_NAMES_ENUM,
    SHIPMENT_TYPES_ENUM,
    SUMMATION_NAMES_ENUM,
    FORECAST_DISABLED_DIMENSIONS
} from '../../pojos/Dimensions';
import {BUCKET_SIZES_IN_SECONDS} from '../../pojos/Buckets';
import {QueryStringUtilities} from '../../util/QueryStringUtilities';
import {WorkpoolUtilities} from '../../util/WorkpoolUtilities';
import AppRouter, {ITEM_LIST_URL, WAREHOUSE_ID_KEY} from '../../router/AppRouter';
import {RODEO_FILTER_NAME_TO_QUERY_STRING_KEY_MAP, WORK_POOL_FILTER_NAME, VALUES_FIELD} from '../../pojos/RodeoFilters';
import {DateUtilities} from '../../util/DateUtilities';
import {ExpandRowButton} from '../ExpandRowButton'
import {
    EXPECTED_STOW_DATE_QUERY_STRING_KEY,
    TRAILER_DOCKING_TIME_QUERY_STRING_KEY,
    DWELL_TIME_MILLIS_QUERY_STRING_KEY
} from '../../pojos/RodeoFilterQueryStrings';
import {AGGREGATE_OPTIONS_ENUM, VIEW_NAME_TO_TIME_RANGE_PROPERTY_MAP} from '../../pojos/AggregateOptionParameters';
import AggregateOptions from '../options/AggregateOptions';
import AggregateAction from '../../pojos/AggregationAction';
import {
    VIEW_NAME_TO_WORK_POOL_NAME_MAP,
    TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP
} from '../../pojos/WorkPools';

import '../../../../css/main-table.scss';

export const NO_DATA_FOUND = "No Data Found";
export const LOADING_DATA = "Loading data";

const HOUR_AND_MINUTE_HEADER_FORMAT = "HH:mm";
const MONTH_AND_DAY_HEADER_FORMAT = "MMM DD";
const TIMEZONE_HEADER_FORMAT = "z";
const VRID_DIMENSION_CAMELCASE = "vrId";

class AggregateViewMainTable extends PureComponent {

    render() {
        const aggregateObject = this.props.aggregatedResponseObject;
        if (_.isNil(aggregateObject) || _.isEmpty(aggregateObject.aggregateResultMap)) {
            let jumboTronCaption = NO_DATA_FOUND;
            if (!this.props.isLoaded) {
                jumboTronCaption = LOADING_DATA;
            }
            return <div data-testid="Jumbotron-SingleDimension-TestId"
                        className={`jumbotron text-center ${this.props.isLoaded ? '' : 'opaque-loading'}`}>{jumboTronCaption}</div>;
        }
        this.grossTimeRange = aggregateObject.timeRange;

        const tableContent = this.generateTableArea(DEFAULT_CATEGORY_NAME, aggregateObject);
        return (
            <div>
                <div>
                    <Table bordered responsive hover size="sm">{tableContent}</Table>
                </div>
            </div>
        );
    }

    generateDisplayedHeaderId(displayedBucketIdArray, isBounded = true) {
        const summationIdAheadOfBucketIdsWithoutBound = [SUMMATION_NAMES_ENUM.TOTAL];
        const summationIdAheadOfBucketIdsDwellTime = [SUMMATION_NAMES_ENUM.RANGE_TOTAL];
        const summationIdAheadOfBucketIdsPostReceive = [SUMMATION_NAMES_ENUM.TOTAL, SUMMATION_NAMES_ENUM.EARLIER_TOTAL, SUMMATION_NAMES_ENUM.RANGE_TOTAL];
        const summationIdAheadOfBucketIdsPreReceive = [SUMMATION_NAMES_ENUM.EARLIER_TOTAL, SUMMATION_NAMES_ENUM.RANGE_TOTAL];
        const summationIdBehindBucketIds = [SUMMATION_NAMES_ENUM.LATER_TOTAL];
        if (isBounded) {
            if (AppRouter.isDwellTimeAggregateView(this.props.viewName)) {
                return summationIdAheadOfBucketIdsDwellTime.concat(displayedBucketIdArray);
            }
            if (AppRouter.isPostReceiveAggregateView(this.props.viewName)) {
                return summationIdAheadOfBucketIdsPostReceive.concat(displayedBucketIdArray).concat(summationIdBehindBucketIds);
            }
            if (AppRouter.isPreReceiveAggregateView(this.props.viewName)) {
                return summationIdAheadOfBucketIdsPreReceive.concat(displayedBucketIdArray);
            }
        } else {
            return summationIdAheadOfBucketIdsWithoutBound.concat(displayedBucketIdArray);
        }
    }

    generateTableArea(categoryName, aggregateObject, isCurrentHeaderAtTop = true, displayedIdToTableSumMap = null) {
        const columnDisplayedIdArray = this.generateDisplayedHeaderId(aggregateObject.columnDisplayedIdArray, aggregateObject.hasTimeRange);
        const aggregationActionTimeStamp = aggregateObject.aggregationActionTimeStamp;
        return [
            this.generateTableAreaHeader(categoryName, columnDisplayedIdArray, isCurrentHeaderAtTop, aggregationActionTimeStamp),
            this.generateTableAreaBody(
                categoryName,
                aggregateObject.aggregateResultMap,
                displayedIdToTableSumMap
            )
        ];
    }

    generateTableAreaHeader(categoryName, colHeaderDisplayedIds, isCurrentHeaderAtTop, aggregationActionTimeStamp) {
        const numberOfGridsInRow = colHeaderDisplayedIds.length;
        const summationNames = _.values(SUMMATION_NAMES_ENUM);
        let currentBucketMonthDayFormatString = null;
        const displayedIdsArray = !isCurrentHeaderAtTop ?
            <th colSpan={numberOfGridsInRow}/> :
            colHeaderDisplayedIds.map((colHeaderDisplayedId) => {
                    if (_.includes(summationNames, colHeaderDisplayedId)) {
                        return (
                            <th className={"text-nowrap"} key={`${categoryName}-${colHeaderDisplayedId}`}>
                                {colHeaderDisplayedId}
                            </th>
                        );
                    } else {
                        if (AppRouter.isDwellTimeAggregateView(this.props.viewName)) {
                            const dwellTime = aggregationActionTimeStamp - _.parseInt(colHeaderDisplayedId);
                            const formattedDwellTime = DateUtilities.formatAggregateViewDwellTime(dwellTime * 1000);
                            return (
                                <th key={`${categoryName}-${dwellTime}`} className="text-nowrap">
                                    <div>{formattedDwellTime}</div>
                                </th>
                            );
                        }
                        const momentTime = moment.unix(_.parseInt(colHeaderDisplayedId)).tz(this.props.warehouseTimezone);
                        const hourMinuteFormatString = momentTime.format(HOUR_AND_MINUTE_HEADER_FORMAT);
                        const monthDayFormatString = momentTime.format(MONTH_AND_DAY_HEADER_FORMAT);
                        let showDateInHeader = false;
                        if (_.isNil(currentBucketMonthDayFormatString) || monthDayFormatString !== currentBucketMonthDayFormatString) {
                            currentBucketMonthDayFormatString = monthDayFormatString;
                            showDateInHeader = true;
                        }
                        return (
                            <th key={`${categoryName}-${momentTime}`}
                                className="text-nowrap">
                                {showDateInHeader && <div>{monthDayFormatString}</div>}
                                <div>{hourMinuteFormatString} {showDateInHeader && momentTime.format(TIMEZONE_HEADER_FORMAT)}</div>
                            </th>
                        );
                    }
                }
            );

        return (
            <thead key={`${categoryName}-head`} className="thead-light">
            <tr>
                {categoryName === DEFAULT_CATEGORY_NAME ? <th className="sticky-row-header text-nowrap"/> :
                    <th className="sticky-row-header text-nowrap">{categoryName}</th>}
                {displayedIdsArray}
            </tr>
            </thead>
        );
    }

    generateLinkForSummationData(colHeaderDisplayedId, unitObject, rowValue, isSummationRow = false) {
        const dateRangeIsAll = this.props.aggregateParameters[VIEW_NAME_TO_TIME_RANGE_PROPERTY_MAP.get(this.props.viewName)] === DATE_RANGE_ENUM.ALL;
        if (unitObject.quantity === 0) {
            return 0;
        } else if (isSummationRow && this.props.aggregateParameters.yAxis === AGGREGATE_DIMENSION_VALUE_ENUM.ISA_ID) {
            return unitObject.quantity;
        } else if (colHeaderDisplayedId === SUMMATION_NAMES_ENUM.TOTAL) {
            if (AppRouter.isDwellTimeAggregateView(this.props.viewName)) {
                return <Link
                    to={this.generateURL(rowValue, unitObject.shipmentTypes, this.grossTimeRange)}>{unitObject.quantity}</Link>
            } else {
                return <Link to={this.generateURL(rowValue, unitObject.shipmentTypes)}>{unitObject.quantity}</Link>
            }
        } else if (colHeaderDisplayedId === SUMMATION_NAMES_ENUM.RANGE_TOTAL) {
            return <Link
                to={this.generateURL(rowValue, unitObject.shipmentTypes, this.grossTimeRange)}>{unitObject.quantity}</Link>
        } else if (colHeaderDisplayedId === SUMMATION_NAMES_ENUM.EARLIER_TOTAL && !dateRangeIsAll) {
            return <Link
                to={this.generateURL(rowValue, unitObject.shipmentTypes, {end: this.grossTimeRange.start})}>{unitObject.quantity}</Link>
        } else if (colHeaderDisplayedId === SUMMATION_NAMES_ENUM.LATER_TOTAL && !dateRangeIsAll) {
            return <Link
                to={this.generateURL(rowValue, unitObject.shipmentTypes, {start: this.grossTimeRange.end})}>{unitObject.quantity}</Link>
        }
        return unitObject.quantity;
    }

    generateTableAreaBodyByRow(aggregateResultMap, rowHeaderValue, colHeaderDisplayedIds, displayedIdToTableAreaSumMap) {
        const rowDataMap = aggregateResultMap.get(rowHeaderValue);
        const summationNames = _.values(SUMMATION_NAMES_ENUM);

        return colHeaderDisplayedIds.map(colHeaderDisplayedId => {
            const unitObject = rowDataMap.has(colHeaderDisplayedId) ? rowDataMap.get(colHeaderDisplayedId) : {
                quantity: 0,
                shipmentTypes: []
            };
            const unitData = !_.isNil(unitObject) ? _.parseInt(unitObject.quantity) : 0;
            const shipmentTypes = !_.isNil(unitObject) ? unitObject.shipmentTypes : [];

            this.addMainWorkpoolsAndOrphanedSubWorkpoolsToTotal(rowHeaderValue, displayedIdToTableAreaSumMap, colHeaderDisplayedId, unitObject);

            if (_.includes(summationNames, colHeaderDisplayedId)) {
                const summationData = this.generateLinkForSummationData(colHeaderDisplayedId, unitObject, rowHeaderValue);
                return (
                    <td key={`${rowHeaderValue}-${colHeaderDisplayedId}-unit`} className="table-primary">
                        {summationData}
                    </td>
                );
            } else {
                const startTimeStamp = _.parseInt(colHeaderDisplayedId);
                const timeRange = {
                    start: startTimeStamp,
                    end: startTimeStamp + BUCKET_SIZES_IN_SECONDS.get(this.props.aggregateParameters.bucketSize)
                };
                return (
                    <td key={`${rowHeaderValue}-${colHeaderDisplayedId}-unit`}>
                        {unitData === 0 ? 0 :
                            <Link to={this.generateURL(rowHeaderValue, shipmentTypes, timeRange)}>{unitData}</Link>}
                    </td>
                );
            }
        });
    }

    addMainWorkpoolsAndOrphanedSubWorkpoolsToTotal(rowHeaderValue, displayedIdToTableAreaSumMap, colHeaderDisplayedId, unitObject) {
        if (!WorkpoolUtilities.isASubWorkPool(rowHeaderValue) ||
            (!_.isEmpty(this.props.rodeoFilters) && !_.includes(this.props.rodeoFilters[WORK_POOL_FILTER_NAME][VALUES_FIELD], WorkpoolUtilities.findParentWorkPool(rowHeaderValue)))) {
            AggregateAction.addValueToMapById(displayedIdToTableAreaSumMap, colHeaderDisplayedId, unitObject, AggregateAction.addObject);
        }
    }

    generateTableAreaBody(categoryName, aggregateResultMap, displayedIdToTableSumMap) {
        const colHeaderDisplayedIds = this.generateDisplayedHeaderId(this.props.aggregatedResponseObject.columnDisplayedIdArray, this.props.aggregatedResponseObject.hasTimeRange);
        if (!AppRouter.isPostReceiveAggregateView(this.props.viewName)) {
            const tableBodyDataRows = this.generateTableBodyDataRowsForNonPostReceive();
            const tableAreaColumnSums = this.generateTableAreaColumnSums(tableBodyDataRows[1], colHeaderDisplayedIds, categoryName, displayedIdToTableSumMap);
            return <tbody key={categoryName}
                          className={!this.props.isLoaded ? "opaque-loading" : ''}>{[tableBodyDataRows[0], tableAreaColumnSums]}</tbody>
        }
        const displayedIdToTableAreaSumMap = new Map();
        const tableBodyDataRows = this.sortAggregateResultMap(aggregateResultMap).map((rowHeaderValue) => {
            return (
                <tr key={rowHeaderValue}>
                    <th className="table-secondary sticky-row-header">{rowHeaderValue}</th>
                    {this.generateTableAreaBodyByRow(aggregateResultMap, rowHeaderValue, colHeaderDisplayedIds, displayedIdToTableAreaSumMap)}
                </tr>
            );
        });
        const tableAreaColumnSums = this.generateTableAreaColumnSums(displayedIdToTableAreaSumMap, colHeaderDisplayedIds, categoryName, displayedIdToTableSumMap);
        return <tbody key={categoryName}
                      className={!this.props.isLoaded ? "opaque-loading" : ''}>{[tableBodyDataRows, tableAreaColumnSums]}</tbody>
    }

    generateTableBodyDataRowsForNonPostReceive() {
        const aggregateResultMap = this.props.aggregatedResponseObject.aggregateResultMap
        const colHeaderDisplayedIds = this.generateDisplayedHeaderId(this.props.aggregatedResponseObject.columnDisplayedIdArray, this.props.aggregatedResponseObject.hasTimeRange)
        const idToTableAreaSumMap = new Map();
        const sortedAggregateResultMap = this.sortAggregateResultMap(aggregateResultMap);
        const tableBodyDataRows = sortedAggregateResultMap.filter(rowHeaderValue => _.includes(this.props.visibleRows, rowHeaderValue)).map((rowHeaderValue) => {
            let className = "";
            let buttonComponent;
            if (WorkpoolUtilities.isAParentWorkPool(rowHeaderValue) && this.props.workPoolAxis !== AGGREGATE_OPTIONS_ENUM.Y_AXIS) {
                const subworkPools = TOP_TO_SUB_PRE_RECEIVE_WORK_POOL_NAME_MAP[rowHeaderValue];
                const isButtonExpanded = subworkPools.some(subworkPool => _.includes(this.props.visibleRows, subworkPool));
                className = "table-secondary sticky-row-header";
                buttonComponent = [<ExpandRowButton key={rowHeaderValue}
                                                    rowHeaderValue={rowHeaderValue}
                                                    setExtendedRows={this.props.setAggregateViewReceiveVisibleRows}
                                                    visibleRows={this.props.visibleRows}
                                                    isOpened={isButtonExpanded}/>];
            }
            if (!(_.includes(FORECAST_DISABLED_DIMENSIONS, this.props.aggregateParameters.zAxis) && WorkpoolUtilities.isASubWorkPool(rowHeaderValue))) {
                className = `table-secondary sticky-row-header text-nowrap ${(this.props.workPoolAxis === '' && WorkpoolUtilities.isASubWorkPool(rowHeaderValue) && _.includes(sortedAggregateResultMap, WorkpoolUtilities.findParentWorkPool(rowHeaderValue))) ? 'pl-5' : ''}`;
            }
            const tableBodyDataRow = _.isEmpty(className) ? (<tr></tr>) : (<tr key={rowHeaderValue}>
                <th className={className}>{buttonComponent}{rowHeaderValue}</th>
                {this.generateTableAreaBodyByRow(aggregateResultMap, rowHeaderValue, colHeaderDisplayedIds, idToTableAreaSumMap)}
            </tr>);
            return tableBodyDataRow;
        });
        return [tableBodyDataRows, idToTableAreaSumMap];
    }

    generateTableAreaColumnSums(displayedIdToTableAreaSumMap, colHeaderDisplayedIds, categoryName, displayedIdToTableSumMap) {
        const yDimensionValues = [...this.props.aggregatedResponseObject.aggregateResultMap.keys()];
        const summationNames = _.values(SUMMATION_NAMES_ENUM);
        const tableAreaColumnSums = colHeaderDisplayedIds.map((colHeaderDisplayedId) => {
            const columnObject = displayedIdToTableAreaSumMap.has(colHeaderDisplayedId) ? displayedIdToTableAreaSumMap.get(colHeaderDisplayedId) : {
                quantity: 0,
                shipmentTypes: []
            };
            const columnSum = !_.isNil(columnObject) ? columnObject.quantity : 0;
            const columnShipmentTypes = !_.isNil(columnObject) ? columnObject.shipmentTypes : [];

            if (!_.isNil(displayedIdToTableSumMap)) {
                AggregateAction.addValueToMapById(displayedIdToTableSumMap, colHeaderDisplayedId, columnObject, AggregateAction.addObject);
            }
            if (_.includes(summationNames, colHeaderDisplayedId)) {
                const summationData = this.generateLinkForSummationData(colHeaderDisplayedId, columnObject, yDimensionValues, true);
                return (
                    <td key={`${categoryName}-${colHeaderDisplayedId}-columnSum`} className="table-primary">
                        {summationData}
                    </td>
                );
            } else {
                const startTimeStamp = _.parseInt(colHeaderDisplayedId);
                const timeRange = {
                    start: startTimeStamp,
                    end: startTimeStamp + BUCKET_SIZES_IN_SECONDS.get(this.props.aggregateParameters.bucketSize)
                };
                return (
                    <td key={`${categoryName}-${colHeaderDisplayedId}-columnSum`} className="table-primary">
                        {this.props.aggregateParameters.yAxis === AGGREGATE_DIMENSION_VALUE_ENUM.ISA_ID || columnSum === 0 ? columnSum
                            : <Link
                                to={this.generateURL(yDimensionValues, columnShipmentTypes, timeRange)}>{columnSum}</Link>}
                    </td>
                );
            }
        });
        const displayedCategoryName = categoryName === DEFAULT_CATEGORY_NAME ? "" : categoryName;

        return (
            <tr key={categoryName}>
                <th className="table-secondary sticky-row-header">{`${displayedCategoryName} Totals`}</th>
                {tableAreaColumnSums}
            </tr>
        );
    }

    generateURL(rowHeaderValue, shipmentTypes, timeRange) {
        const newQueryStrings = {
            ...this.addCurrentRodeoFiltersToTableURL(),
            ...this.addShipmentTypeToTableURL(shipmentTypes),
            ...this.addTimeRangeFilterToTableURL(timeRange),
            ...this.addAggregateDimensionToTableURL(rowHeaderValue, this.props.aggregateParameters.yAxis),
            ...this.addAggregateDimensionToTableURL(this.props.aggregatedResponseObject.zDimensionValue, this.props.aggregateParameters.zAxis)
        };
        return `${ITEM_LIST_URL.replace(WAREHOUSE_ID_KEY, this.props.match.params.warehouseId)}?${QueryStringUtilities.stringifyQueryStrings({...newQueryStrings})}`;
    }

    addCurrentRodeoFiltersToTableURL() {
        const newQueryStrings = {};
        _.toPairs(this.props.rodeoFilters).forEach(([filterName, filterObject]) => {
            if (!_.isNil(filterObject)) {
                const queryStringKey = RODEO_FILTER_NAME_TO_QUERY_STRING_KEY_MAP.get(filterName);
                newQueryStrings[queryStringKey] = AggregateOptions.getFilterValue(filterObject, filterName);
            }
        });
        return newQueryStrings;
    }

    addShipmentTypeToTableURL(shipmentTypes) {
        const newQueryStrings = {};
        if (!_.isNil(shipmentTypes) && shipmentTypes.length === 1) {
            const queryStringKey = AGGREGATE_OPTIONS_ENUM.SHIPMENT_TYPE;
            if (_.find(shipmentTypes, shipmentType => shipmentType === SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE)) {
                newQueryStrings[queryStringKey] = SHIPMENT_TYPES_ENUM.VENDOR_RECEIVE;
            } else {
                newQueryStrings[queryStringKey] = SHIPMENT_TYPES_ENUM.TRANSSHIPMENT;
            }
        }
        return newQueryStrings;
    }

    addTimeRangeFilterToTableURL(timeRange) {
        const newQueryStrings = {};
        if (AppRouter.isPreReceiveAggregateView(this.props.viewName)) {
            newQueryStrings[TRAILER_DOCKING_TIME_QUERY_STRING_KEY] = timeRange;
        } else if (AppRouter.isPostReceiveAggregateView(this.props.viewName)) {
            newQueryStrings[EXPECTED_STOW_DATE_QUERY_STRING_KEY] = timeRange;
        } else {
            if (!_.isNil(timeRange)) {
                const dwellTimeRange = {
                    start: (this.props.aggregatedResponseObject.aggregationActionTimeStamp - timeRange.end) * 1000,
                    end: (this.props.aggregatedResponseObject.aggregationActionTimeStamp - timeRange.start) * 1000
                };
                newQueryStrings[DWELL_TIME_MILLIS_QUERY_STRING_KEY] = dwellTimeRange;
            }
        }
        return newQueryStrings;
    }

    addAggregateDimensionToTableURL(rowHeaderValue, axisDimension) {
        const newQueryStrings = {};
        if (!_.isNil(rowHeaderValue)) {
            if (AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP.get(axisDimension) === PROPERTY_NAMES_ENUM.VR_ID) {
                newQueryStrings[VRID_DIMENSION_CAMELCASE] = [rowHeaderValue];
            } else {
                const dimensionQueryKey = _.camelCase(AGGREGATE_DIMENSION_VALUE_TO_NAME_MAP.get(axisDimension));
                newQueryStrings[dimensionQueryKey] = [rowHeaderValue];
            }
        }
        return newQueryStrings;
    }

    sortAggregateResultMap(aggregateResultMap) {
        if (this.props.aggregateParameters.yAxis === AGGREGATE_DIMENSION_VALUE_ENUM.WORK_POOL) {
            const aggregateResultWorkPools = [...aggregateResultMap.keys()];
            return _.filter([...VIEW_NAME_TO_WORK_POOL_NAME_MAP.get(this.props.viewName).keys()], workPool => _.includes(aggregateResultWorkPools, workPool));
        }
        return [...aggregateResultMap.keys()].sort();
    }
}

export {AggregateViewMainTable}

export default withRouter(AggregateViewMainTable);
