import { AwsSigner } from "aws-sign-web";

import { getAftApiDomainName } from "./config";

export default function signRequest(request, credentials, service, region) {
  const signedRequest = {
    method: request.method,
    url: `${getAftApiDomainName(region)}/${request.url}`,
    headers: {},
    params: deepCopy(request.params),
    data: deepCopy(request.data),
  };

  return credentials.getPromise().then(() => signRequest());

  function signRequest() {
    const signedHeaders = new AwsSigner({
      service,
      region,
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    }).sign(signedRequest);
    Object.assign(request.headers, signedHeaders);
    return request;
  }
}

function deepCopy(object) {
  return object ? JSON.parse(JSON.stringify(object)) : null;
}
