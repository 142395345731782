import React, {PureComponent} from 'react';
import {AutoSizer, Column, Table} from 'react-virtualized';
import moment from 'moment-timezone';
import _ from 'lodash';

import {
    DEFAULT_PROPERTY_NAME_TO_COLUMN_NAME_MAP, DWELL_TIME_NAME_TO_COLUMN_MAP,
    POST_RECEIVE_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP,
    TRANSSHIPMENT_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP,
    VENDOR_RECEIVE_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP,
    WORKPOOL_ONLY_PROPERTY_NAME_TO_TO_COLUMN_MAP
} from './pojos/ItemListRecord';

import '../../../css/custom-scrollbar.scss';
import 'react-virtualized/styles.css';

import {
    FORECASTED_DEFAULT_PROPERTY_NAME_TO_COLUMN_NAME_MAP,
    FORECASTED_TRANSSHIPMENT_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP
} from './pojos/ForecastedItemListRecord';
import{
    SOURCE_WAREHOUSE_ID_PROPERTY_NAME,
    SOURCE_WAREHOUSE_ID_OR_SCSC_CODE_PROPERTY_NAME
} from '../pojos/PropertyNames'

const DANGER_DWELL_TIME_DURATION = moment.duration(2, 'day');
const WARNING_DWELL_TIME_DURATION = moment.duration(1, 'day');

const SORT_ASC = 'ASC';
const SORT_DESC = 'DESC';
const DWELL_TIME_COLUMN = _.head([...DWELL_TIME_NAME_TO_COLUMN_MAP.keys()]);
const NA_REALM = "na";
const EU_REALM = "eu";

const ITEMLIST_FIELDS_UILINKS_ENUM={
    SCANNABLE_ID: "scannableId",
    CONTAINER_ID: "containerId",
    FNSKU: "fnSku",
    FCSKU: "fcSku",
    OUTER_SCANNABLE_ID: "outerScannableId",
    OUTER_OUTER_SCANNABLE_ID: "outerOuterScannableId",
    ISA_ID: "isaId",
    ISD_ID: "isdId",
    MANIFEST_ID: "manifestId",
    ASN_ID: "asnId"
}

export default class ItemListTable extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            sortBy: DWELL_TIME_COLUMN,
            sortDirection: SORT_ASC
        };

        this.noRowsRenderer = this.noRowsRenderer.bind(this);
        this.sort = this.sort.bind(this);
    }

    render() {
        const sortedList = this.sortList();
        const PROPERTY_NAME_TO_COLUMN_NAME_MAP = this.props.isForecast ? FORECASTED_DEFAULT_PROPERTY_NAME_TO_COLUMN_NAME_MAP : DEFAULT_PROPERTY_NAME_TO_COLUMN_NAME_MAP;
        const TRANSSHIPMENT_PROPERTY_NAME_TO_COLUMN_NAME_MAP = this.props.isForecast ? FORECASTED_TRANSSHIPMENT_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP : TRANSSHIPMENT_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP;
        const VENDOR_RECEIVE_PROPERTY_NAME_TO_COLUMN_NAME_MAP = this.props.isForecast ? new Map([]) : VENDOR_RECEIVE_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP;
        return (
            <AutoSizer>
                {({height, width}) => (
                    <Table
                        ref="Table"
                        headerClassName="header-column h-100"
                        className={!this.props.isLoaded ? 'opaque-loading' : ''}
                        gridClassName="table-grid scrollable"
                        headerHeight={65}
                        noRowsRenderer={this.noRowsRenderer}
                        overscanRowCount={8}
                        rowClassName={ItemListTable.rowClassName}
                        rowHeight={60}
                        rowGetter={({index}) => sortedList[index]}
                        rowCount={this.props.itemListRecords.length}
                        sort={this.sort}
                        sortBy={this.state.sortBy}
                        sortDirection={this.state.sortDirection}
                        height={height}
                        width={width}>
                        {[...PROPERTY_NAME_TO_COLUMN_NAME_MAP.entries()].map(([propertyName, columnName]) => this.generateColumn(propertyName, columnName))}
                        {[...this.props.isPostReceiveSelected ?
                            POST_RECEIVE_ONLY_PROPERTY_NAME_TO_COLUMN_NAME_MAP.entries() : []].map(([propertyName, columnName]) => this.generateColumn(propertyName, columnName))}
                        {[...this.props.isTransshipmentSelected ?
                            TRANSSHIPMENT_PROPERTY_NAME_TO_COLUMN_NAME_MAP :
                            VENDOR_RECEIVE_PROPERTY_NAME_TO_COLUMN_NAME_MAP.entries()].map(([propertyName, columnName]) => this.generateColumn(propertyName, columnName))}
                        {[...this.props.additionalPropertyNameToHeaderNameMap.entries()].map(([propertyName, columnName]) => this.generateColumn(propertyName, columnName))}
                        {[...WORKPOOL_ONLY_PROPERTY_NAME_TO_TO_COLUMN_MAP.entries()].map(([propertyName, columnName]) => this.generateColumn(propertyName, columnName))}
                        {[...DWELL_TIME_NAME_TO_COLUMN_MAP.entries()].map(([propertyName, columnName]) => this.generateColumn(propertyName, columnName, true))}
                    </Table>
                )}
            </AutoSizer>
        );
    }

    sortList() {
        const sortedList = _.sortBy(this.props.itemListRecords,
            item => item[this.state.sortBy === DWELL_TIME_COLUMN ?
                'dwellTime' :
                this.state.sortBy]);
        if (this.state.sortDirection === SORT_DESC) {
            sortedList.reverse();
        }
        return sortedList;
    }

    sort({sortBy, sortDirection}) {
        this.setState({sortBy, sortDirection});
    }

    noRowsRenderer() {
        return <div className="no-item-list-rows">{this.props.isLoaded ? 'No rows' : ''}</div>;
    }

     generateColumn(propertyName, columnName, isDwellTime) {
        return <Column
            key={columnName}
            className={`column-wrap ${isDwellTime ? 'h-100 w-100 mr-0' : ''}`}
            width={100}
            label={columnName}
            dataKey={propertyName}
            flexGrow={1}
            cellRenderer={({cellData, rowData}) => !isDwellTime ? this.getLinkForCellRender(cellData, rowData, propertyName) : ItemListTable.dwellTimeRenderer(cellData, rowData)}
        />
    }

     getLinkForCellRender(cellData, rowData, propertyName) {

        const realm = this.props.isEURealm ? EU_REALM : NA_REALM ;

        if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.SCANNABLE_ID ) {
            return ItemListTable.getLinkForFCResearch(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.CONTAINER_ID ) {
            return ItemListTable.getLinkForFCResearch(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.FNSKU ) {
            return ItemListTable.getLinkForFCResearch(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.FCSKU ) {
            return ItemListTable.getLinkForFCResearch(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.OUTER_SCANNABLE_ID ){
            return ItemListTable.getLinkForFCResearch(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.OUTER_OUTER_SCANNABLE_ID ){
            return ItemListTable.getLinkForFCResearch(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.ISA_ID ) {
            return ItemListTable.getLinkForDockAppointment(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.ISD_ID ) {
            return ItemListTable.getLinkForDockShipment(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.MANIFEST_ID ) {
            return ItemListTable.getLinkForManifestId(rowData.warehouseId, cellData, realm);
        }
        else if ( propertyName === ITEMLIST_FIELDS_UILINKS_ENUM.ASN_ID ) {
            return ItemListTable.getLinkForASNId(rowData.warehouseId, cellData, realm);
        }
        this.getConcatenatedString(cellData, propertyName)
        return cellData;
    }

    getConcatenatedString(cellData, propertyName) {
        return !_.isEmpty(cellData) && (_.isEqual(propertyName, SOURCE_WAREHOUSE_ID_PROPERTY_NAME) || _.isEqual(propertyName, SOURCE_WAREHOUSE_ID_OR_SCSC_CODE_PROPERTY_NAME)) ? cellData.toString() : cellData;
    }

    static dwellTimeRenderer(cellData, rowData) {
        return <div
            className={`${ItemListTable.highlightDwellTime(rowData.dwellTime)} justify-content-center d-flex flex-column h-100`}>
            {cellData}
        </div>;
    }

    static highlightDwellTime(dwellTimeMills) {
        if (dwellTimeMills > DANGER_DWELL_TIME_DURATION) {
            return 'table-danger';
        }
        if (dwellTimeMills > WARNING_DWELL_TIME_DURATION) {
            return 'table-warning';
        }
        return 'table-success';

    }

    static rowClassName({index}) {
        if (index < 0) {
            return "header-row";
        } else {
            return index % 2 === 0 ? "even-row" : "odd-row";
        }
    }

    static getLinkForFCResearch(warehouseId, cellData, realm)
    {
        let fcResearchEndpoint = `https://fcresearch-${realm}.aka.amazon.com`;
        if (ItemListTable.isMocRequest()) {
            fcResearchEndpoint = `https://aft-sherlock.${realm}.aftx.amazonoperations.app`;
        }
        return (
                <div>
                    <a href={`${fcResearchEndpoint}/${warehouseId}/results?s=${cellData}`} target="_blank" rel="noreferrer">{cellData}</a>
                </div> );
    }

    static getLinkForDockAppointment(warehouseId, cellData, realm)
    {
        return (
            <div>
                <a href={`https://fc-inbound-dock-hub-${realm}.aka.amazon.com/en_US/#/dockmaster/appointment/${warehouseId}/view/${cellData}/appointmentDetail`} target="_blank" rel="noreferrer">{cellData}</a>
            </div> );
    }

    static getLinkForDockShipment(warehouseId, cellData, realm)
    {
        return (
            <div>
                <a href={`https://fc-inbound-dock-hub-${realm}.aka.amazon.com/en_US/#/dockmaster/shipment/${warehouseId}/${cellData}`} target="_blank" rel="noreferrer">{cellData}</a>
            </div> );
    }

    static getLinkForManifestId(warehouseId, cellData, realm)
    {
        let aftTransshipmentHubEndpoint = `https://afttransshipmenthub-${realm}.aka.amazon.com`;
        if (ItemListTable.isMocRequest()) {
            aftTransshipmentHubEndpoint = `https://transshipmenthub.${realm}.aftx.amazonoperations.app`;
        }
        return (
            <div>
                <a href={`${aftTransshipmentHubEndpoint}/${warehouseId}/view-contents/${cellData}`} target="_blank" rel="noreferrer">{cellData}</a>
            </div> );
    }

    static getLinkForASNId(warehouseId, cellData, realm)
    {
        let unifiedPortalEndpoint = `https://unified-portal-${realm}.inbound-shipment-signals.scot.a2z.com`;
        if (ItemListTable.isMocRequest()) {
            unifiedPortalEndpoint = `https://unified-inbound.${realm}.aftx.amazonoperations.app`;
        }
        return (
            <div>
                <a href={`${unifiedPortalEndpoint}/#/asnsearch?pageType=detail&asnID=${cellData}&asnVersion=1`} target="_blank" rel="noreferrer">{cellData}</a>
            </div> );
    }

    static isMocRequest()
    {
        return window.location.hostname.includes('aft.amazonoperations.app') || window.location.hostname.includes('aftx.amazonoperations.app');
    }
}
